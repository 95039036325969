import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useSortingBlockStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(3),
        },
        subtitle: {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: '1.4rem',
        },
    };
});

export const useSortingBlockToggleGroupStyles = makeStyles(() => {
    return {};
});

export const useSortingBlockToggleButtonStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            fontSize: '1.4rem',
            border: 'none',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        selected: {
            color: `${theme.palette.secondary.main}!important`,
            textDecoration: 'underline!important',
            backgroundColor: `${theme.palette.primary.main}!important`,
        },
    };
});
