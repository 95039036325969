import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { THEME_COLORS } from 'src/theming/theme';
import { centerAlignByFlex } from 'src/theming/mixins';
import { Spacings } from 'src/shared/constants';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useBaseLayoutStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: 'minmax(135px, auto) 1fr auto',
            gridTemplateAreas: `"header"
                            "main"
                            "footer"`,
            backgroundColor: theme.palette.primary.main,
            [theme.breakpoints.down('lg')]: {
                gridTemplateRows: 'minmax(73px, auto) 1fr auto',
            },
        },
        header: {
            position: 'sticky',
            width: '100%',
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            gridArea: 'header',
            backgroundColor: theme.palette.primary.main,
            borderBottom: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            zIndex: 1000,
            '& h5': {
                margin: 0,
            },
        },
        headerMain: {
            width: '100',
            display: 'flex',
            paddingRight: 22,
            paddingBottom: theme.spacing(1),
            [theme.breakpoints.down('lg')]: {
                flexBasis: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                display: 'block',
            },
        },
        headerElementsContainer: {
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            flexWrap: 'wrap',
            width: '100%',
        },
        elements: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            columnGap: theme.spacing(5),
            [theme.breakpoints.down('xl')]: {
                columnGap: theme.spacing(3),
            },
            [theme.breakpoints.down('mm')]: {
                flexWrap: 'wrap-reverse',
            },
        },
        headerLogoLinesWrapper: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        headerLines: {
            position: 'relative',
            top: 7,
            left: -110,
            width: '100%',
            '& svg': {
                width: 'calc(100% + 110px)',
            },
            [theme.breakpoints.down('lg')]: {
                top: 3.5,
                left: -65,
                '& svg': {
                    width: 'calc(100% + 65px)',
                },
            },
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                top: 62,
                width: '100%',
                display: 'flex',
            },
            [theme.breakpoints.down('ml')]: {
                display: 'none',
            },
        },
        logoWrapper: {
            ...centerAlignByFlex(),
            minWidth: 400,
            boxSizing: 'content-box',
            alignSelf: 'stretch',
            padding: `16px 0px 16px 22px`,
            margin: 0,
            zIndex: 1,
            '& img': {
                width: '100%',
                imageRendering: '-webkit-optimize-contrast',
            },
            [theme.breakpoints.down('lg')]: {
                width: 250,
                minWidth: 250,
                paddingBottom: 5,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        searchWrapper: {
            flexGrow: 0.3,
            paddingLeft: theme.spacing(4),
            [theme.breakpoints.down('xl')]: {
                flexGrow: 0.6,
            },
            [theme.breakpoints.down('lg')]: {
                flexGrow: 1,
            },
            '& .MuiInputBase-root.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: THEME_COLORS.INFO_WARNING_DARK_COLOR,
                },
                '& .MuiSvgIcon-root': {
                    color: `${THEME_COLORS.INFO_WARNING_DARK_COLOR}!important`,
                    opacity: 0.5,
                },
            },
        },
        linksWrapper: {
            marginLeft: 'auto',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        shortcutNavWrapper: {},
        langDetectorWrapper: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        navigation: {
            flexBasis: '50%',
        },
        main: {
            gridArea: 'main',
        },

        footer: {
            gridArea: 'footer',
            padding: `${theme.spacing(10)} ${Spacings.xxl}px`,
            backgroundColor: theme.palette.info.light,
            color: theme.palette.primary.contrastText,
            '& *': {
                color: theme.palette.primary.contrastText,
            },
            [theme.breakpoints.down('xl')]: {
                padding: `${theme.spacing(9)} ${Spacings.xl}px`,
            },
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(6)} ${Spacings.sm}px`,
            },
        },
        footerLinkLists: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            paddingBottom: theme.spacing(5),
            borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
            '& ul': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiListItem-root': {
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 0,
            },
            [theme.breakpoints.down('lg')]: {
                justifyContent: 'flex-start',
                paddingBottom: theme.spacing(3),
            },
            '& .MuiTypography-root': {
                [theme.breakpoints.down('lg')]: {
                    fontSize: '1.4rem',
                },
            },
        },
        listTitle: {
            fontSize: '1.8rem',
        },
        paymentMethodItems: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            maxHeight: '240px',
        },
        paymentMethodItem: {
            width: '60px',
            height: '30px',
            [getMediaSelectorKey('min-width: 1200px')]: {
                width: '83px',
                height: '42px',
            },
            display: 'flex',
            alignItems: 'center',
        },
        socialNetworksListItems: {
            display: 'flex',
            alignItems: 'center',
            width: 50,
            '& a': {
                ...centerAlignByFlex(),
            },
            [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(5),
                '& .MuiListItem-root': {
                    width: 'fit-content',
                    paddingRight: 0,
                },
            },
        },
        rowListItems: {
            [theme.breakpoints.down('lg')]: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                '& .MuiListItem-root': {
                    width: 'fit-content',
                },
            },
        },
        fullFlexWidth: {
            [theme.breakpoints.down('lg')]: {
                flexBasis: '100%',
            },
        },
        halfFlexWidth: {
            [theme.breakpoints.down('lg')]: {
                flexBasis: '50%',
            },
        },
        copyRightAndDelivaryInfo: {
            display: 'flex',
            gap: 10,
            justifyContent: 'space-between',
            paddingTop: theme.spacing(6),
            '& .MuiTypography-root': {
                fontSize: '1.6rem',
                marginBottom: '0.6em',
            },
            [theme.breakpoints.down('xl')]: {
                gap: 0,
                flexDirection: 'column',
            },
            [theme.breakpoints.down('lg')]: {
                '& .MuiTypography-root': {
                    fontSize: '1.4rem',
                },
            },
        },
        delivaryInfo: {
            [theme.breakpoints.down('xl')]: {
                fontSize: '1.4rem',
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.2rem!important',
            },
        },
    };
});
