import { types } from 'mobx-state-tree';

import { ImagePreview } from 'src/shared/types';

export const ImagePreviewMst = types.custom({
    name: 'NavItem',
    fromSnapshot(value: ImagePreview) {
        return value;
    },
    toSnapshot(value: ImagePreview) {
        return value;
    },
    isTargetType(value: ImagePreview): boolean {
        return Boolean(value?.id && value?.fileName);
    },
    getValidationMessage(value: ImagePreview): string {
        return `${value} doesn't look like a valid structure`;
    },
});
