import React, { ReactElement } from 'react';

import { useRepresentationSection } from './RepresentationSectionStyles';

interface RepresentationSectionProps {
    children: ReactElement[];
    hideBorder?: boolean;
}

export const RepresentationSection = ({
    children,
    hideBorder,
}: RepresentationSectionProps): ReactElement => {
    const classes = useRepresentationSection();

    return (
        <section className={hideBorder === true ? '' : classes.container}>
            {children[0] && <header className={classes.header}>{children[0]}</header>}
            {children[1] && <div>{children[1]}</div>}
        </section>
    );
};
