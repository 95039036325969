import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useRestorePasswordFormStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            padding: theme.spacing(5),
        },
        actionsContainer: {},
        signUp: {
            width: '50%',
        },
        backToLogin: {
            height: 'fit-content',
            fontSize: '1.4rem',
            fontWeight: theme.typography.fontWeightRegular,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                textDecoration: 'underline',
            },
        },
    };
});
