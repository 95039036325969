import React, { ReactElement } from 'react';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    DialogActions,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import {
    useModalWindowStyles,
    useModalClasses,
    useStyleDialogTitle,
    useStyleDialogContent,
    useStyleDialogActions,
} from './ModalWindowStyles';

interface ModalWindowProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement[];
    width?: number;
    modalWindowClass?: string;
}

export const ModalWindow = (props: ModalWindowProps): ReactElement => {
    const { width, isOpen, onClose, children, modalWindowClass } = props;
    const classes = useModalWindowStyles();
    const modalWindowClasses = useModalClasses({ width });
    const modalTitleClasses = useStyleDialogTitle();
    const modalContent = useStyleDialogContent();
    const modalActionsClasses = useStyleDialogActions();

    return (
        <Dialog open={isOpen} classes={modalWindowClasses} className={modalWindowClass}>
            {children[0] && (
                <DialogTitle classes={modalTitleClasses}>{children[0]}</DialogTitle>
            )}
            {children[1] && (
                <DialogContent classes={modalContent}>{children[1]}</DialogContent>
            )}
            {children[2] && (
                <DialogActions classes={modalActionsClasses}>{children[2]}</DialogActions>
            )}
            <IconButton className={classes.closeButton} onClick={onClose} disableRipple>
                <CancelIcon />
            </IconButton>
        </Dialog>
    );
};
