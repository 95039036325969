import { AxiosRequestConfig } from 'axios';

import { useAxios } from 'src/lib/http-client/use-http-client';

export const usePathInterceptor = (): void => {
    const axios = useAxios();

    const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
        config.url = !config.url?.startsWith('api') ? `api/${config.url}` : config.url;
        return config;
    };

    axios.interceptors.request.use(requestInterceptor);
};
