import { types } from 'mobx-state-tree';

import { SizeDto } from 'src/shared/types';

export const SizeItemMst = types.custom({
    name: 'NavItem',
    fromSnapshot(value: SizeDto) {
        return value;
    },
    toSnapshot(value: SizeDto) {
        return value;
    },
    isTargetType(value: SizeDto): boolean {
        const keys = new Set(Object.keys(value));
        return (
            keys.has('size') &&
            keys.has('aliasUS') &&
            keys.has('aliasUK') &&
            keys.has('aliasItl')
        );
    },
    getValidationMessage(value: SizeDto): string {
        return `${value} doesn't look like a valid structure`;
    },
});
