import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_COLORS } from 'src/theming/theme';
import { getMediaSelectorKey } from 'src/shared/utils';
import { centerAlignByFlex } from 'src/theming/mixins';

export const useSelectClasses = makeStyles((theme: Theme) => {
    return {
        select: {
            '& .MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
                borderRadius: 4,
                width: 18,
                height: 20,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: `${theme.spacing(3)}!important`,
                paddingLeft: theme.spacing(1),
                fontSize: '1.4rem',
                backgroundColor: THEME_COLORS.MAIN_BORDER_COLOR,
                border: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
                textAlign: 'center',
            },
            '& 	.MuiSelect-icon': {
                fontSize: 20,
            },
        },
        outlined: {
            fontSize: '1.4rem',
            marginLeft: 5,
            textAlign: 'center',
            paddingTop: 5,
            '& 	.MuiSelect-icon': {
                fontSize: 20,
            },
        },
    };
});

export const useClasses = makeStyles((theme: Theme) => {
    return {
        main: {
            display: 'flex',
            height: 38,
            alignItems: 'center',
            backgroundColor: theme.palette.primary.dark,
            padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(5)}`,
            borderRadius: 10,
        },
        text: {
            padding: '0 8px',
            fontSize: '1.4rem',
        },
        button: {
            height: 22,
            marginRight: 24,
            boxShadow: 'none',
            padding: '0 0 0 4px',
            borderRadius: 4,
            border: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            backgroundColor: THEME_COLORS.MAIN_BORDER_COLOR,
            '& .MuiSvgIcon-root': {
                fontSize: 15,
            },
            '& .MuiButtonBase-root': {
                borderRadius: 0,
                '&:first-child': {
                    borderRight: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
                },
            },
        },
        paginationWrapper: {
            [getMediaSelectorKey('max-width: 450px')]: {
                display: 'flex',
                flexDirection: 'column',
                height: 80,
            },
            [getMediaSelectorKey('max-width: 345px')]: {
                height: 100,
            },
        },
        choosingWrapper: {
            ...centerAlignByFlex(),
            [getMediaSelectorKey('max-width: 450px')]: {
                marginBottom: 15,
            },
        },
        portionWrapper: {
            ...centerAlignByFlex(),
        },
    };
});
