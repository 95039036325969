import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { List, ListItem, ListItemText, Typography } from '@mui/material';

import { useBaseLayoutStyles } from '../../BaseLayoutStyles';
import {
    generateAboutAsList,
    helpServiceList,
    paymentOptionList,
    socialNetworkList,
} from './footerListConfigs';

export const BaseLayoutFooter = observer((): ReactElement => {
    const classes = useBaseLayoutStyles();
    const { t } = useTranslation(['common']);

    const aboutUsList = generateAboutAsList();

    return (
        <footer className={classes.footer}>
            <div className={classes.footerLinkLists}>
                <List dense disablePadding className={classes.fullFlexWidth}>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='h6' className={classes.listTitle}>
                                {t(aboutUsList.title)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <div className={classes.rowListItems}>
                        {aboutUsList.items.map((item) => {
                            return (
                                <ListItem key={item.id}>
                                    <ListItemText>
                                        <a
                                            target='_blank'
                                            rel='noreferrer'
                                            href={item.href}
                                        >
                                            {t(item.text)}
                                        </a>
                                    </ListItemText>
                                </ListItem>
                            );
                        })}
                    </div>
                </List>
                <List dense disablePadding className={classes.fullFlexWidth}>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='h6' className={classes.listTitle}>
                                {t(helpServiceList.title)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <div className={classes.rowListItems}>
                        {helpServiceList.items.map(
                            ({ id, href, text, spaLink, download }) => {
                                return (
                                    <ListItem key={id}>
                                        <ListItemText>
                                            {spaLink ? (
                                                <Link to={href}>{t(text)}</Link>
                                            ) : download != null ? (
                                                <a href={href} download={download}>
                                                    {t(text)}
                                                </a>
                                            ) : (
                                                <a
                                                    href={href}
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    {t(text)}
                                                </a>
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                );
                            }
                        )}
                    </div>
                </List>
                <List disablePadding className={classes.halfFlexWidth}>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='h6' className={classes.listTitle}>
                                {t(paymentOptionList.title)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <div
                        className={clsx(classes.paymentMethodItems, classes.rowListItems)}
                    >
                        {paymentOptionList.items.map(({ id, src, alt }) => {
                            return (
                                <ListItem key={id} sx={{ marginTop: 3 }}>
                                    <div className={clsx(classes.paymentMethodItem)}>
                                        <img
                                            src={src}
                                            alt={alt}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </ListItem>
                            );
                        })}
                    </div>
                </List>
                <List disablePadding className={classes.halfFlexWidth}>
                    <ListItem>
                        <ListItemText>
                            <Typography variant='h6' className={classes.listTitle}>
                                {t(socialNetworkList.title)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <div className={classes.socialNetworksListItems}>
                        {socialNetworkList.items.map(({ id, Component, href }) => {
                            return (
                                <ListItem key={id} sx={{ marginTop: 3 }}>
                                    <a rel='noreferrer' target='_blank' href={href}>
                                        <Component />
                                    </a>
                                </ListItem>
                            );
                        })}
                    </div>
                </List>
            </div>
            <div className={classes.copyRightAndDelivaryInfo}>
                <Typography variant='body2'>
                    {t('common:footer.copyRightInfo', {
                        currentYear: new Date().getFullYear(),
                    })}
                </Typography>
            </div>
        </footer>
    );
});
