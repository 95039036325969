import React, { ReactElement, BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { withTooltip } from 'src/shared/hocs';

import { useProductCardAddToCartListStyles } from './ProductCardAddToCartListStyles';

const ButtonWithTooltip = withTooltip(Button);

interface ProductCardAddToCartListProps {
    langNs: string[];
    onClick: (e: BaseSyntheticEvent) => void;
    shippingAvailable?: boolean;
    reserved?: boolean;
    alreadyInCart?: boolean;
}

export const getAddToCartBtnTooltip = (
    shippingAvailable: boolean,
    reserved: boolean
): string => {
    if (!shippingAvailable) {
        return 'common:commonTooltips.localOnlyMessage';
    }
    if (reserved) {
        return 'common:commonTooltips.notAvailableForPurchasingBecauseOfReservedMessage';
    }
    return '';
};

export const ProductCardAddToCartList = ({
    reserved,
    shippingAvailable,
    langNs,
    onClick,
    alreadyInCart,
}: ProductCardAddToCartListProps): ReactElement => {
    const { t } = useTranslation();
    const classes = useProductCardAddToCartListStyles();
    return (
        <ButtonWithTooltip
            needTooltip
            disableInteractive
            tooltipTitle={getAddToCartBtnTooltip(shippingAvailable, reserved)}
            langNs={langNs}
            disabled={!shippingAvailable || reserved || alreadyInCart}
            color='secondary'
            variant='contained'
            className={classes.addToCartBtn}
            onClick={onClick}
        >
            {t('common:addToShoppingCart')}
        </ButtonWithTooltip>
    );
};
