import React, { ReactElement } from 'react';
import { GridLoader } from 'react-spinners';

import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useSuspenseProgressStyles = makeStyles(() => {
    return {
        container: {
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
        },
        loader: {
            display: 'flex',
            width: 45,
            flexWrap: 'wrap',
        },
    };
});

export const SuspenseProgress = (): ReactElement => {
    const theme = useTheme();
    const classes = useSuspenseProgressStyles();
    return (
        <div className={classes.container}>
            <div className={classes.loader}>
                <GridLoader size={10} color={theme.palette.secondary.main} />
            </div>
        </div>
    );
};
