import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { CustomAutocomplete } from 'src/components/fields';
import { useStore } from 'src/mst/StoreProvider';
import { SelectableItem } from 'src/shared/types';

import { useAutocompleteFilterStyles } from './AutocompleteFilterStyles';

interface AutocompleteFilterProps {
    label: string;
    id: string;
    name: string;
    languageNamespaces: string[];
    placeholder?: string;
    popperMinWidth?: number;
    width?: number;
}

export const AutocompleteFilter = observer(
    ({
        label,
        name,
        languageNamespaces,
        placeholder,
        popperMinWidth,
        width,
    }: AutocompleteFilterProps): ReactElement => {
        const classes = useAutocompleteFilterStyles();
        const { i18n } = useTranslation();

        const { productsList, selectionValues } = useStore();

        const items: SelectableItem[] | null =
            selectionValues.getSelectionItemsByName(name);

        useEffect(() => {
            selectionValues.fetchSelectionValues(name);
        }, [i18n.language, name]);

        const currentValues = productsList
            .getFilterValues(name)
            .map((item) => item.value);

        const handleChange = (name: string, values: string[]): void => {
            if (Array.isArray(items) && items.length > 0) {
                const mappedValues = values.map((value) => {
                    const foundValue = items.find((item) => item.value === value);
                    return {
                        filter: name,
                        titles: [() => foundValue?.title || ''] || [],
                        value: foundValue?.value || '',
                    };
                });
                productsList.setFilterValues(name, mappedValues);
            }
        };

        return (
            <div
                className={classes.filterContainer}
                style={{ width: width ?? undefined }}
            >
                <CustomAutocomplete
                    multiple
                    tLabelKey={label}
                    nameSpaces={languageNamespaces}
                    name={name}
                    tPlaceholderKey={placeholder}
                    items={items}
                    value={currentValues}
                    handleChange={handleChange}
                    popperMinWidth={popperMinWidth}
                />
            </div>
        );
    }
);
