import React, { CSSProperties, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, InputLabel, FormHelperText } from '@mui/material';

import {
    useFormControlStyles,
    useLabelStyles,
    useFormHelperText,
} from './BaseInputWrapperStyles';

interface BaseInputWrapperProps {
    id: string;
    children: ReactElement;
    required?: boolean;
    label?: string;
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    variant?: 'standard' | 'outlined' | 'filled';
    helperText?: string;
    languageNamespaces?: string[];
    error?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
}

export const BaseInputWrapper = ({
    id,
    children,
    color,
    variant,
    label,
    required,
    helperText,
    languageNamespaces,
    error,
    disabled,
    style,
}: BaseInputWrapperProps): ReactElement => {
    const formControlClasses = useFormControlStyles();
    const formHelperTextClasses = useFormHelperText();
    const labelClasses = useLabelStyles();
    const { t } = useTranslation(languageNamespaces);
    return (
        <FormControl
            disabled={disabled}
            classes={formControlClasses}
            required={required}
            color={color}
            variant={variant}
            error={error}
            style={style}
        >
            {label && (
                <InputLabel id={id} classes={labelClasses} error={error}>
                    {t(label)}
                </InputLabel>
            )}
            {children}
            {helperText && (
                <FormHelperText classes={formHelperTextClasses} error={error}>
                    {t(helperText)}
                </FormHelperText>
            )}
        </FormControl>
    );
};

BaseInputWrapper.defaultProps = {
    color: 'secondary',
    variant: 'outlined',
    required: false,
    error: false,
    disabled: false,
};
