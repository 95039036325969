import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';

interface useAutoCompleteStylesProps {
    popperMinWidth: number;
}

export const useAutoCompleteStyles = makeStyles((theme: Theme) => {
    return {
        clearIndicator: {
            '& .MuiSvgIcon-root': {
                fontSize: 0,
            },
        },
        popper: {
            minWidth: ({ popperMinWidth }: useAutoCompleteStylesProps) => popperMinWidth,
        },
        popupIndicator: {
            top: 'calc(50% - 10px)',
            '& .MuiSvgIcon-root': {
                fontSize: 25,
            },
        },
        textField: {
            '& .MuiOutlinedInput-root': {
                height: 40,
            },
            '& .MuiInputBase-input.MuiAutocomplete-input': {
                color: theme.palette.primary.contrastText,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                padding: 0,
                textOverflow: 'ellipsis',
            },
            '& .MuiInputBase-input.MuiAutocomplete-input::placeholder': {
                color: THEME_COLORS.MAIN_BORDER_COLOR,
                opacity: 1,
            },
        },
    };
});

export const useCheckBoxStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            color: THEME_COLORS.MAIN_BORDER_COLOR,
            width: 20,
            height: 20,
            marginRight: theme.spacing(2),
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        checked: {
            color: `${theme.palette.secondary.main}!important`,
        },
    };
});
