import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useGoodsListFilterPanelStyles = makeStyles((theme: Theme) => {
    return {
        filterContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            gap: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        hidFilterItemContainer: {
            display: 'none',
        },
        filterItemContainer: {
            width: '13%',
            '& .MuiInputLabel-root': {
                fontSize: '1.4rem',
            },
            '& .MuiOutlinedInput-notchedOutline legend': {},
            '@media screen and (max-width: 1750px)': {
                width: '15%',
            },
            [theme.breakpoints.down('xl')]: {
                width: '18%',
            },
            [theme.breakpoints.down('lg')]: {
                width: '22%',
            },
            [getMediaSelectorKey('max-width: 800px')]: {
                width: '48%',
            },
            [theme.breakpoints.down('ml')]: {
                width: '100%',
            },
        },
        filterChipsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            gap: theme.spacing(2),
        },
        moreFiltersButton: {
            [theme.breakpoints.down('ml')]: {
                width: '100%',
            },
        },
    };
});
