import { types, getRoot, getEnv, flow, Instance } from 'mobx-state-tree';

import { SignUpFormType } from 'src/components/forms/SignUpForm/SignUpFormType';
import {
    SignUpDto,
    AuthResponse,
    ResponseError,
    ResetPasswordDto,
    ChangePasswordDto,
} from 'src/shared/types';
import { applicationVersion } from 'src/environment';

import { StoreModel } from '../root';
import { signUpDtoFormatter } from './formatters';

export type AuthenticationModel = Instance<typeof Authentication>;

export const Authentication = types
    .model('AuthenticationModel', {
        authModule: types.maybeNull(types.string),
        isAuth: types.boolean,
        isTokensLoading: types.boolean,
    })
    .actions((self) => {
        const { wishList, bookingPurchaseList, bookingReservationList }: StoreModel =
            getRoot(self);
        return {
            setAuthModule(value: string): void {
                self.authModule = value;
            },
            setAuth(value: boolean): void {
                self.isAuth = value;
                if (value) {
                    this.initializeAppData();
                }
            },
            setTokensLoading(value: boolean) {
                self.isTokensLoading = value;
            },
            initializeAppData(): void {
                const timer = setTimeout(async () => {
                    await wishList.updateUserWishList();
                    await bookingPurchaseList.updateUserPurchaseList();
                    wishList.fetchWishListItemIds();
                    bookingPurchaseList.fetchBookingPurchaseList();
                    bookingReservationList.fetchBookingReservationList();
                    clearTimeout(timer);
                }, 0);
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient, storageService },
        } = getEnv(self);
        const {
            user,
            wishList,
            bookingPurchaseList,
            bookingReservationList,
        }: StoreModel = getRoot(self);
        return {
            signUp: flow(function* (values: SignUpFormType) {
                const body: SignUpDto = signUpDtoFormatter(values);
                yield httpClient.post('users/retail-customers/register', body);
            }),
            signIn: flow(function* ({
                email,
                password,
            }: {
                email: string;
                password: string;
            }) {
                const data: AuthResponse = yield httpClient.post('users/login', {
                    email,
                    password,
                    userType: 'RetailCustomer',
                });
                storageService.setTokens(data.accessToken, data.refreshToken);
                self.setAuthModule(null);
                self.setAuth(true);
            }),
            logout: flow(function* () {
                try {
                    yield httpClient.post('users/logout');
                } catch (error) {
                    Promise.reject(error);
                } finally {
                    self.setAuth(false);
                    user.clearUserData();
                    wishList.clearWishList();
                    bookingPurchaseList.clearPurchaseList();
                    bookingReservationList.clearReservationList();
                    storageService.clear();
                }
            }),
            checkAuth: flow(function* () {
                const refreshToken = storageService.getItem('refreshToken');
                if (refreshToken) {
                    try {
                        self.setTokensLoading(true);
                        const data: AuthResponse = yield httpClient.post(
                            `api/users/refresh-token`,
                            { refreshToken },
                            {
                                axiosConfig: {
                                    headers: { AppVersion: applicationVersion },
                                },
                            }
                        );
                        storageService.setTokens(data.accessToken, data.refreshToken);
                        self.setAuth(true);
                    } catch (response) {
                        const refreshTokenErrors: string[] = (response as ResponseError)
                            ?.error?.response?.data?.errors?.refreshToken;
                        if (
                            !refreshTokenErrors ||
                            (Array.isArray(refreshTokenErrors) &&
                                refreshTokenErrors?.some(
                                    (item) =>
                                        item ===
                                            "Refresh token doesn't match the saved token" ||
                                        item ===
                                            'Refresh token has expired, user needs to relogin' ||
                                        item === 'Refresh token has been revoked.' ||
                                        item === "Refresh token doesn't exist."
                                ))
                        ) {
                            storageService.removeTokens();
                            self.setAuth(false);
                            user.clearUserData();
                        }
                    } finally {
                        self.setTokensLoading(false);
                    }
                } else {
                    self.setAuth(false);
                    user.clearUserData();
                }
            }),
            restorePassword: flow(function* (email: string) {
                if (email) {
                    yield httpClient.post('users/reset-password', {
                        email,
                        userType: 'RetailCustomer',
                    });
                }
            }),
            resetPassword: flow(function* (body: ResetPasswordDto) {
                yield httpClient.put('users/confirm-reset-password', {
                    ...body,
                    userType: 'RetailCustomer',
                });
            }),
            changePassword: flow(function* (body: ChangePasswordDto) {
                yield httpClient.put('users/change-password', body);
            }),
        };
    });
