import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useRepresentationSection = makeStyles((theme: Theme) => {
    return {
        container: {
            overflow: 'hidden',
            border: `2px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            borderRadius: 10,
            [getMediaSelectorKey('max-width: 660px')]: {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        header: {
            borderBottom: `2px solid ${THEME_COLORS.LIGHT_BORDER_COLOR}`,
            backgroundColor: theme.palette.primary.light,
        },
    };
});
