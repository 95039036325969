import { types, Instance, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

import { ImagePreviewMst } from 'src/mst/customTypes';

export const PurchaseHistoryItem = types.model('PurchaseHistoryItem', {
    id: types.identifier,
    shopName: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
    workhours: types.maybeNull(types.string),
    amount: types.maybeNull(types.number),
    stock: types.maybeNull(types.number),
    color: types.maybeNull(types.string),
    size: types.maybeNull(types.string),
    shopId: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    isNewGood: types.maybeNull(types.boolean),
    reserved: types.maybeNull(types.boolean),
    previewImage: types.maybeNull(ImagePreviewMst),
    shippingAvailable: types.maybeNull(types.boolean),
    shippingCosts: types.maybeNull(types.number),
    freeOfShippingCosts: types.maybeNull(types.number),
    itemId: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
});

export type PurchaseHistoryItemModel = Instance<typeof PurchaseHistoryItem>;
export type IPurchaseHistoryItemSnapshotOut = SnapshotOut<typeof PurchaseHistoryItem>;
export type IPurchaseHistoryItemSnapshotIn = SnapshotIn<typeof PurchaseHistoryItem>;
