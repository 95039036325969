export enum SignUpFormFieldNames {
    salutation = 'salutation',
    name = 'name',
    email = 'email',
    surname = 'surname',
    password = 'password',
    repeatPassword = 'repeatPassword',
    street = 'street',
    houseNumber = 'number',
    postcode = 'postcode',
    place = 'place',
    country = 'country',
    phoneNumber = 'phoneNumber',
}
