import React, { ReactElement, BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import { ClassNameMap } from '@mui/styles';

import { useAddToReservationList } from 'src/shared/hooks';
import { withTooltip } from 'src/shared/hocs';

import { Loader } from '../../Loader';

interface ProductCardAddToReservationList {
    id: string;
    isAuth: boolean;
    langNs: string[];
    classes: ClassNameMap;
    reserved: boolean;
    onReserveSuccess: () => void;
    alreadyInCart?: boolean;
}

export const ProductCardAddToReservationList = withTooltip(
    ({
        id,
        isAuth,
        langNs,
        classes,
        reserved,
        onReserveSuccess,
        alreadyInCart,
    }: ProductCardAddToReservationList): ReactElement => {
        const { t } = useTranslation(langNs);

        const onAddToReservationListSuccess = (): void => {
            onReserveSuccess();
        };

        const { mutateAsync, isLoading: isAddToReservationListLoading } =
            useAddToReservationList({
                id,
                onAddToReservationListSuccess,
            });

        const reserve = (e: BaseSyntheticEvent): void => {
            e.stopPropagation();
            mutateAsync(id);
        };

        return (
            <Button
                disabled={
                    isAddToReservationListLoading || !isAuth || reserved || alreadyInCart
                }
                endIcon={isAddToReservationListLoading && <Loader />}
                color='secondary'
                variant='contained'
                className={classes.addToCartBtn}
                onClick={reserve}
            >
                {t('common:reserve')}
            </Button>
        );
    }
);
