import { InputBaseComponentProps } from '@mui/material';

import { SelectableItem } from 'src/shared/types';
import { salutationDropDownOptions } from 'src/shared/dropdownOptions';

import { SignUpFormFieldNames } from './SignUpFormFieldNames';
import { SignUpFormType } from './SignUpFormType';

export interface SignUpFormConfigItem {
    type: string;
    id: string;
    label: string;
    name: keyof SignUpFormType;
    columnSize?: number;
    required?: boolean;
    inputProps?: InputBaseComponentProps;
    items?: SelectableItem[];
}

export const useSignUpFormConfig = ({
    countries,
}: {
    countries: SelectableItem[];
}): SignUpFormConfigItem[] => {
    return [
        {
            type: 'select',
            id: SignUpFormFieldNames.salutation,
            label: 'authentication:salutation',
            name: SignUpFormFieldNames.salutation,
            items: salutationDropDownOptions,
            columnSize: 12,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.name,
            label: 'authentication:name',
            name: SignUpFormFieldNames.name,
            columnSize: 6,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.surname,
            label: 'authentication:surname',
            name: SignUpFormFieldNames.surname,
            required: true,
            columnSize: 6,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.email,
            label: 'authentication:email',
            name: SignUpFormFieldNames.email,
            required: true,
            inputProps: { type: 'email', autoComplete: 'email' },
            columnSize: 12,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.password,
            label: 'authentication:password',
            name: SignUpFormFieldNames.password,
            required: true,
            inputProps: { type: 'password', autoComplete: 'new-password' },
            columnSize: 12,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.repeatPassword,
            label: 'authentication:repeatPassword',
            name: SignUpFormFieldNames.repeatPassword,
            required: true,
            inputProps: { type: 'password', autoComplete: 'new-password' },
            columnSize: 12,
        },
        {
            type: 'phone',
            id: SignUpFormFieldNames.phoneNumber,
            label: 'authentication:phoneNumber',
            name: SignUpFormFieldNames.phoneNumber,
            columnSize: 12,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.street,
            label: 'authentication:street',
            name: SignUpFormFieldNames.street,
            required: true,
            columnSize: 6,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.houseNumber,
            label: 'authentication:houseNumber',
            name: SignUpFormFieldNames.houseNumber,
            required: true,
            columnSize: 6,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.postcode,
            label: 'authentication:postcode',
            name: SignUpFormFieldNames.postcode,
            required: true,
            columnSize: 6,
        },
        {
            type: 'text',
            id: SignUpFormFieldNames.place,
            label: 'authentication:place',
            name: SignUpFormFieldNames.place,
            required: true,
            columnSize: 6,
        },
        {
            type: 'select',
            id: SignUpFormFieldNames.country,
            label: 'authentication:country',
            name: SignUpFormFieldNames.country,
            required: true,
            items: countries,
            columnSize: 12,
        },
    ];
};
