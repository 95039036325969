import React, { ReactElement } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const BaseLayoutHeaderLogoLines = (): ReactElement => {
    const theme = useTheme();
    const upLg = useMediaQuery(theme.breakpoints.up('lg'));

    const firstLineStart = 0;
    const secondLineStart = upLg ? 12 : 7.75;
    const thirdLineStart = upLg ? 24 : 15.5;

    const lineThickness = upLg ? 8 : 5;

    const height = upLg ? 35 : 25;

    return (
        <svg width='100%' height={height} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fill='url(#a)' d={`M0 ${firstLineStart}h5000v${lineThickness}H0z`} />
            <path fill='url(#b)' d={`M0 ${secondLineStart}h5000v${lineThickness}H0z`} />
            <path fill='url(#c)' d={`M0 ${thirdLineStart}h5000v${lineThickness}H0z`} />
            <defs>
                <linearGradient id='a' x1='0' y1='16.504' x2='100%' y2='23.015'>
                    <stop stopColor='#84AC9F' />
                    <stop offset='1' stopColor='#84AC9F' stopOpacity='0' />
                </linearGradient>
                <linearGradient id='b' x1='0' y1='23.504' x2='100%' y2='30.015'>
                    <stop stopColor='#53858C' />
                    <stop offset='1' stopColor='#53858C' stopOpacity='0' />
                </linearGradient>
                <linearGradient id='c' x1='0' y1='31.504' x2='100%' y2='38.016'>
                    <stop stopColor='#30627A' />
                    <stop offset='1' stopColor='#30627A' stopOpacity='0' />
                </linearGradient>
            </defs>
        </svg>
    );
};
