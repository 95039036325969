import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Checkbox,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useCheckBoxStyles, useMenuStyles, useSelectStyles } from './SelectFieldStyles';

export type SelectItemType = {
    title: string;
    value: string;
    disabled?: boolean;
    hidden?: boolean;
};

export type SelectFieldProps = {
    id: string;
    languageNamespaces: string[];
    tLabelKey: string;
    tPlaceholderKey?: string;
    required?: boolean;
    name: string;
    value: string[];
    items?: SelectItemType[];
    multiple?: boolean;
    error?: boolean;
    columnAndDropdownsPerPage?: number;
    handleChange?: (name: string, values: string[]) => void;
    className?: string;
    disabled?: boolean;
};

export const SelectField = React.memo(
    ({
        id,
        languageNamespaces,
        items,
        name,
        value,
        handleChange,
        tLabelKey,
        tPlaceholderKey,
        multiple,
        disabled,
        ...rest
    }: SelectFieldProps): JSX.Element => {
        const selectClasses = useSelectStyles();

        const menuClasses = useMenuStyles();
        const checkboxClasses = useCheckBoxStyles();

        const { t } = useTranslation(['common', ...languageNamespaces]);

        const handleChangeValue = useCallback(
            (event: SelectChangeEvent<string[]>): void => {
                if (typeof handleChange === 'function') {
                    handleChange(name, event.target.value as string[]);
                }
            },
            [handleChange, multiple, name]
        );

        const handleRenderValue = useCallback(
            (selected: string[]): string => {
                if (selected && multiple) {
                    const selectedItems = selected
                        .map((item) => items?.find((i) => i.value === item)?.title)
                        .filter((item) => Boolean(item))
                        .join(', ');
                    return selectedItems;
                }
                return (
                    t(
                        items?.find((i) => {
                            return value.includes(i.value);
                        })?.title
                    ) ?? t(tPlaceholderKey ?? tLabelKey)
                );
            },
            [multiple, items, value]
        );

        return (
            <Select
                id={id}
                multiple={multiple}
                disabled={disabled}
                name={name}
                classes={selectClasses}
                placeholder={tPlaceholderKey && t(tPlaceholderKey)}
                onChange={handleChangeValue}
                value={value}
                IconComponent={KeyboardArrowDownIcon}
                renderValue={handleRenderValue}
                label={t(tLabelKey)}
                MenuProps={{ style: { zIndex: 11000 } }}
                inputProps={{ id }}
                {...rest}
            >
                {!items?.length && (
                    <MenuItem disabled value='' classes={menuClasses}>
                        <ListItemText>{t('common:noOptions')}</ListItemText>
                    </MenuItem>
                )}
                {items?.map((item) => {
                    if (item.hidden) {
                        return null;
                    }
                    return (
                        <MenuItem
                            disabled={item.disabled}
                            key={item.value}
                            value={item.value}
                            classes={menuClasses}
                        >
                            {multiple && (
                                <Checkbox
                                    classes={checkboxClasses}
                                    checked={
                                        Array.isArray(value)
                                            ? value?.includes(item.value)
                                            : false
                                    }
                                />
                            )}
                            <ListItemText>{t(item.title)}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }
);
