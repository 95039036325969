import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs as MuiBreadcrumbs, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useBreadcrumpsStyle } from './BreadcumpsStyles';

export interface BreadcrumpsItem {
    key: string;
    title: string;
    href: string;
    onClick?: () => void | Promise<void>;
}

interface BreadcrumpsProps {
    breadcrumps: BreadcrumpsItem[];
    namespaces?: string[];
}

export const Breadcrumps = ({
    breadcrumps,
    namespaces,
}: BreadcrumpsProps): ReactElement => {
    const classes = useBreadcrumpsStyle();
    const { t } = useTranslation(namespaces);

    return (
        <Stack spacing={2}>
            <MuiBreadcrumbs
                separator={<NavigateNextIcon />}
                aria-label='breadcrumb'
                className={classes.breadcrumps}
            >
                {breadcrumps.map((item, index, arr) => {
                    if (index === arr.length - 1) {
                        return (
                            <Typography
                                key={item.key}
                                className={clsx(classes.item, classes.activeBreadcump)}
                            >
                                {t(item.title)}
                            </Typography>
                        );
                    }

                    return (
                        <Link
                            key={item.key}
                            to={item.href}
                            onClick={item.onClick}
                            className={classes.item}
                        >
                            {t(item.title)}
                        </Link>
                    );
                })}
            </MuiBreadcrumbs>
        </Stack>
    );
};
