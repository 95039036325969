import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { Spacings } from 'src/shared/constants';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useProductDetailedViewLayoutStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            height: '100%',
            alignContent: 'flex-start',
            justifyContent: 'space-between',
            gap: theme.spacing(5),
            padding: `${theme.spacing(6)} ${Spacings.xxl}px`,
            [theme.breakpoints.down('xl')]: {
                gap: theme.spacing(4),
                padding: `${theme.spacing(4)} ${Spacings.xl}px`,
            },
            [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(3),
                padding: `${theme.spacing(3)} ${Spacings.sm}px`,
            },
            [theme.breakpoints.down('mm')]: {
                gap: theme.spacing(1),
                padding: `${theme.spacing(3)} ${Spacings.mm}px`,
            },
        },
        breadcrumps: {
            width: '100%',
        },
        carousel: {
            width: '55%',
            [theme.breakpoints.down('lg')]: {
                width: '50%',
            },
            [getMediaSelectorKey('max-width: 800px')]: {
                width: '100%',
            },
            [getMediaSelectorKey('max-width: 700px')]: {
                height: '100%',
            },
        },
        info: {
            width: '40%',
            [theme.breakpoints.down('lg')]: {
                width: '45%',
            },
            [getMediaSelectorKey('max-width: 800px')]: {
                width: '100%',
            },
            [getMediaSelectorKey('max-width: 550px')]: {
                marginTop: 45,
            },
        },
        mayLikeProductsList: {
            width: '100%',
            [getMediaSelectorKey('max-width: 800px')]: {
                marginTop: theme.spacing(6),
            },
        },
        adaptiveLayoutContainer: {
            display: 'none',
            [getMediaSelectorKey('max-width: 800px')]: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: -80,
            },
            [getMediaSelectorKey('max-width: 550px')]: {
                marginBottom: 0,
            },
        },
        hidden: {
            [getMediaSelectorKey('max-width: 800px')]: {
                display: 'none',
            },
        },
    };
});
