import React, { ReactElement, ComponentType } from 'react';

import { Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useWithBadgeStyles = makeStyles(() => {
    return {
        badge: {
            width: 'fit-content',
            minWidth: 15,
            height: 15,
            right: 5,
            top: 5,
        },
    };
});

interface withBadgeProps {
    badgeValue: number | string;
}

export const withBadge = function <T>(
    Component: ComponentType<T>
): ComponentType<T & withBadgeProps> {
    return ({ badgeValue, ...rest }: T & withBadgeProps): ReactElement => {
        const restParams: unknown = { ...rest };
        const classes = useWithBadgeStyles();

        return (
            <Badge
                classes={classes}
                badgeContent={badgeValue}
                color='error'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Component {...(restParams as T)} />
            </Badge>
        );
    };
};
