import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { Spacings } from 'src/shared/constants';

export const useItemsHistoryLayoutStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: `${theme.spacing(6)} ${Spacings.xxl}px`,
            columnGap: theme.spacing(5),

            [theme.breakpoints.down('xl')]: {
                padding: `${theme.spacing(4)} ${Spacings.xl}px`,
                columnGap: theme.spacing(4),
            },
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)} ${Spacings.sm}px`,
                columnGap: theme.spacing(2),
            },
        },
        navContainer: {
            width: '100%',
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down('xl')]: {
                marginBottom: theme.spacing(3),
            },
            [theme.breakpoints.down('xl')]: {
                marginBottom: theme.spacing(2),
            },
        },
        listContainer: {
            width: '100%',
            flexGrow: 1,
        },
    };
});
