import { useMemo } from 'react';

interface useBuildAddressStringParams {
    street: string;
    number: string;
    zip: string;
    city: string;
    country: string;
}

const addressPattern = '{street} {number}, {zip} {city}, {country}';

export const useBuildAddressString = ({
    street,
    number,
    zip,
    city,
    country,
}: useBuildAddressStringParams): string =>
    useMemo(() => {
        return addressPattern
            .replace('{street}', street || '')
            .replace('{number}', number || '')
            .replace('{zip}', zip || '')
            .replace('{city}', city || '')
            .replace('{country}', country || '');
    }, [street, number, zip, city, country]);
