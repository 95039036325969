import React, { BaseSyntheticEvent, ReactElement, useMemo } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    CardActionArea,
    Typography,
    Grid,
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StoreIcon from '@mui/icons-material/Store';

import { AppRoutes } from 'src/routing/appRoutes';
import { ImagePreview } from 'src/shared/types';
import { useStore } from 'src/mst/StoreProvider';
import { useRemoteImage } from 'src/shared/hooks';

import { ShimmingLoader } from '../ShimmingLoader';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { ProductCardAddToReservationList, ProductCardAddToCartList } from './components';

import {
    useProductCardStyles,
    useProductCardActionAreaStyles,
} from './ProductCardStyles';

export interface ProductCardProps {
    id?: string;
    isNewGood?: boolean;
    reserved?: boolean;
    name?: string;
    price?: number;
    sizes?: string[];
    previewImage?: ImagePreview;
    subCategory?: string;
    withBorder?: boolean;
    borderColor?: string;
    shippingAvailable?: boolean;
    shopId?: string;
    shopName?: string;
    shopLogoSrc?: string;
}

const defaultImageSrc = '/static/images/no-item-preview.svg';

interface NoItemPreviewProps {
    minHeight?: number | string;
}

const getTooltipTitle = (isReserved: boolean, isAuth: boolean): string => {
    if (!isAuth) {
        return 'common:youShouldBeAuthorizedToReserveMessage';
    }
    if (isReserved) {
        return 'common:alreadyReserved';
    }
    return '';
};

export const NoItemPreview = ({ minHeight }: NoItemPreviewProps): ReactElement => {
    const classes = useProductCardStyles({ withBorder: false });
    return (
        <Grid
            container
            className={classes.noPreviewContainer}
            alignItems='center'
            justifyContent='center'
            sx={{
                flexGrow: 1,
                height: '100%',
                minHeight: minHeight ? `${minHeight}px!important` : null,
            }}
        >
            <img src={defaultImageSrc} alt='Product preview' />
        </Grid>
    );
};

const langNs = ['common', 'formatted-values', 'wishList'];

export const getAddToCartBtnTooltip = (shippingAvailable: boolean): string => {
    if (!shippingAvailable) {
        return 'common:commonTooltips.localOnlyMessage';
    }
    return '';
};

export const ProductCard = observer(
    ({
        id,
        name,
        price,
        isNewGood,
        reserved,
        sizes,
        previewImage,
        subCategory,
        withBorder = false,
        borderColor,
        shippingAvailable,
        shopName,
        shopLogoSrc,
    }: ProductCardProps): ReactElement | null => {
        const classes = useProductCardStyles({ withBorder, borderColor });
        const actionAreaClasses = useProductCardActionAreaStyles({
            withBorder,
            borderColor,
        });
        const navigate = useNavigate();
        const { t } = useTranslation(langNs);

        const { wishList, bookingPurchaseList, bookingReservationList, authentication } =
            useStore();

        const { isAuth } = authentication;

        const onCardClick = (): void => {
            sessionStorage.setItem('lastBrowsedItem', id);
            navigate(generatePath(AppRoutes.goodsItem, { itemId: id }));
        };

        const [src] = useRemoteImage(
            id && previewImage?.id
                ? `web-shop/items/${id}/attachments/${previewImage.id}`
                : null
        );

        const imageSrc = useMemo(() => src ?? defaultImageSrc, [src]);

        const addToCart = (e: BaseSyntheticEvent): void => {
            e.stopPropagation();
            bookingPurchaseList.addToPurchaseList({ id, amount: 1 });
            toast.success(t('wishList:addToCartSuccessMessage'));
        };

        const switchFavorite = (): void => {
            if (!wishList.isFavorite(id)) {
                wishList.addItemToFavoriteList(id);
            } else {
                wishList.removeItemFromFavorite(id);
            }
        };

        const navigateToShop = (): void => {
            navigate(generatePath(AppRoutes.shop, { name: shopName.replace(' ', '_') }));
        };

        const onReserveSuccess = (): void => {
            bookingReservationList.fetchBookingReservationList();
        };

        const isFavorite = wishList.isFavorite(id);
        const alreadyInCart = bookingPurchaseList.isPurchaseItemAlreadyInCart(id);

        return (
            <CardActionArea
                disableRipple
                disableTouchRipple
                onClick={onCardClick}
                classes={actionAreaClasses}
            >
                <Card elevation={0} className={classes.cardContainer}>
                    <div style={{ position: 'relative', flexGrow: 1 }}>
                        {!imageSrc && (
                            <div className={classes.cardMedia}>
                                <ShimmingLoader />
                            </div>
                        )}
                        {imageSrc && (
                            <div className={classes.cardMediaContainer}>
                                <CardMedia
                                    component={
                                        previewImage && imageSrc !== defaultImageSrc
                                            ? 'img'
                                            : NoItemPreview
                                    }
                                    src={imageSrc}
                                    className={classes.cardMedia}
                                />
                                <div className={classes.bottomLeftlabelsContainer}>
                                    {isNewGood && (
                                        <div className={classes.newLabel}>
                                            {t('common:new')}
                                        </div>
                                    )}
                                    {reserved && (
                                        <div className={classes.reservedLabel}>
                                            {t('common:reserved')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {!shippingAvailable && (
                            <div className={classes.topLeftlabelsContainer}>
                                <div className={classes.localOnlyLabel}>
                                    {t('common:localOnly')}
                                </div>
                            </div>
                        )}
                    </div>
                    <CardActions
                        className={clsx(classes.cardActions, {
                            [classes.noPreviewColor]: !previewImage,
                        })}
                    >
                        <IconButtonWithTooltip
                            needTooltip
                            langNs={langNs}
                            tooltipTitle={
                                !isFavorite
                                    ? 'common:commonTooltips.addToWishList'
                                    : 'common:commonTooltips.removeFromWishList'
                            }
                            size='small'
                            onClick={switchFavorite}
                            Icon={() => (
                                <>
                                    {!isFavorite && <FavoriteBorderIcon />}
                                    {isFavorite && <FavoriteIcon color='error' />}
                                </>
                            )}
                        />
                        {shopName && (
                            <IconButtonWithTooltip
                                needTooltip
                                langNs={langNs}
                                tooltipTitle={shopName}
                                onClick={navigateToShop}
                                Icon={() => (
                                    <div className={classes.shopLink}>
                                        {shopLogoSrc && (
                                            <img src={shopLogoSrc} alt=' ' width='100%' />
                                        )}
                                        {!shopLogoSrc && (
                                            <StoreIcon style={{ fontSize: 'inherit' }} />
                                        )}
                                    </div>
                                )}
                            />
                        )}
                    </CardActions>
                    <CardContent className={classes.cardContent}>
                        <Typography
                            variant='subtitle1'
                            className={classes.productHeading}
                        >
                            {name || subCategory || t('common:noName')}
                        </Typography>
                        <Typography variant='body1' className={classes.price}>
                            {t('formatted-values:formattedCurrency', { currency: price })}
                        </Typography>
                        <div className={classes.cardAdditionalInfo}>
                            <Typography
                                variant='subtitle2'
                                className={classes.productSizeHeading}
                            >
                                {t('common:availableSizes')}
                            </Typography>
                            <Typography
                                variant='body2'
                                className={classes.productSizeContent}
                            >
                                {sizes?.join(', ') || ' - '}
                            </Typography>
                            <ProductCardAddToCartList
                                shippingAvailable={shippingAvailable}
                                reserved={
                                    bookingReservationList.isReservedById(id) || reserved
                                }
                                langNs={langNs}
                                onClick={addToCart}
                                alreadyInCart={alreadyInCart}
                            />
                            <ProductCardAddToReservationList
                                needTooltip
                                disableInteractive
                                tooltipTitle={getTooltipTitle(
                                    bookingReservationList.isReservedById(id) || reserved,
                                    isAuth
                                )}
                                id={id}
                                classes={classes}
                                isAuth={isAuth}
                                langNs={langNs}
                                reserved={
                                    bookingReservationList.isReservedById(id) || reserved
                                }
                                onReserveSuccess={onReserveSuccess}
                                alreadyInCart={alreadyInCart}
                            />
                        </div>
                    </CardContent>
                </Card>
            </CardActionArea>
        );
    }
);
