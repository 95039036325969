import React, { BaseSyntheticEvent, SyntheticEvent } from 'react';
import PopupState, {
    bindTrigger,
    bindPopover,
    InjectedProps,
} from 'material-ui-popup-state';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
    Button,
    Typography,
    Popover,
    MenuItem as MaterialMenuItem,
    IconButton,
    useTheme,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { withTooltip } from 'src/shared/hocs';

import { useStyle } from './NestedMenuStyles';
import { NestedMenuItem } from './NestedMenuItem';

export interface MenuItem {
    title?: string;
    action?: () => void;
    nestedMenu?: MenuItem[];
    icon?: JSX.Element;
    isAdditionalActionActive?: boolean;
    isActive?: boolean;
    additionalAction?: () => void;
    className?: string;
}

export interface NestedMenuProps {
    menuItems: MenuItem[];
    title?: string;
    buttonIcon?: JSX.Element;
    buttonClass?: string;
    icon?: JSX.Element;
    isAdditionalActionActive?: boolean;
    additionalAction?: () => void;
    isDisabled?: boolean;
    startIcon?: JSX.Element;
    namespaces?: string[];
    ariaLabel?: string;
    needTooltip?: boolean;
    tooltipTitle?: string;
}

interface OpenNestedMenuButtonProps {
    buttonIcon?: JSX.Element;
    buttonClass?: string;
    ariaLabel?: string;
    popupState: InjectedProps;
    startIcon?: JSX.Element;
    title?: string;
    namespaces?: string[];
}

const OpenNestedMenuButton = withTooltip(
    ({
        buttonIcon,
        buttonClass,
        ariaLabel,
        popupState,
        startIcon,
        title,
        namespaces,
    }: OpenNestedMenuButtonProps) => {
        const classes = useStyle();
        const { t } = useTranslation(namespaces);
        return (
            <>
                {buttonIcon ? (
                    <IconButton
                        size='small'
                        className={clsx(classes.iconButton, buttonClass)}
                        aria-label={ariaLabel}
                        {...bindTrigger(popupState)}
                    >
                        {buttonIcon}
                    </IconButton>
                ) : (
                    <Button
                        className={clsx(classes.button, buttonClass)}
                        startIcon={startIcon}
                        endIcon={
                            popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                        color='primary'
                        aria-label={ariaLabel}
                        {...bindTrigger(popupState)}
                    >
                        <Typography className={classes.buttonText} noWrap variant='body1'>
                            {t(title || '')}
                        </Typography>
                    </Button>
                )}
            </>
        );
    }
);

export const NestedMenu = (props: NestedMenuProps): JSX.Element => {
    const classes = useStyle();
    const theme = useTheme();

    const {
        menuItems,
        title,
        buttonIcon,
        buttonClass,
        isDisabled,
        isAdditionalActionActive,
        additionalAction,
        startIcon,
        namespaces,
        ariaLabel,
        needTooltip,
        tooltipTitle,
    } = props;

    const { t } = useTranslation(namespaces);

    const handleStopPropagation = (e: SyntheticEvent): void => {
        e.stopPropagation();
    };

    const handleAdditionalAction = (e: BaseSyntheticEvent): void => {
        e.stopPropagation();
        if (typeof additionalAction === 'function') {
            additionalAction();
        }
    };

    return (
        <div
            tabIndex={-1}
            role='button'
            onClick={handleStopPropagation}
            onKeyPress={handleStopPropagation}
        >
            <PopupState variant='popover'>
                {(popupState) => {
                    return (
                        <div>
                            <OpenNestedMenuButton
                                needTooltip={needTooltip}
                                tooltipTitle={tooltipTitle}
                                langNs={namespaces}
                                popupState={popupState}
                                title={title}
                                namespaces={namespaces}
                                buttonIcon={buttonIcon}
                                buttonClass={buttonClass}
                                startIcon={startIcon}
                                ariaLabel={ariaLabel}
                            />
                            <Popover
                                {...bindPopover(popupState)}
                                disablePortal
                                className={classes.itemsContainer}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                PaperProps={{ role: 'menubar', tabIndex: 0 }}
                            >
                                {menuItems.map((item: MenuItem) =>
                                    !item.nestedMenu ? (
                                        <MaterialMenuItem
                                            tabIndex={0}
                                            disabled={isDisabled}
                                            key={item.title}
                                            className={classes.menuItem}
                                            onClick={() => {
                                                if (typeof item.action === 'function') {
                                                    item.action();
                                                }
                                                popupState.close();
                                            }}
                                            style={{
                                                backgroundColor: item.isActive
                                                    ? theme.palette.info.light
                                                    : 'inherit',
                                            }}
                                        >
                                            <Typography
                                                variant='inherit'
                                                className={clsx(
                                                    classes.menuItemText,
                                                    item.className
                                                )}
                                            >
                                                {t(item.title || '')}
                                            </Typography>
                                            {item.icon ? (
                                                <IconButton
                                                    size='small'
                                                    onClick={handleAdditionalAction}
                                                    className={clsx(
                                                        classes.additionalActive,
                                                        isAdditionalActionActive
                                                            ? classes.additionalActionActive
                                                            : null
                                                    )}
                                                >
                                                    {item.icon}
                                                </IconButton>
                                            ) : null}
                                        </MaterialMenuItem>
                                    ) : (
                                        <NestedMenuItem
                                            isDisabled={isDisabled}
                                            key={item.title}
                                            item={item}
                                            closePopup={popupState.close}
                                            namespaces={namespaces}
                                        />
                                    )
                                )}
                            </Popover>
                        </div>
                    );
                }}
            </PopupState>
        </div>
    );
};
