import React, { ReactElement, Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
    MenuList,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Typography,
    IconButton,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useStore } from 'src/mst/StoreProvider';

import { NavMenuItem } from '../MainProductNavigationMenu/NavMenuItem';
import { useProductTabletNavigationMenuStyles } from './MainProductTabletNavigationMenuStyles';
import { getPathToNavigate } from './getPathToNavigate';

interface MainProductTabletNavigationMenuProps {
    setOpen: Dispatch<SetStateAction<boolean>>;
}

export interface RouterState {
    mainTitle: string;
    currentItems: NavMenuItem;
    subCategryTitle: string;
    menuId: string;
    categoryId: string;
}

export const MainProductTabletNavigationMenu = observer(
    ({ setOpen }: MainProductTabletNavigationMenuProps): ReactElement => {
        const location = useLocation();
        const classes = useProductTabletNavigationMenuStyles();
        const navigate = useNavigate();
        const { t } = useTranslation(['common']);

        const {
            navigation: { items },
        } = useStore();

        const routerState: RouterState = (location.state as RouterState) || {
            mainTitle: '',
            menuId: '',
            categoryId: '',
            subCategryTitle: '',
            currentItems: {
                id: '',
                title: '',
                submenu: items,
            },
        };

        const onSelectMenu = (selectedMenu: NavMenuItem): void => {
            if (Array.isArray(selectedMenu.submenu) && selectedMenu.submenu.length > 0) {
                navigate(location.pathname, {
                    state: {
                        subCategryTitle: routerState.mainTitle
                            ? selectedMenu.title
                            : routerState.subCategryTitle,
                        mainTitle: !routerState.mainTitle
                            ? selectedMenu.title
                            : routerState.mainTitle,
                        currentItems: selectedMenu,
                        menuId: !routerState.menuId
                            ? selectedMenu.id
                            : routerState.menuId,
                        categoryId:
                            routerState.menuId && !routerState.categoryId
                                ? selectedMenu.id
                                : routerState.categoryId,
                        subCategoryId:
                            routerState.menuId && routerState.categoryId
                                ? selectedMenu.id
                                : '',
                    },
                });
            } else {
                setOpen(false);
                const path = getPathToNavigate(
                    !routerState.menuId ? selectedMenu.id : routerState.menuId,
                    routerState.menuId && !routerState.categoryId
                        ? selectedMenu.id
                        : routerState.categoryId,
                    routerState.menuId && routerState.categoryId ? selectedMenu.id : ''
                );
                const timer = setTimeout(() => {
                    navigate(path, {
                        state: null,
                    });
                    clearTimeout(timer);
                }, 200);
            }
        };

        const handleBack = (): void => {
            navigate(-1);
        };

        const applyDefaultNavStateState = (): void => {
            navigate(location.pathname, { state: null });
        };

        useEffect(applyDefaultNavStateState, []);

        return (
            <MenuList className={classes.menuList}>
                {routerState.mainTitle && (
                    <Typography variant='h3' tabIndex={-1} className={classes.mainTitle}>
                        {routerState.mainTitle}
                        <IconButton
                            tabIndex={0}
                            onClick={handleBack}
                            className={classes.backButton}
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                    </Typography>
                )}
                {routerState.subCategryTitle && (
                    <div>
                        <Typography variant='h4' className={classes.subCategoryTitle}>
                            {routerState.subCategryTitle}
                        </Typography>
                    </div>
                )}
                {Array.isArray(routerState.currentItems?.submenu) &&
                    routerState.currentItems?.submenu.map((item) => (
                        <MenuItem
                            tabIndex={0}
                            disableRipple
                            key={item.id}
                            onClick={() => onSelectMenu(item)}
                            className={classes.menuListItem}
                        >
                            <ListItemText>{t(item.title)}</ListItemText>
                            {Array.isArray(item.submenu) && item.submenu?.length > 0 && (
                                <ListItemIcon className={classes.menuItemIcon}>
                                    <NavigateNextIcon />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    ))}
            </MenuList>
        );
    }
);
