import { useMemo } from 'react';
import { SelectableItem } from '../types';

export const usePaginationOptions = (
    count: number,
    pageSize: number
): SelectableItem[] => {
    const pageOptions = useMemo(() => {
        if (count === 0 || pageSize === 0 || count <= pageSize) {
            return [];
        }
        const amountOfPages = Math.ceil(count / pageSize);

        const pageOptions = new Array(amountOfPages).fill(null).map((item, index) => {
            return {
                title: String(index + 1),
                value: String(index + 1),
            };
        });

        return pageOptions;
    }, [pageSize, count]);
    return pageOptions;
};
