import React, { ReactElement } from 'react';

interface FavoriteIconProps {
    width?: number;
    height?: number;
}

export const FavoriteIcon = ({ width, height }: FavoriteIconProps): ReactElement => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M16.1183 5.87743L17.1607 7.02886L17.7631 6.53545L18.4208 7.19322L7.84155 17.884L8.88148 18.8708L19.5884 7.99596C19.8977 7.68664 20.3944 6.6998 19.4077 5.71305C18.2564 4.56168 17.1961 5.02457 16.8844 5.21636C16.6117 5.38417 16.1183 5.87743 16.1183 5.87743Z'
                fill='#84AC9F'
            />
            <path
                d='M24.4865 4.92633C22.7957 -0.445381 15.9865 -1.58025 12.5259 2.29475L13.7233 3.49541C16.2562 1.15001 19.3779 1.0908 21.618 3.3408C23.799 5.52501 23.6838 8.81119 21.322 11.2355C20.1772 12.4132 19.0029 13.5579 17.8384 14.7158C15.674 16.877 13.5062 19.025 11.3647 21.1533L12.6468 22.384L22.7003 12.1862C24.7726 10.1237 25.3417 7.64343 24.4865 4.92633Z'
                fill='#30627A'
            />
            <path
                d='M12.526 2.29434L13.7365 3.48513L14.3681 4.06736L15.4766 5.17591L16.1184 5.78775L17.1609 7.03118L15.9701 8.31407C14.2036 6.53776 12.5918 4.81407 10.8714 3.20881C10.1753 2.53911 9.31262 2.06805 8.37291 1.84461C7.4332 1.62116 6.4508 1.65348 5.5278 1.9382C4.60481 2.22292 3.77492 2.74965 3.12439 3.46365C2.47386 4.17765 2.02644 5.05284 1.82862 5.99828C1.39112 7.90947 1.94046 9.58052 3.33849 10.9522C4.98322 12.5608 6.59506 13.9885 8.41414 15.7451L7.09835 17.0609C6.957 16.9195 6.90439 16.8964 6.75954 16.7516C5.19704 15.2088 3.61151 13.6858 2.08849 12.1068C-0.638487 9.28118 -0.694407 5.10684 1.91743 2.30749C4.52928 -0.491848 8.68388 -0.758296 11.7266 1.71868C11.9799 1.92591 12.2563 2.09368 12.526 2.29434Z'
                fill='#D27C58'
            />
            <path
                d='M14.3682 4.06736L15.4866 5.17591C16.6149 4.20552 17.8583 3.79433 19.2399 4.5871C21.003 5.63644 21.1774 7.74828 19.6116 9.33052L9.53955 19.5278L10.7136 20.5805C14.1741 17.1299 17.5688 13.7812 20.9109 10.3831C22.8879 8.37986 22.878 5.64302 20.9471 3.8042C19.0162 1.96538 16.3978 2.07065 14.3682 4.06736Z'
                fill='#53858C'
            />
            <path
                d='M8.88158 15.4158C6.9079 13.4882 5.17072 11.7776 3.51283 9.90584C1.97664 8.17229 2.3023 5.40255 3.97664 3.80387C5.72335 2.13281 8.20033 2.07031 10.0753 3.65913C10.3681 3.90584 10.6345 4.18216 10.9076 4.45189L15.322 8.81044L14.3092 9.98815L13.4868 9.16578C12.0428 7.74144 10.9898 6.83018 9.53914 5.41571C8.08848 4.00124 6.24967 3.84992 5.0523 5.1065C3.85493 6.36308 3.96349 8.10321 5.44375 9.6065C6.92401 11.1098 8.45724 12.6888 10.0329 14.2645L8.88158 15.4158Z'
                fill='#E5AF61'
            />
            <path
                d='M10.6739 13.6798C8.89104 11.8541 7.31539 10.2686 5.76934 8.65349C4.81539 7.66665 4.75618 6.62059 5.5325 5.7423C5.72213 5.52316 5.95535 5.34595 6.21729 5.22197C6.47923 5.09799 6.76414 5.02997 7.05383 5.02223C7.34353 5.01449 7.63166 5.06721 7.89984 5.17703C8.16803 5.28686 8.41038 5.45136 8.61144 5.66007C10.2891 7.22585 11.9009 8.87388 13.6049 10.5745L12.4338 11.8903L7.19697 6.69954L6.68043 7.19305C8.32846 8.84108 9.99007 10.6042 11.842 12.4562L10.6739 13.6798Z'
                fill='#DFC06C'
            />
            <path
                d='M15.0721 20.7241L13.4868 22.3688H25V20.7241H15.0721Z'
                fill='#30627A'
            />
            <path d='M16.059 19.7373H25V18.0925H17.7037L16.059 19.7373Z' fill='#53858C' />
            <path
                d='M18.6906 17.1057H25V15.4609C23.1783 15.4609 22.157 15.4609 20.3353 15.4609L18.6906 17.1057Z'
                fill='#84AC9F'
            />
        </svg>
    );
};

FavoriteIcon.defaultProps = {
    width: 25,
    height: 23,
};
