import React, { MouseEvent, ComponentType, useState } from 'react';

interface ImageMagnifierProps {
    magnifierHeight: number;
    magnifieWidth: number;
    zoomLevel: number;
    src: string;
}

export function withZoomOnHover<T>(Component: ComponentType<T>): ComponentType<T> {
    return (props: T) => {
        const { magnifieWidth, magnifierHeight, zoomLevel, src } = props as T &
            ImageMagnifierProps;
        const [showMagnifier, setShowMagnifier] = useState<boolean>(false);
        const [[x, y], setXY] = useState<[number, number]>([0, 0]);
        const [[imgWidth, imgHeight], setSize] = useState<[number, number]>([0, 0]);

        const onMouseEnter = (e: MouseEvent<HTMLImageElement>): void => {
            const elem = e.currentTarget;
            const { width, height } = elem.getBoundingClientRect();
            setSize([width, height]);
            setShowMagnifier(true);
        };

        const onMouseLeave = (): void => {
            setShowMagnifier(false);
        };

        const onMouseMove = (e: MouseEvent<HTMLImageElement>): void => {
            const elem = e.currentTarget;
            const { top, left } = elem.getBoundingClientRect();
            const x = e.pageX - left - window.pageXOffset;
            const y = e.pageY - top - window.pageYOffset;
            setXY([x, y]);
        };

        return (
            <div style={{ position: 'relative' }}>
                <Component
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onMouseMove={onMouseMove}
                    {...props}
                />
                <div
                    style={{
                        display: showMagnifier ? '' : 'none',
                        position: 'absolute',
                        pointerEvents: 'none',
                        height: `${magnifierHeight / zoomLevel}px`,
                        width: `${magnifieWidth / zoomLevel}px`,
                        top: `${y - magnifierHeight / zoomLevel / 2}px`,
                        left: `${x - magnifieWidth / zoomLevel / 2}px`,
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        zIndex: 100000,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        pointerEvents: 'none',
                        height: `${magnifierHeight}px`,
                        width: `${magnifieWidth}px`,
                        top: `${y + 20}px`,
                        left: `${x + 20}px`,
                        display: showMagnifier ? '' : 'none',
                        border: '1px solid lightgray',
                        backgroundColor: 'white',
                        backgroundImage: `url('${src}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: `${imgWidth * zoomLevel}px ${
                            imgHeight * zoomLevel
                        }px`,
                        backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
                        backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
                        zIndex: 100000,
                    }}
                />
            </div>
        );
    };
}
