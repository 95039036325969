import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useStore } from 'src/mst/StoreProvider';
import {
    useAppVersionInterceptor,
    usePathInterceptor,
    useAppLanguageInterceptor,
    useAuthInterceptor,
    useGlobalSearch,
} from 'src/shared/hooks';
import { RoutedContent } from 'src/routing/RoutedContent';
import { AuthenticationEntryPoint } from 'src/authentication';

const App = (): ReactElement => {
    const { authentication, user, webshops, wishList, globalSearchValue } = useStore();

    useGlobalSearch(globalSearchValue);
    useAppVersionInterceptor();
    usePathInterceptor();
    useAppLanguageInterceptor();
    useAuthInterceptor(authentication, user);

    useEffect(() => {
        authentication.checkAuth();
        webshops.fetchWebShops();
        wishList.fetchWishListItemsDataByIds(wishList.list.map((item) => item.itemId));
    }, []);

    useEffect(() => {
        if (authentication.isAuth && !authentication.isTokensLoading) {
            user.fetchRetailUserData();
        }
    }, [authentication.isAuth, authentication.isTokensLoading]);

    return (
        <>
            <AuthenticationEntryPoint />
            <RoutedContent />
            <ToastContainer position='bottom-right' style={{ zIndex: 10000000 }} />
        </>
    );
};

export default observer(App);
