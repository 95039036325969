import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            '& *': {
                color: theme.palette.primary.contrastText,
                fontSize: '1.4rem',
            },
        },
        subtitle: {
            fontWeight: theme.typography.fontWeightBold,
        },
        value: {
            lineHeight: 0.9,
        },
    };
});

interface HeaderInfoItemProps {
    title: string;
    value: string;
}

export const HeaderInfoItem = ({ title, value }: HeaderInfoItemProps): ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation(['extendedProductCard', 'formatted-values']);
    return (
        <div className={classes.container}>
            <Typography variant='subtitle1' className={classes.subtitle}>
                {t(title)}
            </Typography>
            <Typography variant='body2' className={classes.value}>
                {value}
            </Typography>
        </div>
    );
};
