import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_COLORS } from 'src/theming/theme';

export const usePriceFilterStyles = makeStyles((theme: Theme) => {
    return {
        filterBtn: {
            width: '100%',
            height: 40,
            justifyContent: 'flex-start',
            paddingTop: 6.5,
            paddingLeft: 11,
            border: `2px solid ${THEME_COLORS.INFO_WARNING_DARK_COLOR}`,
            borderRadius: 10,
            color: 'rgba(0, 0, 0, 0.6)',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '1.4rem',
            transition: '0s',
            '& .MuiButton-endIcon': {
                marginTop: '0.5px',
                marginLeft: 'auto',
            },
            '& .MuiSvgIcon-root': {
                fontSize: '2.5rem',
                color: 'rgba(0, 0, 0, 0.54)',
            },
            '&:hover': {
                borderColor: '#000000',
            },
        },
        priceFieldsContainer: {
            '& .MuiPaper-root': {
                display: 'flex',
                width: 300,
                gap: theme.spacing(2),
                padding: theme.spacing(2),
                '& .MuiInputLabel-root': {
                    paddingTop: '2px',
                    fontSize: '1.4rem',
                },
                '& .MuiOutlinedInput-notchedOutline legend': {
                    fontSize: '0.6em',
                },
                '& .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline':
                    {
                        borderColor: THEME_COLORS.INFO_WARNING_DARK_COLOR,
                    },
            },
        },
        opened: {
            borderColor: theme.palette.secondary.main,
            '& .MuiButton-endIcon': {
                transform: 'rotate(180deg)',
            },
        },
        active: {
            borderColor: theme.palette.secondary.main,
        },
    };
});
