import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useStore } from 'src/mst/StoreProvider';

import {
    useToggableGroupFilterStyles,
    useToggableBtnFilterStyles,
} from './ToggableFilterStyles';

export interface ToggableFilterProps {
    label: string;
    id: string;
    name: string;
    languageNamespaces: string[];
}

export const ToggableFilter = observer(
    ({ label, name, languageNamespaces }: ToggableFilterProps): ReactElement => {
        const { t } = useTranslation(languageNamespaces);
        const toggleGroupClasses = useToggableGroupFilterStyles();
        const toggleBtnClasses = useToggableBtnFilterStyles();

        const { productsList } = useStore();
        const value: boolean | null = productsList.getSeparateFilterValue(name);

        const handleChange = (): void => {
            productsList.setToggableFilterValue(name, !value);
        };

        return (
            <ToggleButtonGroup classes={toggleGroupClasses} onChange={handleChange}>
                <ToggleButton
                    selected={value || false}
                    value={name}
                    classes={toggleBtnClasses}
                >
                    {t(label)}
                    {value && <CloseIcon />}
                </ToggleButton>
            </ToggleButtonGroup>
        );
    }
);
