import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Box, useTheme, useMediaQuery, LinearProgress } from '@mui/material';

import { StoreLinks, ShortCutNav, LanguageSelector } from 'src/components/base';
import { BaseInputWrapper, SearchField } from 'src/components/fields';
import { AppRoutes } from 'src/routing/appRoutes';
import { useStore } from 'src/mst/StoreProvider';
import { useBaseLayoutStyles } from '../../BaseLayoutStyles';

import { BaseLayoutHeaderLogoLines } from './BaseLayoutHeaderLogoLines';
import { TabletMenu } from '../TabletMenu';
import { MainProductnavigationMenu } from '../MainProductNavigationMenu';

const sxShownAfterLg = {
    display: {
        sm: 'none',
        md: 'block',
    },
};

export const BaseLayoutHeader = observer((): ReactElement => {
    const { i18n } = useTranslation();
    const classes = useBaseLayoutStyles();
    const theme = useTheme();
    const isTabletMenu = useMediaQuery(theme.breakpoints.down('md'));

    const root = useStore();
    const { navigation, globalSearchValue } = root;

    const { isLoading } = useQuery(
        ['target-groups', i18n.language],
        navigation.fetchNavigationMenu,
        {
            refetchOnWindowFocus: false,
        }
    );

    const onChange = (_: string, value: string): void => {
        root.setSearchValue(value);
    };

    return (
        <header className={classes.header}>
            <div className={classes.headerMain}>
                <Link to={AppRoutes.home} className={classes.logoWrapper}>
                    <img
                        src='/static/images/logo-without-lines.svg'
                        alt='Ekodise webshop'
                    />
                </Link>

                <div className={classes.headerElementsContainer}>
                    <div className={classes.elements}>
                        <div className={classes.searchWrapper}>
                            <BaseInputWrapper id='global-search-field'>
                                <SearchField
                                    id='global-search-field'
                                    name='global-search-field'
                                    value={globalSearchValue}
                                    onChange={onChange}
                                />
                            </BaseInputWrapper>
                        </div>
                        <Box className={classes.linksWrapper} sx={sxShownAfterLg}>
                            <StoreLinks />
                        </Box>
                        <div className={classes.shortcutNavWrapper}>
                            <ShortCutNav />
                        </div>
                        <Box className={classes.langDetectorWrapper} sx={sxShownAfterLg}>
                            <LanguageSelector />
                        </Box>
                        {isTabletMenu && <TabletMenu isTargetGroupsLoading={isLoading} />}
                    </div>

                    <div className={classes.headerLines}>
                        <div className={classes.headerLogoLinesWrapper}>
                            <BaseLayoutHeaderLogoLines />
                        </div>
                    </div>
                </div>
            </div>
            {!isTabletMenu && (
                <nav className={classes.navigation}>
                    {!isLoading && <MainProductnavigationMenu />}
                    {isLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                height: '100%',
                                width: '100%',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <LinearProgress color='secondary' />
                        </Box>
                    )}
                </nav>
            )}
        </header>
    );
});
