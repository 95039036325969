import React, { ReactElement } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Box, Grid, Button } from '@mui/material';

import { BaseInputWrapper, TextField } from 'src/components/fields';
import { useStore } from 'src/mst/StoreProvider';
import { AuthModuleSections } from 'src/authentication/constants';
import { Loader } from 'src/components/base';
import { ResponseError } from 'src/shared/types';

import { useRestorePasswordFormStyles } from './RestorePasswordFormStyles';
import { restorePasswordValidationSchema } from './RestorePasswordFormValidationSchema';

interface RestorePasswordFormType {
    email: string;
}

const langNs = ['authentication', 'fieldErrors', 'serverErrors'];

export const RestorePasswordForm = (): ReactElement => {
    const classes = useRestorePasswordFormStyles();
    const { t } = useTranslation(langNs);
    const { authentication } = useStore();

    const { mutateAsync, isLoading } = useMutation(authentication.restorePassword, {
        onSuccess: () => {
            toast.success(t('successRestoreMessage', { ns: 'authentication' }));
            authentication.setAuthModule(null);
        },
        onError: (response: ResponseError) => {
            const errorsArray = Object.values(
                response.error.response.data.errors
            )?.flat();
            if (Array.isArray(errorsArray) && errorsArray.length) {
                toast.error(t(errorsArray[0], { ns: 'serverErrors' }));
            } else {
                toast.error(t('unknownError', { ns: 'serverErrors' }));
            }
        },
    });

    const {
        values,
        errors,
        setFieldValue,
        setFieldTouched,
        touched,
        isValid,
        handleSubmit,
        dirty,
    } = useFormik<RestorePasswordFormType>({
        initialValues: { email: '' },
        validationSchema: restorePasswordValidationSchema,
        onSubmit: (values) => {
            mutateAsync(values.email);
        },
    });

    const { email } = values;

    const handleChange = (name: string, value: string): void => {
        setFieldTouched(name);
        setFieldValue(name, value);
    };

    const goToLogin = (): void => {
        authentication.setAuthModule(AuthModuleSections.signIn);
    };

    return (
        <form className={classes.container} onSubmit={handleSubmit}>
            <Box mb={3}>
                <BaseInputWrapper
                    id='email'
                    label='authentication:email'
                    languageNamespaces={langNs}
                    error={touched.email ? Boolean(errors.email) : false}
                    helperText={
                        touched.email && errors.email ? `fieldErrors:${errors.email}` : ''
                    }
                >
                    <TextField
                        id='email'
                        name='email'
                        translationNamespaces={langNs}
                        label='authentication:email'
                        value={email}
                        onChange={handleChange}
                        inputProps={{ type: 'email', autoComplete: 'email' }}
                    />
                </BaseInputWrapper>
            </Box>
            <Grid
                item
                container
                sm={12}
                className={classes.actionsContainer}
                justifyContent='space-between'
                alignItems='center'
            >
                <Button
                    disableRipple
                    color='secondary'
                    onClick={goToLogin}
                    className={classes.backToLogin}
                >
                    {t('authentication:backToSignIn')}
                </Button>
                <Button
                    disabled={!dirty || !isValid || isLoading}
                    type='submit'
                    color='secondary'
                    variant='contained'
                    className={classes.signUp}
                    startIcon={isLoading && <Loader />}
                >
                    {t('authentication:send')}
                </Button>
            </Grid>
        </form>
    );
};
