export enum AppRoutes {
    home = '',
    cookiePolicy = '/cookie-policy',
    personalInfo = '/personal-info',
    itemsHistory = '/items-history/:section',
    wishlist = '/wishlist',
    checkoutWithDetails = '/checkout-with-details',
    goodsListSubCategory = '/goods/:menuId/:categoryId/:subCategoryId',
    goodsListCategory = '/goods/:menuId/:categoryId',
    goodsListMenu = '/goods/:menuId',
    allGoods = '/goods',
    goodsItem = '/goods/item/:itemId',
    shops = '/shops',
    shop = '/shop/:name',
    booking = '/booking/:section',
    thanksForPurchase = '/thanks/:orderId',
    imprint = '/imprint',
    privacy = '/privacy',
}
