import { NavigateFunction, generatePath } from 'react-router-dom';
import { AppRoutes } from 'src/routing/appRoutes';

interface GenerateCurrentProfileMenuParams {
    isAuth: boolean;
    login: () => void;
    logout: () => void;
    signup: () => void;
    navigate: NavigateFunction;
}

export const generateCurrentProfileMenu = (
    params: GenerateCurrentProfileMenuParams
): { title: string; action: () => void }[] => {
    const { isAuth, login, signup, navigate, logout } = params;

    if (isAuth) {
        return [
            {
                title: 'common:profileMenu.myData',
                action: () => navigate(AppRoutes.personalInfo),
            },
            {
                title: 'common:profileMenu.reservedAndListOfPurchase',
                action: () =>
                    navigate(
                        generatePath(AppRoutes.itemsHistory, { section: 'reserved' })
                    ),
            },
            {
                title: 'common:profileMenu.logout',
                action: logout,
            },
        ];
    }
    return [
        { title: 'common:profileMenu.login', action: login },
        { title: 'common:profileMenu.signup', action: signup },
    ];
};
