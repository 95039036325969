import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { AppRoutes } from 'src/routing/appRoutes';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';

export const useGlobalSearch = (searchValue: string): void => {
    const navigate = useNavigate();
    const location = useLocation();
    const debouncedSearchValue = useDebounce(searchValue, 800);

    useEffect(() => {
        if (
            debouncedSearchValue &&
            !(
                location.pathname.startsWith(AppRoutes.allGoods) &&
                !location.pathname.startsWith('/goods/item/')
            )
        ) {
            navigate(AppRoutes.allGoods);
        }
    }, [debouncedSearchValue]);
};
