import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
    flow,
    getEnv,
} from 'mobx-state-tree';

import { NavMenuItem } from 'src/components/layouts/BaseLayout/components/MainProductNavigationMenu/NavMenuItem';
import { NavItemMst } from 'src/mst/customTypes';

import { mapTargetGroupLevel } from './mapTragetGroups';

export const Navigation = types
    .model('Navigation', {
        items: types.array(NavItemMst),
    })
    .actions((self) => {
        let initialState: INavigationSnapshotOut;

        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },

            resetStore() {
                applySnapshot(self, initialState);
            },
            setNavigationMenu(items: NavMenuItem[]) {
                applySnapshot(self, { ...self, items });
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchNavigationMenu: flow(function* () {
                try {
                    const data = yield httpClient.get(
                        `item-target-groups/item-categories`
                    );
                    if (Array.isArray(data)) {
                        const mappedData = mapTargetGroupLevel(data, 0);
                        self.setNavigationMenu(mappedData);
                    }
                } catch {
                    self.setNavigationMenu([]);
                }
            }),
        };
    })
    .views((self) => {
        return {
            getCategoriesByMenuId(id: string): NavMenuItem[] {
                return (
                    self.items
                        .find((item) => item.id === id)
                        ?.submenu?.filter((item) => item.id !== 'All')
                        .map((item) => ({
                            ...item,
                            submenu: item.submenu?.filter((item) => item.id !== 'All'),
                        })) || []
                );
            },
            getMenuTitleById(id: string): string {
                return self.items.find((item) => item.id === id)?.title || '';
            },
            getCategoryTitleById(id: string): string {
                return (
                    self.items
                        .map((item) => item.submenu)
                        .flat()
                        .find((item) => item?.id === id)?.title || ''
                );
            },
            getSubcategoryTitleById(id: string): string {
                return (
                    self.items
                        .map((item) => item.submenu)
                        .flat()
                        .map((item) => item?.submenu || [])
                        .flat()
                        .find((item) => item?.id === id)?.title || ''
                );
            },
        };
    });

export type NavigationModel = Instance<typeof Navigation>;
export type INavigationSnapshotOut = SnapshotOut<typeof Navigation>;
