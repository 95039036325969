import { types } from 'mobx-state-tree';

export const CsFile = types.custom({
    name: 'NavItem',
    fromSnapshot(value: csFile) {
        return value;
    },
    toSnapshot(value: csFile) {
        return value;
    },
    isTargetType(value: csFile): boolean {
        return Boolean(value?.id && value?.fileName);
    },
    getValidationMessage(value: csFile): string {
        return `${value} doesn't look like a valid structure`;
    },
});

type csFile = {
    id: string;
    fileName: string;
};
