import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { THEME_COLORS } from 'src/theming/theme';

export const useProductTabletNavigationMenuStyles = makeStyles((theme: Theme) => {
    return {
        menuList: {
            paddingTop: theme.spacing(2),
        },
        menuListItem: {
            height: 38,
            borderBottom: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
                fontSize: '1.4rem',
                color: theme.palette.primary.contrastText,
            },
        },
        menuItemIcon: {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        mainTitle: {
            position: 'relative',
            textAlign: 'center',
            margin: '0.5em 0',
            fontSize: '2rem',
        },
        subCategoryTitle: {
            paddingLeft: '1.6rem',
            margin: '0.5em 0',
            fontSize: '1.8rem',
        },
        mainTitleContainer: {
            display: 'flex',
        },
        backButton: {
            width: 25,
            height: 25,
            position: 'absolute',
            left: '1.6rem',
            top: 0,
            bottom: 0,
        },
    };
});
