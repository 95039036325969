import {
    types,
    Instance,
    SnapshotOut,
    getEnv,
    flow,
    applySnapshot,
    getSnapshot,
} from 'mobx-state-tree';

import { ImagePreviewMst, SizeItemMst } from 'src/mst/customTypes';

export const ProductDetails = types
    .model('ProductDetailsModel', {
        id: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        brand: types.maybeNull(types.string),
        material: types.maybeNull(types.string),
        color: types.maybeNull(types.string),
        price: types.maybeNull(types.number),
        minimumStock: types.maybeNull(types.number),
        state: types.maybeNull(types.string),
        sizes: types.maybeNull(types.array(SizeItemMst)),
        shippingAvailable: types.maybeNull(types.boolean),
        webShopId: types.maybeNull(types.string),
        webShopName: types.maybeNull(types.string),
        shippingCosts: types.maybeNull(types.number),
        freeOfShippingCosts: types.maybeNull(types.number),
        previewImage: types.maybeNull(ImagePreviewMst),
        images: types.maybeNull(types.array(ImagePreviewMst)),
        userStock: types.maybeNull(types.number),
        imagesSrc: types.maybeNull(types.array(ImagePreviewMst)),
        targetGroup: types.maybeNull(types.string),
        targetGroupId: types.maybeNull(types.string),
        category: types.maybeNull(types.string),
        categoryId: types.maybeNull(types.string),
        subCategory: types.maybeNull(types.string),
        subCategoryId: types.maybeNull(types.string),
        additionalInformation: types.maybeNull(types.string),
        tagIds: types.maybeNull(types.array(types.string)),
        reserved: types.maybeNull(types.boolean),
        itemNumber: types.maybeNull(types.string),
    })
    .actions((self) => {
        let initialState: IProductDetailsSnapshotOut;
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },

            resetStore(): void {
                applySnapshot(self, initialState);
            },
            changeAmount(value: number): void {
                if (value <= self.minimumStock) {
                    self.userStock = value;
                }
            },
            setImagesSrc: flow(function* () {
                const images = [self.previewImage, ...self.images].filter((item) =>
                    Boolean(item)
                );
                if (images.length > 0) {
                    const imagesRequests = images.map(async (item) => {
                        const data = await httpClient.get(
                            `web-shop/items/${self.id}/attachments/${item.id}`,
                            {
                                axiosConfig: {
                                    headers: {
                                        'Content-Type': 'image/*',
                                    },
                                    responseType: 'blob',
                                },
                            }
                        );
                        return Promise.resolve({
                            id: item.id,
                            alt: item.fileName,
                            data,
                        });
                    });

                    const imagesData = yield Promise.allSettled(imagesRequests);
                    const imagesSrc = imagesData.map(
                        ({
                            value,
                        }: {
                            status: string;
                            value: { id: string; alt: string; data: Blob };
                        }) => {
                            const src = value.data && URL.createObjectURL(value.data);
                            return { id: value.id, fileName: value.alt, src };
                        }
                    );
                    applySnapshot(self, { ...self, imagesSrc });
                }
            }),
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchProductDetails: flow(function* (params) {
                try {
                    const [id] = params.queryKey;
                    if (id) {
                        const data = yield httpClient.get(`web-shop/items/${id}/details`);

                        applySnapshot(self, {
                            ...data,
                            userStock: data.minimumStock > 0 ? 1 : 0,
                        });
                        self.setImagesSrc();
                    }
                } catch (err) {
                    self.resetStore();
                }
            }),
        };
    })
    .views((self) => {
        return {
            get sizeStrings(): { intl: string; de: string; uk: string; us: string } {
                const sizes: {
                    intl: string[];
                    de: string[];
                    uk: string[];
                    us: string[];
                } = {
                    intl: [],
                    de: [],
                    uk: [],
                    us: [],
                };
                self.sizes?.forEach((item) => {
                    if (item.aliasItl) {
                        sizes.intl.push(item.aliasItl);
                    }
                    if (item.size) {
                        sizes.de.push(item.size);
                    }
                    if (item.aliasUK) {
                        sizes.uk.push(item.aliasUK);
                    }
                    if (item.aliasUS) {
                        sizes.us.push(item.aliasUS);
                    }
                });
                return {
                    intl: sizes.intl.join(', '),
                    de: sizes.de.join(', '),
                    uk: sizes.uk.join(', '),
                    us: sizes.us.join(', '),
                };
            },
        };
    });

export type ProductDetailsModel = Instance<typeof ProductDetails>;
export type IProductDetailsSnapshotOut = SnapshotOut<typeof ProductDetails>;
