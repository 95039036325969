import { types, getEnv, flow, applySnapshot } from 'mobx-state-tree';

import { WebShopItem } from './webShopItem';

export const WebShops = types
    .model('WebShops', {
        list: types.array(WebShopItem),
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchWebShops: flow(function* () {
                try {
                    const data = yield httpClient.get('web-shop/web-shops');
                    if (Array.isArray(data?.webShops)) {
                        applySnapshot(self, { list: [...data.webShops] });
                    } else {
                        applySnapshot(self, { list: [] });
                    }
                } catch {
                    applySnapshot(self, { list: [] });
                }
            }),
        };
    });
