import React, { ReactElement, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

import { useStore } from 'src/mst/StoreProvider';
import { SortOptions } from 'src/shared/constants';

import {
    useSortingBlockStyles,
    useSortingBlockToggleGroupStyles,
    useSortingBlockToggleButtonStyles,
} from './SortingBlockStyles';

export const SortingBlock = observer((): ReactElement => {
    const classes = useSortingBlockStyles();
    const toggleGroupClasses = useSortingBlockToggleGroupStyles();
    const toggleButtonClasses = useSortingBlockToggleButtonStyles();

    const { t } = useTranslation(['common']);

    const { productsList } = useStore();

    const handleChange = (
        _: MouseEvent<HTMLElement>,
        value: SortOptions | null
    ): void => {
        productsList.setSorting(value);
    };

    return (
        <div className={classes.container}>
            <Typography variant='subtitle1' className={classes.subtitle}>
                {t('common:sortBy')}
            </Typography>
            <ToggleButtonGroup
                exclusive
                value={productsList.sorting}
                classes={toggleGroupClasses}
                onChange={handleChange}
            >
                <ToggleButton
                    disableRipple
                    value={SortOptions.asc}
                    classes={toggleButtonClasses}
                >
                    {t('common:ascendingPrice')}
                </ToggleButton>
                <ToggleButton
                    disableRipple
                    value={SortOptions.desc}
                    classes={toggleButtonClasses}
                >
                    {t('common:descendingPrice')}
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
});
