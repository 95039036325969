import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { Spacings } from 'src/shared/constants';
import { THEME_COLORS } from 'src/theming/theme';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useItemListLayoutStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        sidebarAndMainContentContainer: {
            display: 'flex',
            maxWidth: '100vw',
            flexGrow: 1,
            padding: `0 ${Spacings.xxl}px`,
            [theme.breakpoints.down('xl')]: {
                padding: `0 ${Spacings.xl}px`,
            },
            [theme.breakpoints.down('lg')]: {
                padding: `0 ${Spacings.sm}px`,
            },
            [getMediaSelectorKey('max-width: 860px')]: {
                display: 'flex',
                justifyContent: 'center',
            },
            [theme.breakpoints.down('ml')]: {
                padding: 0,
            },
        },
        sidebar: {
            width: '14%',
            [theme.breakpoints.down('lg')]: {
                minWidth: 157,
            },
            [getMediaSelectorKey('max-width: 860px')]: {
                display: 'none',
            },
        },
        mainContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '86%',
            padding: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(4),
            },
            [getMediaSelectorKey('max-width: 800px')]: {},
        },
        listContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
        },
        filtersWrapper: {
            paddingTop: theme.spacing(3),
        },
        footer: {
            minHeight: 72,
            borderTop: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            [theme.breakpoints.down('lg')]: {
                minHeight: 50,
            },
        },
    };
});
