import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
    path: string;
    isPrivate: boolean;
    authorized: boolean | null;
    userRoles: string[];
    roles?: string[] | null;
}

const ProtectedRoute = ({
    authorized,
    isPrivate,
}: ProtectedRouteProps): ReactElement | null => {
    if (!isPrivate) {
        return <Outlet />;
    }

    if (!authorized) {
        return <Navigate to='/' />;
    }

    return <Outlet />;
};
export default ProtectedRoute;
