import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    ButtonGroup,
    FormControl,
    IconButton,
    MenuItem,
    Select,
} from '@mui/material';
import { useClasses, useSelectClasses } from './CustomPaginationStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { SelectableItem } from 'src/shared/types';
import clsx from 'clsx';

type CustomPaginationProps = {
    previousPage: () => void;
    gotoPage: (value: number) => void;
    setRowsPerPage: (value: number) => void;
    nextPage: () => void;
    pageSize: number;
    page: number;
    count: number;
    rowsPerPageOptions?: SelectableItem[];
};

export const CustomPagination = ({
    page,
    previousPage,
    gotoPage,
    setRowsPerPage,
    nextPage,
    pageSize,
    count,
    rowsPerPageOptions,
}: CustomPaginationProps): JSX.Element => {
    const { t } = useTranslation('common');
    const selectClasses = useSelectClasses();
    const classes = useClasses();

    const pageOptions = useMemo(() => {
        if (count <= pageSize) {
            return [{ title: '1', value: 0 }];
        }
        const amountOfPages = Math.ceil(count / pageSize);

        const pageOptions = new Array(amountOfPages).fill(null).map((item, index) => {
            return {
                title: String(index + 1),
                value: index,
            };
        });

        return pageOptions;
    }, [pageSize, count]);

    return (
        <Box className={clsx(classes.main, classes.paginationWrapper)}>
            <div className={classes.choosingWrapper}>
                <FormControl variant='standard'>
                    <Select
                        disableUnderline
                        IconComponent={KeyboardArrowDownIcon}
                        className={selectClasses.select}
                        value={page}
                        onChange={(e) => {
                            const newPage = e.target.value ? e.target.value : 0;
                            gotoPage(Number(newPage));
                        }}
                    >
                        {pageOptions.map((el) => (
                            <MenuItem key={el.value} value={el.value}>
                                {el.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <span className={classes.text}>
                    {page + 1} {t('of')} {pageOptions.length}
                </span>
            </div>
            <div className={classes.portionWrapper}>
                <ButtonGroup
                    className={classes.button}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                >
                    <IconButton onClick={() => previousPage()} disabled={page <= 0}>
                        <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => nextPage()}
                        disabled={!(page < pageOptions.length - 1)}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </ButtonGroup>
                <span className={classes.text}>{t('rowsPerPage')}:</span>
                <FormControl variant='standard'>
                    <Select
                        disableUnderline
                        IconComponent={ArrowDropDownIcon}
                        className={selectClasses.outlined}
                        defaultValue={pageSize}
                        onChange={(e) => {
                            setRowsPerPage(Number(e.target.value));
                        }}
                    >
                        {rowsPerPageOptions?.map((rowPerPageOption) => {
                            return (
                                <MenuItem
                                    key={rowPerPageOption.value}
                                    value={rowPerPageOption.value}
                                >
                                    {rowPerPageOption.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </Box>
    );
};

CustomPagination.defaultProps = {
    rowsPerPageOptions: [
        { title: '10', value: 10 },
        { title: '25', value: 25 },
        { title: '50', value: 50 },
    ],
    pageOptions: [],
};
