import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useSignInFormStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            padding: theme.spacing(5),
        },
        forgotPassword: {
            height: 'fit-content',
            padding: 0,
            fontSize: '1.4rem',
            fontWeight: theme.typography.fontWeightRegular,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                textDecoration: 'underline',
            },
        },
        registerBtn: {
            height: 'fit-content',
            padding: 0,
            fontSize: '1.4rem',
            fontWeight: theme.typography.fontWeightRegular,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                textDecoration: 'underline',
            },
        },
    };
});
