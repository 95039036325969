import React, { ReactElement, memo } from 'react';

import { SelectField, TextField, PhoneField } from 'src/components/fields';

import { SignUpFormConfigItem } from './signUpFormConfig';
import { SignUpFormType } from './SignUpFormType';

export interface SignUpFormItemProps {
    value: string | string[];
    onChange: (name: keyof SignUpFormType, value: string | number | string[]) => void;
    langNs: string[];
}

export const SignUpFormItem = memo(
    (props: SignUpFormConfigItem & SignUpFormItemProps): ReactElement | null => {
        const { id, type, label, name, value, onChange, items, inputProps, langNs } =
            props;

        if (type === 'text') {
            return (
                <TextField
                    id={id}
                    value={value as string}
                    onChange={onChange as (name: string, value: string) => void}
                    name={name}
                    inputProps={inputProps}
                    translationNamespaces={langNs}
                />
            );
        }
        if (type === 'select') {
            return (
                <SelectField
                    id={id}
                    languageNamespaces={langNs}
                    tLabelKey={label}
                    name={name}
                    value={value as string[]}
                    handleChange={onChange as (name: string, value: string[]) => void}
                    items={items}
                />
            );
        }

        if (type === 'phone') {
            return (
                <PhoneField
                    id={id}
                    value={value as string}
                    name={name}
                    onChange={onChange as (name: string, value: string) => void}
                />
            );
        }
        return null;
    }
);
