import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { Image } from '../types';

export const useRemoteImage = (path: string): [string, boolean] => {
    const httpClient = useHttpClient();

    const { isLoading, data } = useQuery<Image>(
        [path],
        async () => {
            const response = await httpClient.get<Blob>(path, {
                axiosConfig: {
                    headers: {
                        'Content-Type': 'image/*',
                    },
                    responseType: 'blob',
                },
            });

            return { file: response };
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 300000,
            enabled: Boolean(path),
        }
    );

    const imageSrc = useMemo(() => {
        return data?.file ? URL.createObjectURL(data.file) : null;
    }, [data]);

    return [imageSrc, isLoading];
};
