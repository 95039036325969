import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { Spacings } from 'src/shared/constants';

export const useShopDetailsLayoutStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            // padding: `${theme.spacing(6)} ${Spacings.xxl}px`,
            gap: theme.spacing(5),
            '& p': {
                whiteSpace: 'pre-wrap',
            },
        },
        fullWidth: {
            width: '100%',
        },
        breadcrumps: {
            padding: `${theme.spacing(6)} ${Spacings.xxl}px`,
            [theme.breakpoints.down('xl')]: {
                gap: theme.spacing(4),
                padding: `${theme.spacing(4)} ${Spacings.xl}px`,
            },
            [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(3),
                padding: `${theme.spacing(3)} ${Spacings.sm}px`,
            },
        },
        banner: {
            maxHeight: 315,
        },
        info: {
            padding: `0 ${Spacings.xxl}px`,
            paddingLeft: 550,
            marginTop: -20,
            [theme.breakpoints.down('xl')]: {
                gap: theme.spacing(4),
                padding: `${theme.spacing(4)} ${Spacings.xl}px`,
                paddingLeft: 400,
                marginTop: -40,
            },
            [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(3),
                padding: `${theme.spacing(3)} ${Spacings.sm}px`,
                paddingLeft: 250,
                marginTop: -20,
            },
            [theme.breakpoints.down('md')]: {
                marginTop: 50,
                padding: Spacings.sm,
            },
        },
        infoAndLinksContainer: {
            display: 'flex',
            gap: theme.spacing(8),
            padding: `${theme.spacing(6)} ${Spacings.xxl}px`,
            [theme.breakpoints.down('xl')]: {
                gap: theme.spacing(4),
                padding: `${theme.spacing(4)} ${Spacings.xl}px`,
            },
            [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(3),
                padding: `${theme.spacing(3)} ${Spacings.sm}px`,
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        mainInfo: {
            flexGrow: 1,
        },
        map: {
            width: '100%',
            height: 240,
            backgroundColor: theme.palette.primary.dark,
            borderRadius: 10,
        },
        additionalInfo: {
            padding: `${theme.spacing(6)} ${Spacings.xxl}px`,
            [theme.breakpoints.down('xl')]: {
                gap: theme.spacing(4),
                padding: `${theme.spacing(4)} ${Spacings.xl}px`,
            },
            [theme.breakpoints.down('lg')]: {
                gap: theme.spacing(3),
                padding: `${theme.spacing(3)} ${Spacings.sm}px`,
            },
        },
        mayLikeProducts: {},
        socials: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(2),
        },
        browseShopContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    };
});
