import * as yup from 'yup';

export const createPasswordValidationSchema = yup.object().shape({
    type: yup.string().required(),
    oldPassword: yup.string().when('type', {
        is: (val: string) => val === 'change',
        then: yup.string().required('required').nullable(),
        otherwise: yup.string(),
    }),
    password: yup.string().required('required'),
    repeatPassword: yup
        .string()
        .oneOf([yup.ref('password'), 'password'], 'passwordsAreNotMatched')
        .required('required'),
});
