import React, { ReactElement } from 'react';

import { useProductDetailedViewLayoutStyles } from './ProductDetailedViewLayoutStyles';
import clsx from 'clsx';

interface ProductDetailedViewLayoutProps {
    children: ReactElement[];
}

export const ProductDetailedViewLayout = ({
    children,
}: ProductDetailedViewLayoutProps): ReactElement => {
    const classes = useProductDetailedViewLayoutStyles();

    return (
        <div className={classes.container}>
            {children[0] && <div className={classes.breadcrumps}>{children[0]}</div>}
            {children[1] && (
                <div className={clsx(classes.carousel, classes.hidden)}>
                    {children[1]}
                </div>
            )}
            {children[2] && (
                <div className={clsx(classes.info, classes.hidden)}>{children[2]}</div>
            )}
            {children[1] && children[2] && (
                <div className={classes.adaptiveLayoutContainer}>
                    {children[1] && <div className={classes.carousel}>{children[1]}</div>}
                    {children[2] && <div className={classes.info}>{children[2]}</div>}
                </div>
            )}
            {children[3] && (
                <div className={classes.mayLikeProductsList}>{children[3]}</div>
            )}
        </div>
    );
};
