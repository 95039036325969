import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
} from 'mobx-state-tree';

import { ImagePreviewMst } from 'src/mst/customTypes';

export const Product = types
    .model('ProductModel', {
        id: types.identifier,
        name: types.maybeNull(types.string),
        isNewGood: types.maybeNull(types.boolean),
        reserved: types.maybeNull(types.boolean),
        price: types.maybeNull(types.number),
        sizes: types.maybeNull(types.array(types.string)),
        previewImage: types.maybeNull(ImagePreviewMst),
        subCategory: types.maybeNull(types.string),
        shippingAvailable: types.maybeNull(types.boolean),
        shopId: types.maybeNull(types.string),
    })
    .actions((self) => {
        let initialState: IProductSnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore(): void {
                applySnapshot(self, initialState);
            },
        };
    });

export type ProductModel = Instance<typeof Product>;
export type IProductSnapshotOut = SnapshotOut<typeof Product>;
