import { SignUpFormType } from 'src/components/forms/SignUpForm/SignUpFormType';
import { SignUpDto } from 'src/shared/types';

export const signUpDtoFormatter = (values: SignUpFormType): SignUpDto => {
    return {
        email: values.email,
        password: values.password,
        name: values.surname,
        firstname: values.name,
        street: values.street,
        houseNumber: values.number,
        country: values.country as string,
        zipCode: values.postcode,
        city: values.place,
        salutation: values.salutation,
        phoneNumber: values.phoneNumber,
    };
};
