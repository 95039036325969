import { ComponentType } from 'react';

import { SelectionValues, ItemCategories } from 'src/shared/constants';

import {
    PriceFilter,
    MultipleSelectFilterProps,
    AutocompleteFilter,
    SizeFilter,
} from './components';

export interface GoodsFilterPanelItem {
    id: string;
    label: string;
    name: string;
    FilterItem: ComponentType<MultipleSelectFilterProps>;
    placeholder?: string;
    popperMinWidth?: number;
}

interface generateGoodsFilterPanelConfigParams {
    categoryId?: string;
}

export const generateGoodsFilterPanelConfig = ({
    categoryId,
}: generateGoodsFilterPanelConfigParams): GoodsFilterPanelItem[] => [
    {
        id: 'Color filter',
        label: 'product-filters:color',
        FilterItem: AutocompleteFilter,
        name: SelectionValues.color,
    },
    ...(categoryId !== ItemCategories.shoes
        ? [
              {
                  id: 'Size filter',
                  label: 'product-filters:size',
                  FilterItem: SizeFilter,
                  name: SelectionValues.size,
              },
          ]
        : []),
    ...(categoryId === ItemCategories.shoes || !categoryId
        ? [
              {
                  id: 'Sizw shoes filter',
                  label: 'product-filters:sizeShoes',
                  FilterItem: SizeFilter,
                  name: SelectionValues.sizeShoes,
              },
          ]
        : []),
    {
        id: 'Brand filter',
        label: 'product-filters:brand',
        FilterItem: AutocompleteFilter,
        name: SelectionValues.brand,
        placeholder: 'product-filters:brandPlaceholder',
    },
    {
        id: 'Price filter',
        label: 'product-filters:price',
        FilterItem: PriceFilter,
        name: 'price',
    },
    {
        id: 'Condition filter',
        label: 'product-filters:condition',
        FilterItem: AutocompleteFilter,
        name: SelectionValues.condition,
        popperMinWidth: 300,
    },
    // {
    //     id: 'Shop filter',
    //     label: 'product-filters:shop',
    //     FilterItem: AutocompleteFilter,
    //     name: SelectionValues.shop,
    // },
    {
        id: 'Fit filter',
        label: 'product-filters:fit',
        FilterItem: AutocompleteFilter,
        name: SelectionValues.fit,
    },
    // This filter will be added in the back end from the app version 2
    // {
    //   id: 'Sale filter',
    //   label: 'product-filters:sale',
    //   FilterItem: ToggableFilter,
    //   name: 'sale',
    // },
];
