import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { DateTimeService } from 'src/lib/dateTimeService/dateTimeService';

const dateTimeService = new DateTimeService();

interface useDateStringParams {
    date: string;
}

export const useDateString = ({ date }: useDateStringParams): string => {
    const { i18n } = useTranslation();
    return useMemo(() => {
        if (date) {
            return dateTimeService.getDateStringWithDayOfWeek(date, i18n.language);
        }
        return date;
    }, [i18n.language, date]);
};
