import React, { Suspense, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useLocation, Location } from 'react-router-dom';

import { SuspenseProgress } from 'src/components/base';
import { useStore } from 'src/mst/StoreProvider';

import routingConfig from './routingConfig';
import ProtectedRoute from './ProtectedRoute';

export const RoutedContent = observer((): JSX.Element => {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState<Location>(null);

    useEffect(() => {
        return () => {
            setPrevLocation(location);
        };
    }, [location]);

    const {
        authentication: { isAuth, isTokensLoading },
    } = useStore();
    const userRoles: string[] = [];

    if (isTokensLoading) {
        return <SuspenseProgress />;
    }

    return (
        <Suspense fallback={<SuspenseProgress />}>
            <Routes>
                {routingConfig.map((route) => {
                    const {
                        path,
                        children,
                        roles,
                        private: isPrivate,
                        component: Component,
                    } = route;
                    return (
                        <Route
                            key={path}
                            element={
                                <ProtectedRoute
                                    isPrivate={Boolean(isPrivate)}
                                    path={path}
                                    authorized={isAuth}
                                    userRoles={[...userRoles]}
                                    roles={roles}
                                />
                            }
                            {...route}
                        >
                            <Route path={path} element={<Component />}>
                                {Array.isArray(children) &&
                                    children.map((childRoute) => {
                                        const ChildComponent = childRoute.component;
                                        return (
                                            <Route
                                                key={childRoute.path}
                                                element={
                                                    <ProtectedRoute
                                                        isPrivate={Boolean(
                                                            childRoute.private
                                                        )}
                                                        path={childRoute.path}
                                                        authorized={isAuth}
                                                        userRoles={[...userRoles]}
                                                        roles={childRoute.roles}
                                                    />
                                                }
                                                {...childRoute}
                                            >
                                                <Route
                                                    path={path}
                                                    element={
                                                        <ChildComponent
                                                            prevLocation={prevLocation}
                                                        />
                                                    }
                                                >
                                                    {Array.isArray(childRoute.children) &&
                                                        childRoute.children.map(
                                                            (threeLevelchildRoute) => {
                                                                const ThreeLevelChildComponent =
                                                                    threeLevelchildRoute.component;
                                                                return (
                                                                    <Route
                                                                        key={
                                                                            threeLevelchildRoute.path
                                                                        }
                                                                        element={
                                                                            <ProtectedRoute
                                                                                isPrivate={Boolean(
                                                                                    threeLevelchildRoute.private
                                                                                )}
                                                                                path={
                                                                                    threeLevelchildRoute.path
                                                                                }
                                                                                authorized={
                                                                                    isAuth
                                                                                }
                                                                                userRoles={[
                                                                                    ...userRoles,
                                                                                ]}
                                                                                roles={
                                                                                    threeLevelchildRoute.roles
                                                                                }
                                                                            />
                                                                        }
                                                                        {...threeLevelchildRoute}
                                                                    >
                                                                        <Route
                                                                            path={path}
                                                                            element={
                                                                                <ThreeLevelChildComponent />
                                                                            }
                                                                        />
                                                                    </Route>
                                                                );
                                                            }
                                                        )}
                                                </Route>
                                            </Route>
                                        );
                                    })}
                            </Route>
                        </Route>
                    );
                })}
            </Routes>
        </Suspense>
    );
});
