import { CircularProgress } from '@mui/material';
import React, { ReactElement } from 'react';

export const Loader = ({ ...props }): ReactElement => {
    return <CircularProgress {...props} />;
};

Loader.defaultProps = {
    color: 'secondary',
    size: '2rem',
};
