import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { NestedMenu } from '../NestedMenu';
import { Languages } from 'src/shared/constants';

import { useLanguageDetectorStyle } from './LanguageSelectorStyles';

const languageNS = ['common'];

export const LanguageSelector = (): ReactElement => {
    const classes = useLanguageDetectorStyle();
    const { i18n } = useTranslation(['common']);

    const handleChangeLanguage = (lang: string): void => {
        i18n.changeLanguage(lang);
    };

    const menuItems = [
        {
            title: 'common:fullLanguageNames.en',
            action: () => handleChangeLanguage(Languages.EN),
        },
        {
            title: 'common:fullLanguageNames.de',
            action: () => handleChangeLanguage(Languages.DE),
        },
    ];

    return (
        <NestedMenu
            buttonClass={classes.langBtn}
            menuItems={menuItems}
            title={`common:languages.${i18n.language}`}
            namespaces={languageNS}
        />
    );
};

LanguageSelector.defaultProps = {
    color: 'primary',
};
