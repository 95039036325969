import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';

export const useAutocompleteFilterStyles = makeStyles((theme: Theme) => {
    return {
        filterContainer: {
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: 2,
                borderRadius: 10,
                borderColor: THEME_COLORS.INFO_WARNING_DARK_COLOR,
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.main,
            },
            '& .MuiInputLabel-root': {
                paddingTop: 0,
                transform: 'translate(14px, 10px) scale(1)',
                '&.MuiInputLabel-shrink': {
                    transform: 'translate(14px, -6px) scale(0.75)',
                },
            },
        },
    };
});
