import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';

const targetGroupColors: THEME_COLORS[] = [
    THEME_COLORS.INFO_DARK_COLOR,
    THEME_COLORS.INFO_MAIN_COLOR,
    THEME_COLORS.INFO_LIGHT_COLOR,
];

interface useMainProductNavigationMenuStylesProps {
    targetMenuIndex?: number;
}

export const useMainProductNavigationMenuStyles = makeStyles((theme: Theme) => {
    return {
        navigationContainer: {
            height: 67,
            width: 'fit-content',
            padding: 0,
            marginBottom: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        mainItemContainer: {
            height: '100%',
            borderBottom: `5px solid`,
            margin: '0 1rem',
        },
        menuItems: {
            height: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            padding: `0 23px`,
            fontFamily: 'Saveur',
            fontSize: '1.6rem',
        },
        underlinedItems: {
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: 8,
                zIndex: 1,
                backgroundColor: ({
                    targetMenuIndex,
                }: useMainProductNavigationMenuStylesProps) => {
                    return targetGroupColors[targetMenuIndex % 3];
                },
            },
        },
        dropdown: {
            display: 'flex',
            flexWrap: 'nowrap',
            position: 'absolute',
            minHeight: 200,
            top: 67,
            left: '50%',
            backgroundColor: theme.palette.primary.main,
            border: `2px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            borderRadius: 10,
            zIndex: -10000,
            transition: '0.3s',
            transform: 'scale3d(0, 0, 0)',
            '&>.MuiList-root': {
                minWidth: 194,
            },
        },
        dropdownItem: {
            height: 59,
            paddingLeft: theme.spacing(5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontSize: '1.6rem',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.contrastText,
            borderBottom: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
        },
        borderBottomNone: {
            '&:last-child': {
                borderBottom: 0,
            },
        },
        detailedCategories: {
            transition: '0.3s',
            borderLeft: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
        },
        selectedCategory: {
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: 8,
                zIndex: 10,
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        activeTextColor: {
            color: theme.palette.secondary.main,
        },
        scaleShow: {
            zIndex: 10000,
            transform: 'scale3d(1, 1, 1)',
        },
        scaleHide: {
            zIndex: -10000,
        },
        show: {
            display: 'block',
        },
        hide: {
            display: 'none',
        },
        detailedCategoriesList: {
            display: 'grid',
            gridTemplateRows: 'repeat(auto-fill, 40px)',
            gridAutoFlow: 'column',
            maxHeight: 'inherit',
            padding: `8px 0 0`,
            fontWeight: theme.typography.fontWeightRegular,
        },
        detailedCategoryListItem: {
            '& a': {
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                fontSize: '1.6rem',
                color: theme.palette.primary.contrastText,
                padding: '6px 26px',
            },
            '&.MuiMenuItem-root': {
                padding: 0,
            },
        },
        targetGroupContainer: {
            height: '100%',
            margin: `0 ${theme.spacing(2)}`,
        },
        shopFilterContainer: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            gap: 12,
            marginLeft: 42,
        },
        shopFilterChipsContainer: {
            display: 'flex',
            gap: 4,
        },
    };
});
