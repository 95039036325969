import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { Button } from '@mui/material';

import { useStore } from 'src/mst/StoreProvider';
import { SelectionValues } from 'src/shared/constants';

import { useGoodsListFilterPanelStyles } from './GoodsListFilterPanelStyles';
import { generateGoodsFilterPanelConfig } from './GoodsFilterPanelConfig';
import { FilterChip, SortingBlock } from './components';

const defaultVisibleCountOfFilters = 6;

const lgNamespaces = ['product-filters'];

export const GoodsListFilterPanel = observer((): ReactElement => {
    const { categoryId } = useParams();
    const { t } = useTranslation(lgNamespaces);
    const { productsList, selectionValues } = useStore();
    const classes = useGoodsListFilterPanelStyles();
    const [visibleCount, setVisibleCount] = useState<number>(
        defaultVisibleCountOfFilters
    );

    const goodsFilterPanelConfig = generateGoodsFilterPanelConfig({ categoryId });

    const toggleShownMore = (): void => {
        if (visibleCount > defaultVisibleCountOfFilters) {
            setVisibleCount(defaultVisibleCountOfFilters);
        } else {
            setVisibleCount(goodsFilterPanelConfig.length);
        }
    };

    useEffect(() => {
        return () => {
            selectionValues.setSelectionValues(SelectionValues.size, null);
            productsList.resetStore();
        };
    }, []);

    return (
        <div>
            <div className={classes.filterContainer}>
                {goodsFilterPanelConfig.map(
                    (
                        { FilterItem, id, label, name, placeholder, popperMinWidth },
                        index
                    ) => {
                        return (
                            <div
                                key={id}
                                className={clsx(classes.filterItemContainer, {
                                    [classes.hidFilterItemContainer]:
                                        index >= visibleCount,
                                })}
                            >
                                <FilterItem
                                    label={label}
                                    id={id}
                                    name={name}
                                    languageNamespaces={lgNamespaces}
                                    placeholder={placeholder}
                                    popperMinWidth={popperMinWidth}
                                />
                            </div>
                        );
                    }
                )}
                {defaultVisibleCountOfFilters !== goodsFilterPanelConfig.length && (
                    <Button
                        onClick={toggleShownMore}
                        className={classes.moreFiltersButton}
                        color='secondary'
                        variant='contained'
                    >
                        {visibleCount < goodsFilterPanelConfig.length
                            ? t('moreFilters')
                            : t('lessFilters')}
                    </Button>
                )}
            </div>
            {productsList.filters.filter((item) => item.value).length > 0 && (
                <div className={classes.filterChipsContainer}>
                    {productsList.filters
                        .filter(
                            (item) => item.value && item.filter !== SelectionValues.shop
                        )
                        .map((item) => {
                            return (
                                <FilterChip
                                    key={`${item.filter}${item.value}`}
                                    {...item}
                                />
                            );
                        })}
                </div>
            )}
            <SortingBlock />
        </div>
    );
});
