import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { ResponseError } from 'src/shared/types';

import { handleAddToReservationListErrors } from './handleAddToReservationListErrors';

interface useAddToReservationListProps {
    id: string;
    onAddToReservationListSuccess?: () => void;
    onAddToReservationListError?: () => void;
}

export const useAddToReservationList = ({
    id,
    onAddToReservationListSuccess,
    onAddToReservationListError,
}: useAddToReservationListProps): UseMutationResult => {
    const { t } = useTranslation(['booking', 'serverErrors']);
    const httpClient = useHttpClient();
    return useMutation(
        async () => {
            if (id) {
                return httpClient.post(`web-shop/items/${id}/reservations`);
            }
            return null;
        },
        {
            onSuccess: () => {
                toast.success(
                    t('booking:reservation.addToReservationListSuccessMessage')
                );
                if (onAddToReservationListSuccess instanceof Function) {
                    onAddToReservationListSuccess();
                }
            },
            onError: (err: ResponseError) => {
                const errors = handleAddToReservationListErrors(err);
                toast.error(t(`${errors[0]}`, { ns: 'serverErrors' }));
                if (onAddToReservationListError instanceof Function) {
                    onAddToReservationListError();
                }
            },
        }
    );
};
