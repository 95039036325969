import { generatePath } from 'react-router-dom';
import { AppRoutes } from 'src/routing/appRoutes';
import { bookingNavigationItems } from 'src/shared/constants';

export const getBookingStartingPage = ({
    isAuth,
    amountOfPurchases,
    amountOfReservations,
}: {
    isAuth: boolean;
    amountOfPurchases: number;
    amountOfReservations: number;
}): string => {
    let startSection: bookingNavigationItems;

    if (amountOfReservations && !amountOfPurchases) {
        startSection = bookingNavigationItems.reservation;
    } else if ((!amountOfReservations && amountOfPurchases) || !isAuth) {
        startSection = bookingNavigationItems.purchaseList;
    } else {
        startSection = bookingNavigationItems.reservation;
    }
    return generatePath(AppRoutes.booking, {
        section: startSection,
    });
};
