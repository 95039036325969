import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams, generatePath } from 'react-router-dom';

import { Typography } from '@mui/material';
import { useTreeItem, TreeItemContentProps } from '@mui/lab';

import { AppRoutes } from 'src/routing/appRoutes';

declare module '@mui/lab/TreeItem' {
    interface TreeItemContentProps {
        depthLevel: number;
        parentId?: string;
    }
}

export const GoodListNavigationItem = forwardRef(function CustomContent(
    props: TreeItemContentProps,
    ref
) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        depthLevel,
        parentId,
    } = props;

    const navigate = useNavigate();
    const { menuId } = useParams();

    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        preventSelection(event);
    };

    const handleExpansionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        handleExpansion(event);
    };

    const handleSelectionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        handleSelection(event);
        if (depthLevel === 0) {
            navigate(
                generatePath(AppRoutes.goodsListCategory, { menuId, categoryId: nodeId })
            );
            return;
        }
        if (depthLevel === 1) {
            navigate(
                generatePath(AppRoutes.goodsListSubCategory, {
                    menuId,
                    categoryId: parentId,
                    subCategoryId: nodeId,
                })
            );
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref as React.Ref<HTMLDivElement>}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <Typography
                onClick={handleSelectionClick}
                component='div'
                className={classes.label}
            >
                {label}
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
        </div>
    );
});
