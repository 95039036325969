import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const usePhoneFieldStyles = makeStyles((theme: Theme) => {
    return {
        phoneNumber: {
            padding: 0,
            '& input': {
                height: '40px!important',
                width: '100%!important',
                border: 'none!important',
            },
            '& .special-label': {
                display: 'none',
            },
            '& .flag-dropdown ': {
                top: 5,
                left: 2,
                height: 30,
                zIndex: 1,
                border: 'none',
                borderRadius: 10,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    borderRadius: 10,
                },
            },
            '& .flag-dropdown.open': {
                borderRadius: 10,
                '& .selected-flag': {
                    borderRadius: 10,
                },
            },
        },
    };
});
