import React, { ReactElement } from 'react';

import { useItemListLayoutStyles } from './ItemsListLayoutStyles';

interface ItemsListLayoutProps {
    children: ReactElement[];
}

export const ItemsListLayout = ({ children }: ItemsListLayoutProps): ReactElement => {
    const classes = useItemListLayoutStyles();
    return (
        <div className={classes.container}>
            <div className={classes.sidebarAndMainContentContainer}>
                {children[0] && <div className={classes.sidebar}>{children[0]}</div>}
                {children[1] && children[2] && children[3] && (
                    <div className={classes.mainContent}>
                        <div>{children[1]}</div>
                        <div className={classes.filtersWrapper}>{children[2]}</div>
                        <div className={classes.listContainer}>{children[3]}</div>
                    </div>
                )}
            </div>
            {children[4] && <div className={classes.footer}>{children[4]}</div>}
        </div>
    );
};
