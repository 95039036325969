import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { getAdaptiveFormWidth } from 'src/theming/mixins';

export const useSignUpFormStyles = makeStyles((theme: Theme) => {
    return {
        formContainer: {
            width: 620,
            display: 'flex',
            padding: theme.spacing(5),
            '& .MuiSelect-select': {
                paddingTop: 10,
            },
            ...getAdaptiveFormWidth(theme),
        },
        fullWidth: {
            width: '100%',
        },
        halfWidth: {
            width: '50%',
        },
        actionsContainer: {},
        signUp: {
            width: '50%',
        },
        backToLogin: {
            height: 'fit-content',
            fontSize: '1.4rem',
            fontWeight: theme.typography.fontWeightRegular,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                textDecoration: 'underline',
            },
        },
        phoneNumberWrapper: {
            '& .MuiInputLabel-root': {
                transform: 'translate(40px, 10px) scale(1)',
            },
            '& .MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)',
            },
        },
    };
});
