import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_COLORS } from 'src/theming/theme';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useStaticBodyContentStyles = makeStyles((theme: Theme) => {
    return {
        staticBodyContent: {
            display: 'flex',
            alignItems: 'flex-start',
            gap: theme.spacing(5),
            [getMediaSelectorKey('max-width: 515px')]: {
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
        mediaContainer: {
            position: 'relative',
            width: 150,
            alignSelf: 'stretch',
            borderRadius: 10,
            overflow: 'hidden',
            backgroundColor: theme.palette.primary.main,

            '& img': {
                aspectRatio: `${1 / 1.3}}`,
                display: 'block',
                maxWidth: '100%',
                objectFit: 'cover',
                height: '240px',
            },
            [getMediaSelectorKey('max-width: 515px')]: {
                width: '100%',
            },
        },
        interactiveMediaContainer: {
            transition: '0.3s',
            '&:hover': {
                boxShadow: theme.shadows[3],
            },
        },
        itemName: {
            fontSize: '1.4rem',
        },
        descriptionContainer: {
            '&>*': {
                marginBottom: theme.spacing(1),
            },
        },
        horizontalPlacement: {
            display: 'flex',
            gap: theme.spacing(3),
        },
        shippingContainer: {
            marginTop: theme.spacing(5),
            '& *': {
                fontSize: '1.4rem',
            },
        },
        shippingTo: {
            color: theme.palette.primary.contrastText,
        },
        freeShippingMessage: {
            color: THEME_COLORS.SUBTITLE_COLOR,
        },
        topLabelsContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            borderBottomRightRadius: 10,
            borderTopLeftRadius: 10,
            overflow: 'hidden',
            fontSize: '1.2rem',
            '&>div': {
                padding: `0px 8px`,
            },
        },
        labelsContainer: {
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            left: 0,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 10,
            overflow: 'hidden',
            fontSize: '1.2rem',
            '&>div': {
                padding: `0px 8px`,
            },
        },
        reservedLabel: {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.contrastText,
        },
        isNewGood: {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
        },
    };
});

export const useStaticBodyContentDescrItemStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
            '& *': {
                fontSize: '1.4rem',
            },
        },
        subTitle: {},
        value: {},
    };
});
