import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';

import { useHttpClient } from 'src/lib/http-client/use-http-client';

interface useRemoveFromReservationListProps {
    onRemoveFromReservationListSuccess?: () => void;
    onRemoveFromReservationListError?: () => void;
}

export const useRemoveFromReservationList = ({
    onRemoveFromReservationListSuccess,
    onRemoveFromReservationListError,
}: useRemoveFromReservationListProps): UseMutationResult<
    unknown,
    unknown,
    { id: string },
    unknown
> => {
    const { t } = useTranslation(['booking', 'serverErrors']);
    const httpClient = useHttpClient();
    return useMutation(
        async ({ id }: { id: string }) => {
            if (id) {
                return httpClient.delete(`web-shop/items/${id}/reservations`);
            }
            return null;
        },
        {
            onSuccess: () => {
                if (onRemoveFromReservationListSuccess instanceof Function) {
                    onRemoveFromReservationListSuccess();
                }
            },
            onError: () => {
                toast.error(t('unknownError', { ns: 'serverErrors' }));
                if (onRemoveFromReservationListError instanceof Function) {
                    onRemoveFromReservationListError();
                }
            },
        }
    );
};
