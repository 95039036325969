import { SignUpFormFieldNames } from './SignUpFormFieldNames';

import { SalutationOptions } from 'src/shared/constants';

export const signUpFormInitialValues = {
    [SignUpFormFieldNames.salutation]: SalutationOptions.notSpecified,
    [SignUpFormFieldNames.name]: '',
    [SignUpFormFieldNames.email]: '',
    [SignUpFormFieldNames.surname]: '',
    [SignUpFormFieldNames.password]: '',
    [SignUpFormFieldNames.repeatPassword]: '',
    [SignUpFormFieldNames.street]: '',
    [SignUpFormFieldNames.houseNumber]: '',
    [SignUpFormFieldNames.postcode]: '',
    [SignUpFormFieldNames.place]: '',
    [SignUpFormFieldNames.country]: '',
    [SignUpFormFieldNames.phoneNumber]: '',
};
