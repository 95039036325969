import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { THEME_COLORS, ThemeShadows } from 'src/theming/theme';

export const useStyle = makeStyles((theme: Theme) => {
    return {
        itemsContainer: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 4000,
            '& .MuiPaper-root': {
                maxWidth: 'auto',
                backgroundColor: theme.palette.secondary.contrastText,
                boxShadow: ThemeShadows.light,
            },
        },
        button: {
            width: '100%',
            color: theme.palette.secondary.contrastText,
        },
        buttonText: {
            fontSize: '1.4rem',
        },
        menuItem: {
            display: 'flex',
            minHeight: 44,
            paddingTop: 0,
            paddingBottom: 0,
            color: theme.palette.secondary.main,
            borderBottom: `1px solid ${THEME_COLORS.LIGHT_BORDER_COLOR}`,
            '&.Mui-focusVisible': {
                backgroundColor: `${theme.palette.secondary.light}!important`,
            },
        },
        menuItemText: {
            flexGrow: 1,
            fontSize: '1.4rem',
            color: theme.palette.primary.contrastText,
            whiteSpace: 'break-spaces',
        },
        iconButton: {},
        additionalActive: {
            marginLeft: theme.spacing(2),
            '&:hover': {
                backgroundColor: theme.palette.info.light,
            },
        },
        additionalActionActive: {
            '& .MuiSvgIcon-root': {
                color: theme.palette.info.main,
            },
        },
    };
});
