import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useExtendedProductCardStyles = makeStyles((theme: Theme) => {
    return {
        header: {
            display: 'flex',
            gap: theme.spacing(4),
            padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
            [getMediaSelectorKey('max-width: 540px')]: {
                flexWrap: 'wrap',
            },
            [theme.breakpoints.down('mm')]: {
                gap: theme.spacing(3),
                padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
            },
            [getMediaSelectorKey('max-width: 350px')]: {
                flexDirection: 'column',
                gap: theme.spacing(2),
                padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
            },
            background: THEME_COLORS.LIGHT_BORDER_COLOR,
        },
        mainHeader: {
            background: THEME_COLORS.MAIN_BORDER_COLOR,
        },
        itemsList: {},
        itemBody: {
            display: 'flex',
            flexWrap: 'nowrap',
            gap: theme.spacing(3),
            padding: theme.spacing(5),
            '&:not(:last-child)': {
                borderBottom: `2px solid ${THEME_COLORS.LIGHT_BORDER_COLOR}`,
            },
            [getMediaSelectorKey('max-width: 640px')]: {
                flexWrap: 'wrap',
            },
            [getMediaSelectorKey('max-width: 350px')]: {
                padding: theme.spacing(2),
            },
        },
        staticBodyContent: {
            flexGrow: 1,
        },
        dynamicBodyContent: {
            width: 'fit-content',
            textAlign: 'end',
            [getMediaSelectorKey('max-width: 515px')]: {
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            },
        },
    };
});
