import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListItem, ListItemText } from '@mui/material';

import { useResultBlockStyles } from './ResultBlockStyles';
import { ExtendedItemCard } from '../../ExtendedProductCard';

const langNs = ['formatted-values', 'extendedProductCard'];

interface ResultBlockProps<M> {
    shippingPrice: number;
    freeShippingPrice: number;
    items: ExtendedItemCard<M>[];
}

export function ResultBlock<M>({
    freeShippingPrice,
    shippingPrice,
    items,
}: ResultBlockProps<M>): ReactElement {
    const { t } = useTranslation(langNs);
    const classes = useResultBlockStyles();

    const purchaseSum = useMemo(
        () =>
            items
                .filter((item) => !item.reserved)
                .reduce((acc, item) => {
                    return acc + parseFloat((item.price * item.amount).toFixed(2));
                }, 0),
        [items]
    );

    const itemsPriceSumForDelivery = useMemo(() => {
        return items
            .filter((item) => !item.localOnly && !item.reserved)
            .reduce((acc, item) => {
                return acc + parseFloat((item.price * item.amount).toFixed(2));
            }, 0);
    }, [items]);

    const totalSum = useMemo(() => {
        return parseFloat(
            (
                purchaseSum +
                (itemsPriceSumForDelivery >= freeShippingPrice ||
                itemsPriceSumForDelivery === 0
                    ? 0
                    : shippingPrice)
            ).toFixed(2)
        );
    }, [purchaseSum, itemsPriceSumForDelivery, freeShippingPrice]);

    return (
        <div className={classes.resultBlockContainer}>
            <List dense className={classes.infoContainer}>
                <ListItem dense>
                    <ListItemText>{t('extendedProductCard:purchaseSum')}</ListItemText>
                    <ListItemText className={classes.value}>
                        {t('formatted-values:formattedCurrency', {
                            currency: purchaseSum,
                        })}
                    </ListItemText>
                </ListItem>
                {itemsPriceSumForDelivery < freeShippingPrice &&
                    itemsPriceSumForDelivery !== 0 && (
                        <ListItem dense>
                            <ListItemText>
                                {t('extendedProductCard:shippingPrice')}
                            </ListItemText>

                            <ListItemText className={classes.value}>
                                {t('formatted-values:formattedCurrency', {
                                    currency: shippingPrice,
                                })}
                            </ListItemText>
                        </ListItem>
                    )}
            </List>
            <List className={classes.totalContainer}>
                <ListItem dense>
                    <ListItemText>{t('extendedProductCard:total')}</ListItemText>
                    <ListItemText className={classes.value}>
                        {t('formatted-values:formattedCurrency', { currency: totalSum })}
                    </ListItemText>
                </ListItem>
            </List>
        </div>
    );
}
