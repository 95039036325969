import { flow } from 'mobx-state-tree';
import {
    types,
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    SnapshotIn,
    getEnv,
} from 'mobx-state-tree';

import { RetailCustomerDto } from 'src/shared/types';

export const User = types
    .model('UserModel', {
        id: types.maybeNull(types.string),
        archived: types.maybeNull(types.boolean),
        active: types.maybeNull(types.boolean),
        roles: types.maybeNull(types.array(types.string)),
        name: types.maybeNull(types.string),
        firstname: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        phoneNumber: types.maybeNull(types.string),
        mobileNumber: types.maybeNull(types.string),
        activated: types.maybeNull(types.boolean),
        street: types.maybeNull(types.string),
        houseNumber: types.maybeNull(types.string),
        zipCode: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        shippingAddressName: types.maybeNull(types.string),
        shippingAddressFirstName: types.maybeNull(types.string),
        shippingAddressStreet: types.maybeNull(types.string),
        shippingAddressHouseNumber: types.maybeNull(types.string),
        shippingAddressZipCode: types.maybeNull(types.string),
        shippingAddressCity: types.maybeNull(types.string),
        yearOfBirth: types.maybeNull(types.number),
        newsletter: types.maybeNull(types.boolean),
        salutation: types.maybeNull(types.string),
    })
    .actions((self) => {
        let initialState: IUserSnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore(): void {
                applySnapshot(self, initialState);
            },
            setUserData(data: RetailCustomerDto): void {
                applySnapshot(self, { ...self, ...data });
            },
            clearUserData(): void {
                applySnapshot(self, {});
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchRetailUserData: flow(function* () {
                const data = yield httpClient.get(`users/retail-customer`);
                self.setUserData(data);
            }),
            updateUserData: flow(function* (values: RetailCustomerDto) {
                yield httpClient.put('users/retail-customer', values);
            }),
        };
    })
    .views((self) => {
        return {
            get userData(): IUserSnapshotOut {
                return { ...self };
            },
        };
    });

export type UserModel = Instance<typeof User>;
export type IUserSnapshotOut = SnapshotOut<typeof User>;
export type IUserSnapshotIn = SnapshotIn<typeof User>;
