import React, { ReactElement } from 'react';

import { useBookingLayoutStyles } from './BookingLayoutStyles';

interface BookingLayoutProps {
    children: ReactElement[];
}

export const BookingLayout = ({ children }: BookingLayoutProps): ReactElement => {
    const classes = useBookingLayoutStyles();
    if (!children[0] && !children[1] && !children[2]) {
        return null;
    }
    return (
        <div className={classes.container}>
            {children[0] && <div className={classes.navContainer}>{children[0]}</div>}
            <div className={classes.contentBlock}>
                {children[1] && (
                    <div className={classes.listContainer}>{children[1]}</div>
                )}
                {children[2] && (
                    <div className={classes.actionBlockContainer}>{children[2]}</div>
                )}
            </div>
        </div>
    );
};
