import { createTheme } from '@mui/material/styles';

export enum THEME_COLORS {
    PRIMARY_MAIN_COLOR = '#FFFFFF',
    PRIMARY_LIGHT_COLOR = '#FBFBFB',
    PRIMARY_DARK_COLOR = '#F4F4F4',
    PRIMARY_CONTRAST_TEXT_COLOR = '#3D3D3D',

    SECONDARY_MAIN_COLOR = '#53858C',
    SECONDARY_LIGHT_COLOR = '#84AC9F',
    SECONDARY_DARK_COLOR = '#30627A',
    SECONDARY_CONTRAST_TEXT_COLOR = '#FFFFFF',

    INFO_MAIN_COLOR = '#E5AC5A',
    INFO_LIGHT_COLOR = '#DEBC62',
    INFO_DARK_COLOR = '#D17751',
    INFO_CONTRAST_TEXT_COLOR = '#FFFFFF',
    INFO_WARNING_COLOR = '#FFD79D',
    INFO_WARNING_DARK_COLOR = '#E5AC5A',

    ERROR_MAIN_COLOR = '#DB4E4E',
    ERROR_LIGHT_COLOR = '#FCA2A2',
    ERROR_DARK_COLOR = '#B71414',
    ERROR_CONTRAST_TEXT_COLOR = '#FFFFFF',

    MAIN_BORDER_COLOR = '#DFDFDF',
    LIGHT_BORDER_COLOR = '#EDEDED',

    TITLE_COLOR = '#3D3D3D',
    SUBTITLE_COLOR = '#757575',
}

export const ThemeShadows = {
    light: '0px 0px 8px rgba(0, 0, 0, 0.15)',
};

export const customTheme = createTheme({
    spacing: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    breakpoints: {
        values: {
            xs: 0,
            ms: 325,
            mm: 375,
            ml: 425,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1640,
        },
    },
    typography: {
        fontFamily:
            "'Roboto', 'Roboto Light', 'Helvetica', 'Arial', 'Saveur', sans-serif",
        fontWeightLight: 300,
        fontWeightRegular: 500,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
            fontSize: '4.5rem',
            fontWeight: 600,
            color: THEME_COLORS.TITLE_COLOR,
        },
        h2: {
            fontSize: '3rem',
            fontWeight: 600,
            color: THEME_COLORS.TITLE_COLOR,
        },
        h3: {
            fontSize: '2.4rem',
            fontWeight: 600,
            color: THEME_COLORS.TITLE_COLOR,
        },
        h4: {
            fontSize: '1.8rem',
            fontWeight: 600,
            color: THEME_COLORS.TITLE_COLOR,
        },
        h5: {
            fontSize: '1.6rem',
            fontWeight: 600,
            color: THEME_COLORS.TITLE_COLOR,
        },
        h6: {
            fontSize: '1.4rem',
            fontWeight: 600,
            color: THEME_COLORS.TITLE_COLOR,
        },
        body1: {
            fontSize: '1.8rem',
            fontWeight: 400,
        },
        body2: {
            fontSize: '1.6rem',
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '1.3rem',
            color: THEME_COLORS.SUBTITLE_COLOR,
        },
        subtitle2: {
            fontSize: '1rem',
            color: THEME_COLORS.SUBTITLE_COLOR,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: '10px',
                },
                body: {
                    display: 'flex',
                    minHeight: '100vh',
                    margin: '0px auto',
                    fontSize: '1.6rem',
                    boxSizing: 'border-box',
                    '&>#root': {
                        flexGrow: '1',
                    },
                },
                a: {
                    color: THEME_COLORS.SECONDARY_MAIN_COLOR,
                    textDecoration: 'none',
                    fontWeight: 400,
                    cursor: 'pointer',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
                '*': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${THEME_COLORS.SECONDARY_MAIN_COLOR} ${THEME_COLORS.PRIMARY_DARK_COLOR}`,
                    '&:hover': {
                        scrollbarWidth: 'auto',
                    },
                    // Webkit Browsers (Chrome, Safari, Edge)
                    '&::-webkit-scrollbar': {
                        width: '6px',
                        height: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: THEME_COLORS.PRIMARY_DARK_COLOR,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: THEME_COLORS.SECONDARY_MAIN_COLOR,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: THEME_COLORS.SECONDARY_LIGHT_COLOR,
                    },
                    '&::-webkit-scrollbar-corner': {
                        background: THEME_COLORS.PRIMARY_DARK_COLOR,
                    },
                },
                ul: {
                    margin: 0,
                    padding: 0,
                    listStyle: 'none',
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    textTransform: 'none',
                },
                containedSecondary: {
                    backgroundColor: THEME_COLORS.SECONDARY_DARK_COLOR,
                },
                outlined: {
                    borderWidth: 2,
                    '&:hover': {
                        borderWidth: 2,
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                },
            },
        },
    },
    palette: {
        primary: {
            main: THEME_COLORS.PRIMARY_MAIN_COLOR,
            light: THEME_COLORS.PRIMARY_LIGHT_COLOR,
            dark: THEME_COLORS.PRIMARY_DARK_COLOR,
            contrastText: THEME_COLORS.PRIMARY_CONTRAST_TEXT_COLOR,
        },
        secondary: {
            main: THEME_COLORS.SECONDARY_MAIN_COLOR,
            light: THEME_COLORS.SECONDARY_LIGHT_COLOR,
            dark: THEME_COLORS.SECONDARY_DARK_COLOR,
            contrastText: THEME_COLORS.SECONDARY_CONTRAST_TEXT_COLOR,
        },
        info: {
            main: THEME_COLORS.INFO_MAIN_COLOR,
            light: THEME_COLORS.INFO_LIGHT_COLOR,
            dark: THEME_COLORS.INFO_DARK_COLOR,
            contrastText: THEME_COLORS.INFO_CONTRAST_TEXT_COLOR,
        },
        error: {
            main: THEME_COLORS.ERROR_MAIN_COLOR,
            light: THEME_COLORS.ERROR_LIGHT_COLOR,
            dark: THEME_COLORS.ERROR_DARK_COLOR,
            contrastText: THEME_COLORS.ERROR_CONTRAST_TEXT_COLOR,
        },
        warning: {
            main: THEME_COLORS.INFO_MAIN_COLOR,
            light: THEME_COLORS.INFO_LIGHT_COLOR,
        },
    },
});
