import React, { ReactElement } from 'react';

import { useItemsHistoryLayoutStyles } from './ItemsHistoryLayoutStyles';

interface BoughtItemsLayoutProps {
    children: ReactElement[];
}

export const ItemsHistoryLayout = ({
    children,
}: BoughtItemsLayoutProps): ReactElement => {
    const classes = useItemsHistoryLayoutStyles();
    if (!children[0] && !children[1]) {
        return null;
    }
    return (
        <div className={classes.container}>
            {children[0] && <div className={classes.navContainer}>{children[0]}</div>}
            {children[1] && <div className={classes.listContainer}>{children[1]}</div>}
        </div>
    );
};
