import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { baseUrl } from 'src/environment';

function configureAxiosGlobalSettings(): void {
    axios.defaults.baseURL = baseUrl;
}

export function initializeAxios(): AxiosInstance {
    configureAxiosGlobalSettings();

    const configuration: AxiosRequestConfig = {
        headers: {
            audienceId: 'acaaf79e-5baa-4738-85d8-005782ea8013',
        },
    };

    configuration.baseURL = baseUrl;

    return axios.create(configuration);
}
