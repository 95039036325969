import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
} from 'mobx-state-tree';

import { ImagePreviewMst } from 'src/mst/customTypes';

export const BookingReservationListItem = types
    .model('BookingReservationListItem', {
        id: types.identifier,
        shopName: types.maybeNull(types.string),
        location: types.maybeNull(types.string),
        workhours: types.maybeNull(types.string),
        amount: types.maybeNull(types.number),
        color: types.maybeNull(types.string),
        size: types.maybeNull(types.string),
        reserved: types.maybeNull(types.boolean),
        reservedFrom: types.maybeNull(types.string),
        reservedTo: types.maybeNull(types.string),
        previewImage: types.maybeNull(ImagePreviewMst),
        shippingAvailable: types.maybeNull(types.boolean),
        shippingCosts: types.maybeNull(types.number),
        freeOfShippingCosts: types.maybeNull(types.number),
        sold: types.maybeNull(types.boolean),
        itemId: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        shopId: types.maybeNull(types.string),
        price: types.maybeNull(types.number),
        isNewGood: types.maybeNull(types.boolean),
    })
    .actions((self) => {
        let initialState: IBookingReservationListItemSnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore(): void {
                applySnapshot(self, initialState);
            },
        };
    });

export type BookingReservationListItemModel = Instance<typeof BookingReservationListItem>;
export type IBookingReservationListItemSnapshotOut = SnapshotOut<
    typeof BookingReservationListItem
>;
