import React from 'react';

import { FacebookIcon, InstagramIcon } from 'src/components/base';
import { AppRoutes } from 'src/routing/appRoutes';

interface generateAboutAsListReturn {
    title: string;
    items: {
        id: string;
        text: string;
        href: string;
    }[];
}

export const generateAboutAsList = (): generateAboutAsListReturn => ({
    title: 'common:footer.aboutUs',
    items: [
        {
            id: 'about-us-link',
            text: 'common:footer.aboutUs',
            href: 'https://ekodise.de/ueber-uns/',
        },
        {
            id: 'about-us-all-stores-link',
            text: 'common:footer.allStores',
            href: AppRoutes.shops,
        },
    ],
});

export const helpServiceList = {
    title: 'common:footer.helpService',
    items: [
        {
            id: 'help-service-privacy-link',
            text: 'common:footer.privacy',
            href: AppRoutes.privacy,
            spaLink: true,
        },
        {
            id: 'help-service-imprint-link',
            text: 'common:footer.imprint',
            href: AppRoutes.imprint,
            spaLink: true,
        },
        {
            id: 'help-service-agb-user-link',
            text: 'common:footer.agb-user',
            href: process.env.PUBLIC_URL + '/static/files/AGB_Käufer_EKODISE.pdf',
            download: 'AGB_Käufer_EKODISE.pdf',
            spaLink: false,
        },
        {
            id: 'help-service-agb-shop-link',
            text: 'common:footer.agb-shop',
            href: process.env.PUBLIC_URL + '/static/files/AGB_Händler_EKODISE.pdf',
            download: 'AGB_Händler_EKODISE.pdf',
            spaLink: false,
        },
    ],
};

export const paymentOptionList = {
    title: 'common:footer.paymentOption',
    items: [
        {
            id: 'payment-option-credit-card',
            src: '/static/images/credit-card-logo.svg',
            alt: 'Credit Card',
        },
        {
            id: 'payment-option-google-pay',
            src: '/static/images/google-pay-logo.svg',
            alt: 'Google Pay',
        },
        {
            id: 'payment-option-apple-pay',
            src: '/static/images/apple-pay-logo.svg',
            alt: 'Apple Pay',
        },
        /*{
      id: 'payment-option-visa',
      src: '/static/images/visa-logo.svg',
      alt: 'Visa payment method',
    },*/
        {
            id: 'payment-option-giro-pay',
            src: '/static/images/giro-pay-logo.svg',
            alt: 'Visa payment method',
        },
        {
            id: 'payment-option-klarna',
            src: '/static/images/klarna-logo.svg',
            alt: 'Visa payment method',
        },
        {
            id: 'payment-option-paypal',
            src: '/static/images/paypal-logo.svg',
            alt: 'PayPal',
        },
    ],
};

export const socialNetworkList = {
    title: 'common:footer.socialNetwork',
    items: [
        {
            id: 'social-network-facebook',
            alt: 'Facebook',
            href: 'https://www.facebook.com/profile.php?id=100092609697755',
            Component: () => <FacebookIcon color='#3D3D3D' width={30} height={30} />,
        },
        {
            id: 'social-network-instagram',
            alt: 'Instagram',
            href: 'https://www.instagram.com/secondhand.in.hand',
            Component: () => <InstagramIcon color='#3D3D3D' width={30} height={30} />,
        },
    ],
};
