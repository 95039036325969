import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';

export const useResultBlockStyles = makeStyles((theme: Theme) => {
    return {
        resultBlockContainer: {
            width: '100%',
            '& ul li': {
                paddingLeft: 0,
                paddingRight: 0,
            },
            '& .MuiListItemText-root': {
                margin: 0,
            },
        },
        infoContainer: {
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            borderBottom: `2px dashed ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            '& .MuiTypography-root': {
                fontSize: '1.4rem',
                color: THEME_COLORS.SUBTITLE_COLOR,
            },
        },
        totalContainer: {
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            '& .MuiTypography-root': {
                fontSize: '1.6rem',
                fontWeight: theme.typography.fontWeightBold,
            },
        },
        value: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        crossed: {
            textDecoration: 'line-through',
        },
    };
});
