import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
    flow,
    getEnv,
} from 'mobx-state-tree';

import { WeekHighlightsAndTrendyDto } from 'src/shared/types';

import { Navigation } from '../navigation/navigation';
import { ProductsList } from '../productsList';
import { WeekHighlights } from '../weekHighlights';
import { Trendy } from '../trendy';
import { SelectionValues } from '../selectionValues';
import { Authentication } from '../authentication';
import { ProductDetails } from '../productDetails';
import { WishList } from '../wishList';
import { User } from '../user';
import { BookingReservationList } from '../bookingReservationList';
import { BookingPurchaseList } from '../bookingPurchaseList';
import { PurchasedItemHistory } from '../purchasedItemsHistory';
import { ReservationHistory } from '../reservationHistory';
import { WebShops } from '../webShops';
import { ShopsList } from '../shopsList';

export const Store = types
    .model('Root', {
        user: User,
        authentication: Authentication,
        navigation: Navigation,
        productsList: ProductsList,
        weekHighlights: WeekHighlights,
        trendy: Trendy,
        selectionValues: SelectionValues,
        productDetails: ProductDetails,
        globalSearchValue: types.string,
        wishList: WishList,
        bookingReservationList: BookingReservationList,
        bookingPurchaseList: BookingPurchaseList,
        purchasedItemsHistory: PurchasedItemHistory,
        reservationHistory: ReservationHistory,
        webshops: WebShops,
        shopsList: ShopsList,
    })
    .actions((self) => {
        let initialState: IStoreSnapshotOut;

        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },

            resetStore() {
                applySnapshot(self, initialState);
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            setSearchValue(value: string): void {
                self.globalSearchValue = value;
            },
            fetchHighlightsAndTrendy: flow(function* () {
                const data: WeekHighlightsAndTrendyDto = yield httpClient.get(
                    `web-shop/items/highlights-trendy`
                );
                applySnapshot(self, {
                    ...self,
                    weekHighlights: {
                        items: data.highlightsItems,
                    },
                    trendy: { items: data.trendyItems },
                    bookingPurchaseList: {
                        ...self.bookingPurchaseList,
                        itemReference: null,
                    },
                });
            }),
        };
    });

export type StoreModel = Instance<typeof Store>;
export type IStoreSnapshotOut = SnapshotOut<typeof Store>;
