import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useTreeViewItemStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                color: theme.palette.primary.contrastText,
            },
            selected: {
                color: theme.palette.secondary.main,
            },
            label: {
                paddingLeft: '0!important',
                fontSize: '1.4rem!important',
                fontWeight: `${theme.typography.fontWeightBold}!important`,
            },
            iconContainer: {
                '& .MuiSvgIcon-root': {
                    fontSize: '2.1rem!important',
                },
            },
            content: {
                paddingLeft: 0,
                marginBottom: theme.spacing(3),
            },
            group: {
                marginLeft: theme.spacing(2),
            },
        };
    },
    { index: 1 }
);

export const useGoodsListNavigationStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            paddingTop: theme.spacing(6),
            [theme.breakpoints.down('lg')]: {
                paddingTop: theme.spacing(5),
            },
        },
        heading: {
            fontSize: '1.8rem',
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.6rem',
            },
        },
    };
});
