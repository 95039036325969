import { flow } from 'mobx';
import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
    getEnv,
} from 'mobx-state-tree';
import { generatePath } from 'react-router-dom';

import { ReservationItemsListDto } from 'src/shared/types';
import { AppRoutes } from 'src/routing/appRoutes';

import {
    BookingReservationListItem,
    IBookingReservationListItemSnapshotOut,
} from './bookingReservationListItem';
import { BookingReservationListFormattedItem } from './types';

export const BookingReservationList = types
    .model('BookingReservationList', {
        list: types.maybeNull(types.array(BookingReservationListItem)),
    })
    .actions((self) => {
        let initialState: IBookingReservationListSnapshotOut;

        const {
            env: { httpClient },
        } = getEnv(self);

        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },

            resetStore(): void {
                applySnapshot(self, initialState);
            },
            leaveOnlyReservedItems(): void {
                const reservedItems = self.list.filter((item) => item.reserved);
                applySnapshot(self, { ...self, list: reservedItems });
            },
            fetchBookingReservationList: flow(function* () {
                const data: ReservationItemsListDto = yield httpClient.get(
                    'web-shop/reservations/get-items'
                );
                applySnapshot(self, { ...self, list: data.reservedItems });
            }),
            reserve: flow(function* () {
                yield httpClient.post('web-shop/items/reservation/retail-customer');
            }),
            clearReservationList(): void {
                applySnapshot(self, { ...self, list: [] });
            },
        };
    })
    .views((self) => {
        return {
            get isReservation(): boolean {
                return Boolean(self.list?.length);
            },
            get isCompleteReservationAvailable(): boolean {
                return Boolean(self.list?.filter((item) => !item.reserved).length);
            },
            isReservedById(id: string): boolean {
                return self.list?.some((item) => item.itemId === id);
            },
            get isReservedItemsBySomeoneInList(): boolean {
                return self.list?.some((item) => item.reserved);
            },
            get totalPrice(): number {
                return (
                    parseFloat(
                        self.list
                            ?.filter((item) => !item.reserved)
                            .reduce((acc, item) => acc + item.price, 0)
                            ?.toFixed(2)
                    ) || 0
                );
            },
            get amountOfReservations(): number {
                return self.list?.length || 0;
            },
            get formattedReservationList(): BookingReservationListFormattedItem[] {
                const shops: Set<string> = new Set<string>(
                    self.list?.map((item) => item.shopId)
                );
                const formattedReservationList: BookingReservationListFormattedItem[] =
                    Array.from(shops).map((shop, index) => {
                        const itemsByShop = self.list?.filter(
                            (item) => item.shopId === shop
                        );
                        return {
                            id: `${index}`,
                            shop: itemsByShop[0].shopName,
                            shopId: itemsByShop[0].shopId,
                            location: itemsByShop[0].location,
                            workHours: itemsByShop[0].workhours,
                            reservedFrom: itemsByShop[0].reservedFrom,
                            reservedTo: itemsByShop[0].reservedTo,
                            items: itemsByShop.map(
                                (item: IBookingReservationListItemSnapshotOut) => ({
                                    id: item.itemId,
                                    reserved: item.reserved,
                                    adddedToCart: false,
                                    availableForPurchaseAndBooking: false,
                                    addedToBookingList: false,
                                    localOnly: !item.shippingAvailable,
                                    isNewGood: item.isNewGood,
                                    shippingAvailable: item.shippingAvailable,
                                    name: item.name,
                                    price: item.price,
                                    freeShippingPrice: item.freeOfShippingCosts,
                                    amount: 1, // It is hardcoded because it is supposed that we can reserve only 1 item
                                    color: item.color,
                                    size: item.size,
                                    stock: item.amount,
                                    previewImage: item.previewImage,
                                    imageLink: generatePath(AppRoutes.goodsItem, {
                                        itemId: item.itemId,
                                    }),
                                })
                            ),
                        };
                    });
                return formattedReservationList;
            },
        };
    });

export type BookingReservationListModel = Instance<typeof BookingReservationList>;
export type IBookingReservationListSnapshotOut = SnapshotOut<
    typeof BookingReservationList
>;
