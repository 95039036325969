import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useLanguageDetectorStyle = makeStyles((theme: Theme) => {
    return {
        langBtn: {
            fontSize: '1.8rem',
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            '& p': {
                fontSize: '1.8rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 25,
                color: theme.palette.primary.contrastText,
            },
        },
    };
});
