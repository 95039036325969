import { types } from 'mobx-state-tree';

import { NavMenuItem } from 'src/components/layouts/BaseLayout/components/MainProductNavigationMenu/NavMenuItem';

export const NavItemMst = types.custom({
    name: 'NavItem',
    fromSnapshot(value: NavMenuItem) {
        return value;
    },
    toSnapshot(value: NavMenuItem) {
        return value;
    },
    isTargetType(value): boolean {
        return Boolean(
            value.id &&
                value.title &&
                (value.submenu ? Array.isArray(value.submenu) : true)
        );
    },
    getValidationMessage(value: NavMenuItem): string {
        return `${value} doesn't look like a valid structure`;
    },
});
