import React, { ReactElement } from 'react';

import { TextField } from 'src/components/fields';

interface IntegerFieldProps {
    id: string;
    name: string;
    value: number | null;
    onChange: (name: string, value: string) => void;
    tLabelKey: string;
    languageNamespaces: string[];
    disabled?: boolean;
    tPlaceholderKey?: string;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const IntegerField = ({
    id,
    name,
    disabled,
    tPlaceholderKey,
    tLabelKey,
    value,
    onChange,
    languageNamespaces,
    ...rest
}: IntegerFieldProps): ReactElement => {
    return (
        <TextField
            id={id}
            inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
            }}
            disabled={disabled}
            label={tLabelKey}
            translationNamespaces={languageNamespaces}
            value={String(value)}
            onChange={onChange}
            name={name}
            placeholder={tPlaceholderKey}
            {...rest}
        />
    );
};

IntegerField.defaultProps = {
    disabled: false,
    tPlaceholderKey: '',
    tLabelKey: '',
};
