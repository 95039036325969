import { generatePath } from 'react-router-dom';

import { AppRoutes } from 'src/routing/appRoutes';

export const getPathToNavigate = (
    menuId: string,
    categoryId: string,
    subCategoryId: string
): string => {
    if (menuId && categoryId && subCategoryId) {
        return subCategoryId === 'All'
            ? generatePath(AppRoutes.goodsListCategory, { menuId, categoryId })
            : generatePath(AppRoutes.goodsListSubCategory, {
                  menuId,
                  categoryId,
                  subCategoryId,
              });
    }
    if (menuId && categoryId) {
        return categoryId === 'All'
            ? generatePath(AppRoutes.goodsListMenu, { menuId })
            : generatePath(AppRoutes.goodsListCategory, { menuId, categoryId });
    }
    if (menuId) {
        return generatePath(AppRoutes.goodsListMenu, { menuId });
    }
    return '';
};
