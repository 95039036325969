import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
} from 'mobx-state-tree';

import { Product } from '../product';

export const Trendy = types
    .model('TrendyModel', {
        items: types.maybeNull(types.array(Product)),
    })
    .actions((self) => {
        let initialState: ITrendySnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore(): void {
                applySnapshot(self, initialState);
            },
        };
    });

export type TrendyModel = Instance<typeof Trendy>;
export type ITrendySnapshotOut = SnapshotOut<typeof Trendy>;
