import React, { ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs as MaterialTabs, Tab } from '@mui/material';

import { withBadge } from 'src/shared/hocs';

import { useTabStyles, useTabsStyles } from './TabsStyles';

const TabWithBadge = withBadge(Tab);

export interface TabItem {
    id: string;
    value: string;
    label: string;
    badgeValue?: number;
    [key: string]: string | number;
}

interface TabsProps {
    value: string;
    onChange: (value: string) => void;
    items: TabItem[];
    ariaLabel?: string;
    languageNS?: string[];
}

export const Tabs = ({
    ariaLabel,
    onChange,
    value,
    items,
    languageNS,
}: TabsProps): ReactElement => {
    const tabsClasses = useTabsStyles();
    const tabClasses = useTabStyles();
    const { t } = useTranslation(languageNS);
    const handleChange = (event: SyntheticEvent, newValue: string): void => {
        onChange(newValue);
    };

    return (
        <MaterialTabs
            indicatorColor='secondary'
            textColor='secondary'
            value={value}
            onChange={handleChange}
            aria-label={ariaLabel}
            classes={tabsClasses}
        >
            {items.map(({ id, label, value, badgeValue }) => {
                return (
                    <TabWithBadge
                        disableRipple
                        classes={tabClasses}
                        key={id}
                        label={t(label)}
                        value={value}
                        badgeValue={badgeValue || 0}
                    />
                );
            })}
        </MaterialTabs>
    );
};

Tabs.defaultProps = {
    ariaLabel: 'tabs navigation',
};
