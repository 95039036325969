import React, { ReactElement, Suspense, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
    SignUpForm,
    SignInForm,
    RestorePasswordForm,
    CreateNewPasswordForm,
} from 'src/components/forms';
import { ModalWindow } from 'src/components/base';
import { useStore } from 'src/mst/StoreProvider';
import { AuthModuleSections } from 'src/authentication/constants';
import { useQuery } from 'src/shared/hooks';

export const AuthenticationEntryPoint = observer((): ReactElement => {
    const { t } = useTranslation(['authentication']);
    const { authentication } = useStore();

    const action = useQuery().get('action');
    const type = useQuery().get('type');
    const token = useQuery().get('token');

    useEffect(() => {
        if (action && action === AuthModuleSections.creatingPassword) {
            authentication.setAuthModule(AuthModuleSections.creatingPassword);
        }
    }, [action]);

    const onClose = (): void => {
        authentication.setAuthModule(null);
    };

    return (
        <Suspense fallback={null}>
            <ModalWindow isOpen={Boolean(authentication.authModule)} onClose={onClose}>
                <>
                    {authentication.authModule
                        ? t(`authentication:${authentication.authModule}`)
                        : null}
                </>
                <>
                    {authentication.authModule === AuthModuleSections.signUp && (
                        <SignUpForm />
                    )}
                    {authentication.authModule === AuthModuleSections.signIn && (
                        <SignInForm />
                    )}
                    {authentication.authModule === AuthModuleSections.passwordRestore && (
                        <RestorePasswordForm />
                    )}
                    {authentication.authModule ===
                        AuthModuleSections.creatingPassword && (
                        <CreateNewPasswordForm type={type} token={token} />
                    )}
                </>
            </ModalWindow>
        </Suspense>
    );
});
