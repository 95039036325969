import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { getAdaptiveFormWidth } from 'src/theming/mixins';

export const useCreateNewPasswordFormStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            padding: theme.spacing(5),
            ...getAdaptiveFormWidth(theme),
        },
        inputWrapper: {
            width: '100%',
        },
    };
});
