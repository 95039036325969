import { types } from 'mobx-state-tree';

export interface ProductsFilterItem {
    filter: string;
    titles: (() => string)[];
    value: string;
}

export const ProductsListFilterMst = types.custom({
    name: 'ProductsFilterItem',
    fromSnapshot(value: ProductsFilterItem) {
        return value;
    },
    toSnapshot(value: ProductsFilterItem) {
        return value;
    },
    isTargetType(value): boolean {
        const title = value.titles.reduce((acc, cb) => acc + cb(), '');
        return Boolean(value.filter != null && title != null && value.value != null);
    },
    getValidationMessage(value: ProductsFilterItem): string {
        return `${value} doesn't look like a valid structure`;
    },
});
