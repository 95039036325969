import { getMediaSelectorKey } from 'src/shared/utils';
import { Theme } from '@mui/material';

export const centerAlignByFlex = (): {
    display: string;
    alignItems: 'center';
    justifyContent: 'center';
} => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
};

export const getAdaptiveFormWidth = (theme: Theme): object => ({
    [getMediaSelectorKey('max-width: 670px')]: {
        width: 550,
    },
    [theme.breakpoints.down('sm')]: {
        width: 450,
        flexDirection: 'column',
    },
    [getMediaSelectorKey('max-width: 500px')]: {
        width: 400,
    },
    [theme.breakpoints.down('ml')]: {
        width: 350,
    },
    [theme.breakpoints.down('mm')]: {
        width: 300,
    },
    [theme.breakpoints.down('ms')]: {
        width: 260,
    },
});
