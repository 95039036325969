import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useQuery as useQueryParams, useShopsAttachments } from 'src/shared/hooks';
import { withLazyRendering } from 'src/shared/hocs/withLazyRendering';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { ProductCard, Loader, Message, ProductCardProps } from 'src/components/base';
import { useStore } from 'src/mst/StoreProvider';

import { useGoodsListItemsStyles } from './GoodsListItemsStyles';
import { ShopsListItemDto } from 'src/shared/types';
import { useQueryParamFilter } from './useQueryParamFilter';

const langNs = ['common'];

interface ItemsProps {
    items: ProductCardProps[];
    shops: ShopsListItemDto[];
}

const Items = withLazyRendering<ItemsProps>((props: ItemsProps): ReactElement => {
    const classes = useGoodsListItemsStyles();
    const { items, shops } = props;

    const shopsWithLogo = shops
        .filter((shop) => shop.id && shop.logo?.id)
        .map((shop) => ({
            id: shop.id,
            attachmentId: shop.logo.id,
        }));

    if (shopsWithLogo.length < shops.length)
        // eslint-disable-next-line no-console
        console.log('shop id(s) or shop logo id(s) missing');

    const shopLogos = useShopsAttachments(shopsWithLogo);

    return (
        <>
            {items.map((item) => {
                return (
                    <>
                        <div
                            id={item.id}
                            key={item.id}
                            className={classes.listItemContainer}
                        >
                            <ProductCard
                                {...item}
                                shopName={shops.find((s) => s.id === item.shopId)?.name}
                                shopLogoSrc={
                                    shopLogos.find((l) => l.shopId === item.shopId)?.src
                                }
                            />
                        </div>
                    </>
                );
            })}
            <div style={{ flexGrow: 1 }} />
        </>
    );
});

export const GoodsListItems = observer((): ReactElement => {
    const classes = useGoodsListItemsStyles();
    const root = useStore();
    const { i18n } = useTranslation();
    const { productsList, shopsList } = root;
    const { items } = productsList;
    const { list: shops } = shopsList;
    const page = useQueryParams().get('page');
    const { menuId, categoryId, subCategoryId } = useParams();
    const [scrollTimer, setScrollTimer] = useState<NodeJS.Timeout>();

    const { applyFiltersFromQuery, applyFiltersToQuery } = useQueryParamFilter();

    const debouncedSearchValue = useDebounce(root.globalSearchValue);

    useEffect(() => {
        applyFiltersFromQuery();
    }, []);

    const { isLoading } = useQuery(
        [
            page || 1,
            debouncedSearchValue,
            menuId,
            categoryId,
            subCategoryId,
            productsList.filters,
            productsList.sorting,
            i18n.language,
        ],
        productsList.fetchProductsList,
        {
            refetchOnWindowFocus: false,
            onSuccess: () => {
                applyFiltersToQuery();
            },
        }
    );

    useQuery([], shopsList.fetchAllShops, {
        refetchOnWindowFocus: false,
    });

    const lastBrowsedItem = useMemo(
        () => sessionStorage.getItem('lastBrowsedItem'),
        [sessionStorage]
    );

    useEffect(() => {
        const scrollToLastItem = (): void => {
            if (lastBrowsedItem != null) {
                const divElement = document.getElementById(lastBrowsedItem);
                if (divElement != null) {
                    divElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    sessionStorage.removeItem('lastBrowsedItem');
                } else {
                    setScrollTimer(setTimeout(scrollToLastItem, 250));
                }
            } else if (scrollTimer != null) {
                clearTimeout(scrollTimer);
                setScrollTimer(null);
            }
        };

        scrollToLastItem();
    }, []);

    return (
        <div className={classes.listContainer}>
            {!isLoading &&
                !(Array.isArray(items) && (items as Array<unknown>).length > 0) && (
                    <Message
                        message='common:noDataWithSuchParameters'
                        languageNamespaces={langNs}
                    />
                )}
            {isLoading && <Loader />}
            {!isLoading && Array.isArray(items) && items.length > 0 && (
                <Items
                    items={items}
                    className={classes.listContainer}
                    shops={shops}
                    renderItemsStep={lastBrowsedItem != null ? items.length : 16}
                />
            )}
        </div>
    );
});
