import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useStore } from 'src/mst/StoreProvider';
import { SelectionValues as SelectionValuesEnum } from 'src/shared/constants';
import { SelectableItem } from 'src/shared/types';

export const groupBy = <T>(array: T[], key: string): Record<string, T[]> => {
    return array.reduce((rv: Record<string, T[]>, x: T) => {
        const item = x as Record<string, unknown>;
        (rv[item[key] as string] = rv[item[key] as string] || []).push(x);
        return rv;
    }, {});
};

export type QueryParamFilter = {
    applyFiltersFromQuery: () => void;
    applyFiltersToQuery: () => void;
};

export const useQueryParamFilter = (): QueryParamFilter => {
    const navigate = useNavigate();
    const { productsList, selectionValues } = useStore();
    const queryParams = useSearchParams()[0];
    const { menuId, subCategoryId } = useParams();

    const mappedQueryParams = groupBy(
        [...queryParams.entries()].map((param) => ({
            key: param[0],
            value: param[1],
        })),
        'key'
    );

    const applyFiltersFromQuery = async (): Promise<void> => {
        Object.keys(mappedQueryParams).forEach(async (key) => {
            if ((mappedQueryParams[key][0].key as SelectionValuesEnum) == null) {
                return;
            }
            if (
                mappedQueryParams[key][0].key === SelectionValuesEnum.size ||
                mappedQueryParams[key][0].key === SelectionValuesEnum.sizeShoes
            ) {
                await selectionValues.fetchSizeSelectionValues(
                    mappedQueryParams[key][0].key,
                    menuId || '',
                    subCategoryId || ''
                );
            } else {
                await selectionValues.fetchSelectionValues(mappedQueryParams[key][0].key);
            }
            const items: SelectableItem[] | null =
                selectionValues.getSelectionItemsByName(mappedQueryParams[key][0].key);
            if (items?.length == 0) {
                return;
            }
            const mappedFilters = mappedQueryParams[key]
                .map((queryParam) => {
                    const found = items.find((item) => item.value === queryParam.value);
                    return {
                        filter: queryParam.key,
                        titles: [() => found?.title || ''],
                        value: found?.value || '',
                    };
                })
                .filter((filter) => filter.value != null && filter.value != '');
            if (mappedFilters.length === 0) {
                return;
            }

            productsList.setFilterValues(key, mappedFilters);
        });
    };

    const applyFiltersToQuery = (): void => {
        let aggregate = '';
        Object.keys(mappedQueryParams).forEach(async (key) => {
            if (
                Object.values(SelectionValuesEnum).includes(
                    mappedQueryParams[key][0].key as SelectionValuesEnum
                )
            ) {
                return;
            }
            mappedQueryParams[key].forEach((item) => {
                if (aggregate != '') {
                    aggregate = aggregate + `&${item.key}=${item.value}`;
                } else {
                    aggregate = `?${item.key}=${item.value}`;
                }
            });
        });
        productsList.filters.forEach((item) => {
            if (aggregate != '') {
                aggregate = aggregate + `&${item.filter}=${item.value}`;
            } else {
                aggregate = `?${item.filter}=${item.value}`;
            }
        });

        navigate(`${location.pathname}${aggregate}`);
    };

    return { applyFiltersFromQuery, applyFiltersToQuery };
};
