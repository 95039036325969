import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
} from 'mobx-state-tree';

import { Product } from '../product';

export const WeekHighlights = types
    .model('WeekHihtlightsModel', {
        items: types.maybeNull(types.array(Product)),
    })
    .actions((self) => {
        let initialState: IWeekHighlightsSnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore(): void {
                applySnapshot(self, initialState);
            },
        };
    });

export type WeekHighlightsModel = Instance<typeof WeekHighlights>;
export type IWeekHighlightsSnapshotOut = SnapshotOut<typeof WeekHighlights>;
