import React, { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';

import { FavoriteIcon } from 'src/components/base';
import { NestedMenu } from 'src/components/base/NestedMenu';
import { AppRoutes } from 'src/routing/appRoutes';
import { useStore } from 'src/mst/StoreProvider';
import { AuthModuleSections } from 'src/authentication/constants';
import { getBookingStartingPage } from 'src/shared/utils';

import { generateCurrentProfileMenu } from './configs';
import { useShortcutNavStyles } from './ShortCutNavStyles';
import { ShortCutNavItem } from './ShortCutNavItem';

const languageNS = ['common'];

export const ShortCutNav = observer((): ReactElement => {
    const classes = useShortcutNavStyles();
    const navigate = useNavigate();
    const {
        authentication,
        wishList: { amountOfFavorites },
        bookingPurchaseList: { amountOfPurchases },
        bookingReservationList: { amountOfReservations },
    } = useStore();

    const { isAuth } = authentication;

    const login = (): void => {
        authentication.setAuthModule(AuthModuleSections.signIn);
    };

    const signup = (): void => {
        authentication.setAuthModule(AuthModuleSections.signUp);
    };

    const logout = (): void => {
        authentication.logout();
    };

    const currentProfileMenu = useMemo(() => {
        return generateCurrentProfileMenu({ isAuth, login, logout, signup, navigate });
    }, [isAuth]);

    return (
        <div className={classes.shotCutNav}>
            <ShortCutNavItem
                badgeValue={amountOfFavorites}
                tooltipTitle='common:shortcutNavTooltips.goToWishList'
                langNs={languageNS}
                Icon={FavoriteIcon}
                path={AppRoutes.wishlist}
                ariaLabel='wishlist'
            />
            <ShortCutNavItem
                badgeValue={amountOfPurchases + amountOfReservations}
                tooltipTitle='common:shortcutNavTooltips.goToCart'
                langNs={languageNS}
                Icon={ShoppingCartOutlinedIcon}
                path={getBookingStartingPage({
                    isAuth,
                    amountOfPurchases,
                    amountOfReservations,
                })}
                ariaLabel='shopping cart'
            />

            <NestedMenu
                needTooltip
                tooltipTitle='common:shortcutNavTooltips.openProfileMenu'
                buttonClass={classes.button}
                buttonIcon={<PermIdentityOutlinedIcon />}
                menuItems={currentProfileMenu}
                namespaces={languageNS}
                ariaLabel='profile menu'
            />
        </div>
    );
});
