import React, { useState, ReactElement, KeyboardEvent, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';

import {
    IconButton,
    SwipeableDrawer,
    Box,
    CircularProgress,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { LanguageSelector, StoreLinks } from 'src/components/base';
import { centerAlignByFlex } from 'src/theming/mixins';
import { useStore } from 'src/mst/StoreProvider';

import { useTabletMenuStyles } from './TabletMenuStyles';
import { MainProductTabletNavigationMenu } from '../MainProductTabletNavigationMenu';
import {
    AutocompleteFilter,
    FilterChip,
} from '../../../../../pages/GoodsListPage/components/GoodsListFilterPanel/components';
import { SelectionValues } from '../../../../../shared/constants';
import { useTranslation } from 'react-i18next';

interface TabletMenuProps {
    isTargetGroupsLoading: boolean;
}
export const TabletMenu = observer(
    ({ isTargetGroupsLoading }: TabletMenuProps): ReactElement => {
        const [open, setOpen] = useState<boolean>(false);
        const classes = useTabletMenuStyles();

        const { productsList } = useStore();
        const { t } = useTranslation(['mainPage']);

        const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as KeyboardEvent).key === 'Tab' ||
                    (event as KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setOpen(open);
        };

        const list = (): ReactElement => (
            <Box role='presentation' className={classes.drawerContentWrapper}>
                <div className={classes.drawerHeader}>
                    <div className={classes.drawerLanguageSelectorWrapper}>
                        <LanguageSelector />
                    </div>
                    <div className={classes.drawerLogoWrapper}>
                        <img src='/static/images/logo.png' alt='Ekodise webshop' />
                    </div>
                    <IconButton
                        className={classes.clsButton}
                        onClick={toggleDrawer(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 12,
                    }}
                >
                    <Typography variant='h5'>{t('chooseStoresQuestion')}</Typography>
                    <AutocompleteFilter
                        label={'product-filters:shop'}
                        id={'Shop filter'}
                        name={SelectionValues.shop}
                        languageNamespaces={['product-filters']}
                        width={240}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        {productsList.filters
                            .filter(
                                (item) =>
                                    item.value && item.filter === SelectionValues.shop
                            )
                            .map((item) => {
                                return (
                                    <FilterChip
                                        key={`${item.filter}${item.value}`}
                                        {...item}
                                    />
                                );
                            })}
                    </div>
                </div>
                <div className={classes.navigation}>
                    {!isTargetGroupsLoading && (
                        <MainProductTabletNavigationMenu setOpen={setOpen} />
                    )}
                    {isTargetGroupsLoading && (
                        <Box
                            sx={{ ...centerAlignByFlex(), width: '100%', height: '100%' }}
                        >
                            <CircularProgress color='secondary' />
                        </Box>
                    )}
                </div>

                <StoreLinks injectedClassName={classes.linksWrapper} />
            </Box>
        );

        return (
            <div>
                <IconButton className={classes.burgerButton} onClick={toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    anchor='right'
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    sx={{ zIndex: 1000 }}
                >
                    {list()}
                </SwipeableDrawer>
            </div>
        );
    }
);
