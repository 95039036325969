import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useProductCardAddToCartListStyles = makeStyles((theme: Theme) => {
    return {
        addToCartBtn: {
            width: '100%',
            marginTop: theme.spacing(4),
            [theme.breakpoints.down('xl')]: {
                fontSize: '1.4rem',
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.2rem',
            },
        },
    };
});
