import { applicationVersion } from 'src/environment';

import { useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useAxios } from 'src/lib/http-client/use-http-client';

export const useAppVersionInterceptor = (): void => {
    const axios = useAxios();

    const appVersionInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
        if (config.headers) {
            config.headers.AppVersion = applicationVersion || '';
        }

        return config;
    };

    const reqInterceptor = axios.interceptors.request.use(appVersionInterceptor);

    useEffect(() => {
        return () => {
            if (reqInterceptor) {
                axios.interceptors.request.eject(reqInterceptor);
            }
        };
    }, []);
};
