import React, { ReactElement, useState, SyntheticEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { useStore } from 'src/mst/StoreProvider';
import { NavMenuItem } from 'src/components/layouts/BaseLayout/components/MainProductNavigationMenu/NavMenuItem';

import {
    useTreeViewItemStyles,
    useGoodsListNavigationStyles,
} from './GoodsListNavigationStyles';
import { GoodListNavigationItem } from './GoodListNavigationItem';

const getDefaultTreeState = (
    categoryId?: string,
    subCategoryId?: string
): {
    defaultExpanded: string[];
    defaultSelected: string;
} => {
    const defaultExpanded: string[] = [];
    let defaultSelected = '';

    if (categoryId && !subCategoryId) {
        defaultSelected = categoryId;
    }
    if (categoryId && subCategoryId) {
        defaultExpanded.push(categoryId);
        defaultSelected = subCategoryId;
    }

    return { defaultExpanded, defaultSelected };
};

export const GoodsListNavigation = observer((): ReactElement => {
    const treeViewItemClasses = useTreeViewItemStyles();
    const classes = useGoodsListNavigationStyles();

    const { t } = useTranslation(['common']);

    const { menuId, categoryId, subCategoryId } =
        useParams<{ menuId: string; categoryId: string; subCategoryId: string }>();

    const { navigation } = useStore();

    const [expanded, setExpanded] = useState<string[]>([]);
    const [selected, setSelected] = useState<string>('');

    const categories = navigation.getCategoriesByMenuId(menuId as string);

    useEffect(() => {
        const defaultState = getDefaultTreeState(categoryId, subCategoryId);
        setSelected(defaultState.defaultSelected);
        setExpanded([...expanded, ...defaultState.defaultExpanded]);
    }, [menuId, categoryId, subCategoryId]);

    const handleToggle = (_: SyntheticEvent, nodeIds: string[]): void => {
        setExpanded(nodeIds);
    };

    const handleSelect = (_: React.SyntheticEvent, nodeId: string): void => {
        setSelected(nodeId);
    };

    const renderTree = (
        nodes: NavMenuItem[],
        depthLevel: number,
        parentId?: string
    ): ReactElement => (
        <>
            {Array.isArray(nodes) &&
                nodes.map((node) => (
                    <TreeItem
                        ContentComponent={GoodListNavigationItem}
                        ContentProps={
                            { depthLevel, parentId } as React.HTMLAttributes<HTMLElement>
                        }
                        key={node.id}
                        nodeId={node.id}
                        label={node.title}
                        classes={treeViewItemClasses}
                    >
                        {Array.isArray(node.submenu)
                            ? renderTree(node.submenu, depthLevel + 1, node.id)
                            : null}
                    </TreeItem>
                ))}
        </>
    );

    return (
        <div className={classes.container}>
            <Typography variant='h2' className={classes.heading}>
                {t('common:category')}
            </Typography>
            <TreeView
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
                defaultCollapseIcon={<ExpandLessIcon />}
                defaultExpandIcon={<ExpandMoreIcon />}
            >
                {renderTree(categories, 0)}
            </TreeView>
        </div>
    );
});
