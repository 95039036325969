import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';

export const useBreadcrumpsStyle = makeStyles((theme: Theme) => {
    return {
        item: {
            fontSize: '1.4rem',
            color: THEME_COLORS.SUBTITLE_COLOR,
        },
        activeBreadcump: {
            color: theme.palette.secondary.dark,
        },
        breadcrumps: {
            '& *': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    };
});
