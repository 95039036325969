import React, { ComponentType, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LinearProgress } from '@mui/material';

interface WithLazyRenderingProps {
    items: Array<unknown>;
    className: string;
    renderItemsStep: number;
}

export function withLazyRendering<T>(
    Component: ComponentType<T>
): ComponentType<T & WithLazyRenderingProps> {
    return (props: T & WithLazyRenderingProps) => {
        const { items, className, renderItemsStep } = props;
        const [end, setEnd] = useState(renderItemsStep);

        const handleNext = (): void => {
            setEnd(end + renderItemsStep);
        };

        return (
            <InfiniteScroll
                dataLength={end}
                hasMore={end < items.length}
                next={handleNext}
                loader={<LinearProgress color='secondary' />}
                style={{ overflow: 'visible' }}
                className={className}
            >
                <Component {...(props as T)} items={items.slice(0, end)} />
            </InfiniteScroll>
        );
    };
}
