import React, { ReactElement } from 'react';

import { Chip, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import { ProductsFilterItem } from 'src/mst/customTypes';
import { useStore } from 'src/mst/StoreProvider';
import { THEME_COLORS } from 'src/theming/theme';

const useProductFilterChipStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            fontSize: '1.4rem',
            color: theme.palette.primary.contrastText,
            backgroundColor: THEME_COLORS.INFO_WARNING_COLOR,
            '& .MuiSvgIcon-root': {
                color: theme.palette.primary.contrastText,
            },
        },
    };
});

export const FilterChip = ({
    filter,
    titles,
    value,
}: ProductsFilterItem): ReactElement => {
    const classes = useProductFilterChipStyles();
    const { productsList } = useStore();

    const handleDelete = (): void => {
        productsList.removeFilterValue(filter, value);
    };

    const title = titles.map((cb: () => string) => cb()).join(' ');

    return (
        <Chip
            classes={classes}
            label={title}
            onDelete={handleDelete}
            deleteIcon={<CloseIcon />}
        />
    );
};
