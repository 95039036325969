import { types, flow, applySnapshot, getEnv } from 'mobx-state-tree';

import { ShopsListDto } from 'src/shared/types';

import { ShopsListItem } from './shopsListItem';

export const ShopsList = types
    .model('ShopsList', {
        total: types.maybeNull(types.number),
        list: types.array(ShopsListItem),
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchAllShops: flow(function* () {
                const data: ShopsListDto = yield httpClient.get('web-shop/web-shops');
                applySnapshot(self, { ...self, list: data.webShops });
            }),
        };
    });
