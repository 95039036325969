import React, { ReactElement } from 'react';

import SearchIcon from '@mui/icons-material/Search';

import { useTheme } from '@mui/material';

import { TextField } from '../TextField';

interface SearchFieldProps {
    id: string;
    value: string;
    name: string;
    onChange: (name: string, value: string) => void;
}

export const SearchField = (props: SearchFieldProps): ReactElement => {
    const theme = useTheme();
    return (
        <TextField
            {...props}
            color='secondary'
            startAdornment={
                <SearchIcon
                    style={{ width: 25, height: 25, color: theme.palette.grey[600] }}
                />
            }
        />
    );
};
