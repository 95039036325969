import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useInputBaseStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
        },
        input: {
            height: 40,
            paddingLeft: theme.spacing(2),
            boxSizing: 'border-box',
            borderRadius: 5,
            '&::placeholder': {
                fontSize: '1.4rem',
            },
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color',
                boxShadow: 'none',
            },
        },
    };
});
