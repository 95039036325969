import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { BaseLayoutHeader, BaseLayoutFooter } from './components';
import { useBaseLayoutStyles } from './BaseLayoutStyles';

export const BaseLayout = (): ReactElement => {
    const classes = useBaseLayoutStyles();

    return (
        <div className={classes.container}>
            <BaseLayoutHeader />
            <main className={classes.main}>
                <Outlet />
            </main>
            <BaseLayoutFooter />
        </div>
    );
};
