import * as yup from 'yup';

import { SignUpFormFieldNames } from './SignUpFormFieldNames';

export const signUpValidationSchema = yup.object().shape({
    [SignUpFormFieldNames.email]: yup
        .string()
        .email('incorrectEmail')
        .required('required'),
    [SignUpFormFieldNames.surname]: yup.string().required('required'),
    [SignUpFormFieldNames.password]: yup.string().required('required'),
    [SignUpFormFieldNames.repeatPassword]: yup
        .string()
        .oneOf(
            [yup.ref(SignUpFormFieldNames.password), SignUpFormFieldNames.password],
            'passwordsAreNotMatched'
        )
        .required('required'),
    [SignUpFormFieldNames.street]: yup.string().required('required'),
    [SignUpFormFieldNames.houseNumber]: yup.string().required('required'),
    [SignUpFormFieldNames.postcode]: yup.string().required('required'),
    [SignUpFormFieldNames.place]: yup.string().required('required'),
    [SignUpFormFieldNames.country]: yup.string().required('required'),
});
