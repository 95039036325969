import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';

export const useTabletMenuStyles = makeStyles((theme: Theme) => {
    return {
        burgerButton: {
            '& .MuiSvgIcon-root': {
                fontSize: 25,
                color: theme.palette.primary.contrastText,
            },
        },
        drawerContentWrapper: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: 335,
            overflowX: 'hidden',
            height: '100%',
        },
        drawerHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(3),
            paddingBottom: theme.spacing(2),
        },
        searchFieldWrapper: {
            padding: `0 ${theme.spacing(3)}`,
        },
        drawerLanguageSelectorWrapper: {
            '& button': {
                padding: 0,
            },
            '& .MuiTypography-root': {
                fontSize: '1.4rem',
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            },
        },
        drawerLogoWrapper: {
            width: 105,
            '& img': {
                maxWidth: '100%',
            },
        },
        navigation: {
            flexGrow: 1,
        },
        clsButton: {
            width: 20,
            height: 20,
            padding: 0,
            '& .MuiSvgIcon-root': {
                fontSize: 20,
            },
        },
        linksWrapper: {
            flexDirection: 'column',
            gap: theme.spacing(3),
            padding: `${theme.spacing(3)} 0`,
            borderTop: `1px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            '& li': {
                textAlign: 'center',
            },
            '& a': {
                fontWeight: theme.typography.fontWeightRegular,
            },
        },
    };
});
