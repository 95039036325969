import React, { ReactElement, CSSProperties, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Typography, useTheme } from '@mui/material';
import { THEME_COLORS } from 'src/theming/theme';
import { ImagePreview } from 'src/shared/types';
import { useRemoteImage } from 'src/shared/hooks';

import { NoItemPreview } from '../../../ProductCard';

import {
    useStaticBodyContentStyles,
    useStaticBodyContentDescrItemStyles,
} from './StaticBodyContentStyles';
import { ItemRefundDto } from 'src/pages';

const defaultImageSrc = '/static/images/no-item-preview.svg';

interface StaticBodyContentDescrItemProps<M> {
    title: string;
    value: string | number | TFunctionResult;
    valueStyles?: CSSProperties;
    InputField?: FC<{ item: M }>;
    itemModel?: M;
}

function StaticBodyContentDescrItem<M>({
    title,
    value,
    valueStyles,
    InputField,
    itemModel,
}: StaticBodyContentDescrItemProps<M>): ReactElement {
    const classes = useStaticBodyContentDescrItemStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <Typography
                variant='subtitle1'
                style={{ color: THEME_COLORS.SUBTITLE_COLOR }}
            >
                {t(title)}
            </Typography>
            {!InputField && (
                <Typography variant='body1' style={valueStyles}>
                    {value}
                </Typography>
            )}
            {InputField && <InputField item={itemModel} />}
        </div>
    );
}

interface StaticBodyContentProps<M> {
    name: string;
    price: number;
    amount: number;
    color: string;
    size: string | number;
    reserved: boolean;
    localOnly: boolean;
    isNewGood: boolean;
    AmountInputField?: FC<{ item: M }>;
    injectedMediaContainerClass?: string;
    hideAmount?: boolean;
    hideLabels?: boolean;
    previewImage?: ImagePreview;
    itemId?: string;
    imageLink?: string;
    itemModel?: M;
    disableImageInteractivity?: boolean;
    refund?: ItemRefundDto;
}

export function StaticBodyContent<M>({
    itemId,
    name,
    price,
    amount,
    color,
    size,
    reserved,
    localOnly,
    isNewGood,
    AmountInputField,
    injectedMediaContainerClass,
    hideAmount,
    previewImage,
    hideLabels,
    imageLink,
    disableImageInteractivity,
    itemModel,
    refund,
}: StaticBodyContentProps<M>): ReactElement {
    const classes = useStaticBodyContentStyles();
    const theme = useTheme();
    const { t } = useTranslation(['extendedProductCard', 'formatted-values']);

    const [src] = useRemoteImage(
        itemId && previewImage?.id
            ? `web-shop/items/${itemId}/attachments/${previewImage.id}`
            : null
    );

    const imageSrc = useMemo(() => src ?? defaultImageSrc, [src]);

    return (
        <div className={classes.staticBodyContent}>
            {(!imageLink || disableImageInteractivity) && (
                <div
                    className={clsx(classes.mediaContainer, injectedMediaContainerClass)}
                >
                    {imageSrc === defaultImageSrc && <NoItemPreview />}
                    {imageSrc && imageSrc !== defaultImageSrc && (
                        <img src={imageSrc} alt='Preview' />
                    )}
                    {!hideLabels && (
                        <>
                            {localOnly && (
                                <div className={classes.topLabelsContainer}>
                                    <div className={classes.isNewGood}>
                                        {t('extendedProductCard:labels.localOnly')}
                                    </div>
                                </div>
                            )}

                            {(isNewGood || reserved) && (
                                <div className={classes.labelsContainer}>
                                    {isNewGood && (
                                        <div className={classes.isNewGood}>
                                            {t('extendedProductCard:labels.isNewGood')}
                                        </div>
                                    )}
                                    {reserved && (
                                        <div className={classes.reservedLabel}>
                                            {t('extendedProductCard:labels.reserved')}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            {imageLink && !disableImageInteractivity && (
                <Link
                    to={imageLink}
                    className={clsx(
                        classes.mediaContainer,
                        classes.interactiveMediaContainer,
                        injectedMediaContainerClass
                    )}
                >
                    {imageSrc === defaultImageSrc && <NoItemPreview />}
                    {imageSrc && imageSrc !== defaultImageSrc && (
                        <img src={imageSrc} alt='Preview' />
                    )}
                    {!hideLabels && (
                        <>
                            {localOnly && (
                                <div className={classes.topLabelsContainer}>
                                    <div className={classes.isNewGood}>
                                        {t('extendedProductCard:labels.localOnly')}
                                    </div>
                                </div>
                            )}

                            {(isNewGood || reserved) && (
                                <div className={classes.labelsContainer}>
                                    {isNewGood && (
                                        <div className={classes.isNewGood}>
                                            {t('extendedProductCard:labels.isNewGood')}
                                        </div>
                                    )}
                                    {reserved && (
                                        <div className={classes.reservedLabel}>
                                            {t('extendedProductCard:labels.reserved')}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Link>
            )}
            <div className={classes.descriptionContainer}>
                <Typography variant='h3' className={classes.itemName}>
                    {name}
                </Typography>

                {refund && (
                    <>
                        <StaticBodyContentDescrItem
                            title='extendedProductCard:refundStatus'
                            value={`${t('extendedProductCard:refundAmount')}: ${
                                refund.amount
                            }, ${t(
                                `extendedProductCard:refundStatuses:${refund.status}`
                            )}`}
                            valueStyles={{ fontWeight: theme.typography.fontWeightBold }}
                        />
                    </>
                )}

                <StaticBodyContentDescrItem
                    title='extendedProductCard:price'
                    value={t('formatted-values:formattedCurrency', { currency: price })}
                    valueStyles={{ fontWeight: theme.typography.fontWeightBold }}
                />
                <div className={classes.horizontalPlacement}>
                    {!hideAmount && (
                        <StaticBodyContentDescrItem<M>
                            title='extendedProductCard:amount'
                            value={amount}
                            InputField={AmountInputField}
                            itemModel={itemModel}
                        />
                    )}
                    {color != null ?? (
                        <StaticBodyContentDescrItem
                            title='extendedProductCard:color'
                            value={color}
                        />
                    )}
                    {size != null ?? (
                        <StaticBodyContentDescrItem
                            title='extendedProductCard:size'
                            value={size}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
