import React, { ReactElement, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { withBadge } from 'src/shared/hocs';

import { useShortcutNavStyles } from './ShortCutNavStyles';

interface IconProps {
    width?: number;
    height?: number;
}

interface ShortCutNavItem {
    Icon: FC<IconProps>;
    path: string;
    ariaLabel?: string;
    tooltipTitle?: string;
    langNs?: string[];
}

export const ShortCutNavItem = withBadge(function ({
    Icon,
    path,
    langNs,
    ariaLabel,
    tooltipTitle,
}: ShortCutNavItem): ReactElement {
    const classes = useShortcutNavStyles();
    const navigate = useNavigate();
    return (
        <IconButtonWithTooltip
            needTooltip
            wrapperStyles={{ display: 'flex', alignItems: 'center' }}
            tooltipTitle={tooltipTitle}
            langNs={langNs}
            buttonClassName={classes.button}
            onClick={() => navigate(path)}
            aria-label={ariaLabel}
            Icon={Icon}
        />
    );
});
