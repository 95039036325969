import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { useStore } from 'src/mst/StoreProvider';
import { CustomAutocomplete } from 'src/components/fields';

import { useSizeFilterStyles } from './SizeFilterStyles';
import { SelectableItem } from 'src/shared/types';

interface SizeFilterProps {
    label: string;
    id: string;
    name: string;
    languageNamespaces: string[];
    placeholder?: string;
    popperMinWidth?: number;
}

export const SizeFilter = observer(
    ({
        name,
        languageNamespaces,
        label,
        placeholder,
        popperMinWidth,
    }: SizeFilterProps): ReactElement => {
        const classes = useSizeFilterStyles();

        const { menuId, subCategoryId } = useParams();

        const { productsList, selectionValues } = useStore();

        const items: SelectableItem[] | null =
            selectionValues.getSelectionItemsByName(name);

        useEffect(() => {
            selectionValues.fetchSizeSelectionValues(name, menuId, subCategoryId);
        }, [name, menuId, subCategoryId]);

        const currentValues = productsList
            .getFilterValues(name)
            .map((item) => item.value);

        const handleChange = (name: string, values: string[]): void => {
            if (Array.isArray(items) && items.length > 0) {
                const mappedValues = values.map((value) => {
                    const foundValue = items.find((item) => item.value === value);
                    return {
                        filter: name,
                        titles: [() => foundValue?.title || ''] || [],
                        value: foundValue?.value || '',
                    };
                });
                productsList.setFilterValues(name, mappedValues);
            }
        };

        return (
            <div className={classes.filterContainer}>
                <CustomAutocomplete
                    multiple
                    tLabelKey={label}
                    nameSpaces={languageNamespaces}
                    name={name}
                    tPlaceholderKey={placeholder}
                    items={items ?? []}
                    value={currentValues}
                    handleChange={handleChange}
                    popperMinWidth={popperMinWidth}
                />
            </div>
        );
    }
);
