import React, { ReactElement } from 'react';

import { IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

const useCloseBtnStyles = makeStyles((theme: Theme) => {
    return {
        btn: {
            width: 20,
            height: 20,
            backgroundColor: theme.palette.primary.contrastText,
            transition: '0.2s',
            '&:hover': {
                backgroundColor: theme.palette.primary.contrastText,
                opacity: 0.8,
                transform: 'rotate(180deg)',
            },
        },
        btnIcon: {
            width: 12,
            height: 12,
            color: theme.palette.primary.main,
        },
    };
});

interface CloseBtnProps {
    onClick: () => void;
}

export const CloseBtn = ({ onClick }: CloseBtnProps): ReactElement => {
    const classes = useCloseBtnStyles();
    return (
        <IconButton className={classes.btn} onClick={onClick}>
            <CloseIcon className={classes.btnIcon} />
        </IconButton>
    );
};
