import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { THEME_COLORS } from 'src/theming/theme';

export const useFormControlStyles = makeStyles(() => {
    return {
        root: {
            width: '100%',
            '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: 10,
                borderColor: THEME_COLORS.MAIN_BORDER_COLOR,
            },
            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: THEME_COLORS.LIGHT_BORDER_COLOR,
                color: THEME_COLORS.LIGHT_BORDER_COLOR,
            },
            '& .MuiInputLabel-root.Mui-disabled, .MuiInputBase-input.Mui-disabled': {
                color: THEME_COLORS.LIGHT_BORDER_COLOR,
            },
            '& fieldset': {
                borderWidth: 2,
            },
        },
    };
});

export const useLabelStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            transform: 'translate(14px, 10px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -6px) scale(0.75)',
            },
            '& .MuiInputLabel-asterisk': {
                color: theme.palette.error.main,
            },
        },
    };
});

export const useFormHelperText = makeStyles((theme: Theme) => {
    return {
        root: {
            fontSize: '1.2rem',
        },
        error: {
            color: theme.palette.error.main,
        },
    };
});
