import { makeStyles } from '@mui/styles';

export const useShimmingLoaderStyles = makeStyles(() => {
    return {
        '@keyframes shimming': {
            '0%': {
                backgroundPosition: '-800px 0',
            },
            '100%': {
                backgroundPosition: '800px 0',
            },
        },
        container: {
            width: '100%',
            height: '100%',
            animationName: '$shimming',
            animationDuration: '2s',
            animationFillMode: 'forwards',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            backgroundColor: '#f6f7f8',
            background: 'linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%)',
            backgroundSize: '800px 104px',
        },
    };
});
