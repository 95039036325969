import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useTabsStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            minHeight: 'auto',
        },
        flexContainer: {
            gap: theme.spacing(5),
        },
        indicator: {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
        },
    };
});

export const useTabStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            minHeight: 'auto',
            textTransform: 'none',
            fontSize: '1.8rem',
            padding: `${theme.spacing(1)} 0`,
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.6rem',
            },
        },
    };
});
