import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useShortcutNavStyles = makeStyles((theme: Theme) => {
    return {
        shotCutNav: {
            display: 'flex',
            gap: 5,
            padding: 10,
            maxWidth: 150,
        },
        button: {
            '& .MuiSvgIcon-root': {
                fontSize: 30,
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.down('lg')]: {
                    fontSize: 25,
                },
            },
        },
    };
});
