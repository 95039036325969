import React, { Fragment, CSSProperties, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from '../custom-hooks/use-debounce';
import { Item } from './item';
import { useHttpClient } from '../http-client/use-http-client';
import { PasswordStrengthResult } from '.';

export interface PasswordStrengthBarProps {
    className?: string;
    style?: React.CSSProperties;
    scoreWordClassName?: string;
    password: string;
    barColors?: string[];
    minLength?: number;
    onChangeScore?: (score: number) => void;
    onStrong?: (isStrong: boolean) => void;
    pathToCheckPassword: string;
}

const rootStyle: CSSProperties = {
    position: 'relative',
};

const wrapStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0 0',
};

const spaceStyle: CSSProperties = {
    width: 4,
};

const PasswordStrengthBar = (props: PasswordStrengthBarProps): JSX.Element => {
    const { password, onStrong, pathToCheckPassword } = props;

    const { t } = useTranslation(['password']);

    const [score, setScore] = useState(0);
    const [suggestions, setSuggestions] = useState<string[]>([]);

    const debouncedPassword = useDebounce(password);

    const httpClient = useHttpClient();

    const handleScore = useCallback(async (): Promise<void> => {
        if (debouncedPassword?.length > 0) {
            try {
                const { isStrong, score, feedback } =
                    await httpClient.post<PasswordStrengthResult>(pathToCheckPassword, {
                        password: debouncedPassword,
                    });
                const { suggestions } = feedback || {};
                setScore(score);
                setSuggestions(suggestions);
                onStrong && onStrong(isStrong);
            } catch (err) {
                setScore(1);
                setSuggestions([]);
                onStrong && onStrong(false);
            }
        } else if (debouncedPassword?.length === 0 || !debouncedPassword) {
            setScore(0);
            setSuggestions([]);
            onStrong && onStrong(false);
        }
    }, [httpClient, debouncedPassword, onStrong, pathToCheckPassword]);

    useEffect(() => {
        handleScore();
    }, [debouncedPassword, handleScore]);

    return (
        <div className={props.className} style={{ ...rootStyle, ...props.style }}>
            <div style={wrapStyle}>
                {[1, 2, 3, 4, 5].map((el: number) => {
                    return (
                        <Fragment key={`password-strength-bar-item-${el}`}>
                            {el > 1 && <div style={spaceStyle} />}
                            <Item
                                score={score}
                                itemNum={el}
                                barColors={props.barColors}
                            />
                        </Fragment>
                    );
                })}
            </div>
            {suggestions?.map((suggestion) => (
                <li
                    key={suggestion}
                    style={{ margin: '10px 10px 0 10px' }}
                    className='fileListDefaultStyle'
                >
                    {t(`password:${suggestion}`)}
                </li>
            ))}
        </div>
    );
};

PasswordStrengthBar.defaultProps = {
    barColors: ['#ddd', '#EF4836', '#F68A4D', '#F6E54D', '#8DEF2B', '#089D5F'],
};

export default PasswordStrengthBar;
