import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
    getEnv,
    getRoot,
    flow,
} from 'mobx-state-tree';
import { generatePath } from 'react-router-dom';

import { storageKeys } from 'src/shared/constants';
import { WishListDto } from 'src/shared/types';
import { AppRoutes } from 'src/routing/appRoutes';

import { WishListItem, IWishListItemSnapshotOut } from './wishListItem';
import { WishListFormattedItem } from './types';

export const WishList = types
    .model('WishList', {
        list: types.maybeNull(types.array(WishListItem)),
    })
    .actions((self) => {
        let initialState: IWishListSnapshotOut;

        const {
            env: { storageService, httpClient },
        } = getEnv(self);

        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },

            resetStore(): void {
                applySnapshot(self, initialState);
            },
            clearWishList() {
                applySnapshot(self, { ...self, list: [] });
            },
            updateFavoriteItemsInStorage(): void {
                storageService.setItem(storageKeys.favoriteItems, [...self.list]);
            },
            updateUserWishList: flow(function* () {
                const wishListFromStorage = JSON.parse(
                    storageService.getItem(storageKeys.favoriteItems)
                );
                if (Array.isArray(wishListFromStorage) && wishListFromStorage.length) {
                    yield httpClient.post('web-shop/items/bookmarks', {
                        itemIds: wishListFromStorage.map((item) => item.itemId),
                    });
                    storageService.removeItem(storageKeys.favoriteItems);
                }
            }),

            fetchWishListItemsDataByIds: flow(function* (itemIds: string[]) {
                if (Array.isArray(itemIds) && itemIds.length) {
                    try {
                        const data: WishListDto = yield httpClient.post(
                            'web-shop/bookmarks/get-items',
                            {
                                itemIds,
                            }
                        );
                        applySnapshot(self, { ...self, list: data.bookmarkedItems });
                    } catch {
                        applySnapshot(self, { ...self, list: [] });
                    }
                }
            }),
        };
    })
    .actions((self) => {
        const { authentication } = getRoot(self);

        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchWishListItemIds: flow(function* () {
                const response: { itemIds: string[] } = yield httpClient.get(
                    'web-shop/bookmarks'
                );
                if (Array.isArray(response.itemIds) && response.itemIds.length) {
                    self.fetchWishListItemsDataByIds(response.itemIds);
                }
            }),
            addItemToFavoriteList: flow(function* (id: string) {
                const newFavoriteItems = [...self.list, { itemId: id }];
                applySnapshot(self, { ...self, list: newFavoriteItems });
                if (authentication.isAuth) {
                    yield httpClient.post(`web-shop/items/${id}/bookmarks`);
                } else {
                    self.updateFavoriteItemsInStorage();
                }
                self.fetchWishListItemsDataByIds(self.list.map((item) => item.itemId));
            }),
            removeItemFromFavorite: flow(function* (id: string) {
                const newList = self.list.filter((item) => item.itemId !== id);
                applySnapshot(self, {
                    ...self,
                    list: newList,
                });
                if (authentication.isAuth) {
                    yield httpClient.delete(`web-shop/items/${id}/bookmarks`);
                } else {
                    self.updateFavoriteItemsInStorage();
                }
            }),
        };
    })
    .views((self) => {
        const { bookingPurchaseList, bookingReservationList } = getRoot(self);
        return {
            isFavorite(id: string): boolean {
                return self.list.some((item) => item.itemId === id);
            },
            get doesFavoriteItemsExist(): boolean {
                return Boolean(self.list.length);
            },
            get amountOfFavorites(): number {
                return self.list?.length || 0;
            },
            get formattedReservationList(): WishListFormattedItem[] {
                const shops: Set<string> = new Set<string>(
                    self.list?.map((item) => item.shopId)
                );
                const formattedReservationList: WishListFormattedItem[] = Array.from(
                    shops
                ).map((shop, index) => {
                    const itemsByShop = self.list?.filter((item) => item.shopId === shop);

                    return {
                        id: `${index}`,
                        shop: itemsByShop[0].shopName,
                        shopId: itemsByShop[0].shopId,
                        location: itemsByShop[0].location,
                        workHours: itemsByShop[0].workhours,
                        freeShippingPrice: itemsByShop[0].freeOfShippingCosts,
                        items: itemsByShop.map((item: IWishListItemSnapshotOut) => {
                            return {
                                id: item.itemId,
                                reserved: item.reserved,
                                adddedToCart: bookingPurchaseList.isItemInPurchaseList(
                                    item.itemId
                                ),
                                addedToBookingList: bookingReservationList.isReservedById(
                                    item.itemId
                                ),
                                localOnly: !item.shippingAvailable,
                                isNewGood: item.isNewGood,
                                shippingAvailable: item.shippingAvailable,
                                name: item.name,
                                price: item.price,
                                amount: item.amount,
                                color: item.color,
                                size: item.size,
                                stock: item.amount,
                                previewImage: item.previewImage,
                                freeShippingPrice: item.freeOfShippingCosts,
                                imageLink: generatePath(AppRoutes.goodsItem, {
                                    itemId: item.itemId,
                                }),
                            };
                        }),
                    };
                });
                return formattedReservationList;
            },
        };
    });

export type WishListModel = Instance<typeof WishList>;
export type IWishListSnapshotOut = SnapshotOut<typeof WishList>;
