import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useGoodsListItemsStyles = makeStyles((theme: Theme) => {
    return {
        listContainer: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginLeft: `-${theme.spacing(2)}`,
            marginRight: `-${theme.spacing(2)}`,
            gap: theme.spacing(1),
            '& .infinite-scroll-component__outerdiv': {
                width: '100%',
            },
        },
        listItemContainer: {
            width: '24%',
            [getMediaSelectorKey('max-width: 860px')]: {
                width: '32%',
            },
            [getMediaSelectorKey('max-width: 700px')]: {
                width: '40%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '49%',
            },
            [theme.breakpoints.down('ml')]: {
                width: '100%',
            },
        },
    };
});
