import { FC, ComponentType, LazyExoticComponent, lazy } from 'react';
import { Location } from 'react-router-dom';
import { BaseLayout } from 'src/components/layouts';
import { AppRoutes } from './appRoutes';

export interface RouteItem {
    path: string;
    component: LazyExoticComponent<ComponentType> | FC<{ prevLocation?: Location }>;
    roles?: string[];
    private?: boolean;
    children?: RouteItem[];
    index?: boolean;
}

const HomePage = lazy(() =>
    import('src/pages').then(({ HomePage }) => ({
        default: HomePage,
    }))
);

const CookiePolicyPage = lazy(() =>
    import('src/pages').then(({ CookiePolicy }) => ({
        default: CookiePolicy,
    }))
);

const PersonalInfoPage = lazy(() =>
    import('src/pages').then(({ PersonalInfoPage }) => ({
        default: PersonalInfoPage,
    }))
);

const ItemsHistoryPage = lazy(() =>
    import('src/pages').then(({ ItemsHistoryPage }) => ({
        default: ItemsHistoryPage,
    }))
);

const WishlistPage = lazy(() =>
    import('src/pages').then(({ WishlistPage }) => ({
        default: WishlistPage,
    }))
);

const CheckoutWithDetailsPage = lazy(() =>
    import('src/pages').then(({ CheckoutWithDetailsPage }) => ({
        default: CheckoutWithDetailsPage,
    }))
);

const GoodsListPage = lazy(() =>
    import('src/pages').then(({ GoodsListPage }) => ({
        default: GoodsListPage,
    }))
);

const GoodsItemPage = lazy(() =>
    import('src/pages').then(({ GoodsItemPage }) => ({
        default: GoodsItemPage,
    }))
);

const ShopsPage = lazy(() =>
    import('src/pages').then(({ ShopsPage }) => ({
        default: ShopsPage,
    }))
);

const ShopPage = lazy(() =>
    import('src/pages').then(({ ShopPage }) => ({
        default: ShopPage,
    }))
);

const BookingPage = lazy(() =>
    import('src/pages').then(({ BookingPage }) => ({
        default: BookingPage,
    }))
);

const ThanksForPurchasePage = lazy(() =>
    import('src/pages').then(({ ThanksForPurchasePage }) => ({
        default: ThanksForPurchasePage,
    }))
);

const ImprintPage = lazy(() =>
    import('src/pages').then(({ ImprintPage }) => ({
        default: ImprintPage,
    }))
);

const PrivacyPage = lazy(() =>
    import('src/pages').then(({ PrivacyPage }) => ({
        default: PrivacyPage,
    }))
);

const NoRoutePage = lazy(() =>
    import('src/pages').then(({ NoRoutePage }) => ({
        default: NoRoutePage,
    }))
);

const routingConfig: RouteItem[] = [
    {
        path: AppRoutes.home,
        component: BaseLayout,
        children: [
            {
                path: AppRoutes.home,
                component: HomePage,
            },
            {
                path: AppRoutes.cookiePolicy,
                component: CookiePolicyPage,
            },
            {
                private: true,
                path: AppRoutes.personalInfo,
                component: PersonalInfoPage,
            },
            {
                private: true,
                path: AppRoutes.itemsHistory,
                component: ItemsHistoryPage,
            },
            {
                path: AppRoutes.wishlist,
                component: WishlistPage,
            },
            {
                path: AppRoutes.checkoutWithDetails,
                component: CheckoutWithDetailsPage,
            },
            {
                path: AppRoutes.allGoods,
                component: GoodsListPage,
            },
            {
                path: AppRoutes.goodsListMenu,
                component: GoodsListPage,
            },
            {
                path: AppRoutes.goodsListCategory,
                component: GoodsListPage,
            },
            {
                path: AppRoutes.goodsListSubCategory,
                component: GoodsListPage,
            },
            {
                path: AppRoutes.goodsItem,
                component: GoodsItemPage,
            },
            {
                path: AppRoutes.shops,
                component: ShopsPage,
            },
            {
                path: AppRoutes.shop,
                component: ShopPage,
            },
            {
                path: AppRoutes.booking,
                component: BookingPage,
            },
            {
                private: true,
                path: AppRoutes.thanksForPurchase,
                component: ThanksForPurchasePage,
            },
            {
                path: AppRoutes.imprint,
                component: ImprintPage,
            },
            {
                path: AppRoutes.privacy,
                component: PrivacyPage,
            },
        ],
    },
    {
        path: '*',
        component: NoRoutePage,
    },
];

export default routingConfig;
