import React, { ReactElement, memo, BaseSyntheticEvent, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import { OutlinedInput, InputBaseComponentProps } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';

import { useInputBaseStyles } from './TextFieldStyles';

export interface TextFieldProps<T> {
    id: string;
    placeholder?: string;
    label?: string;
    inputProps?: T | InputBaseComponentProps;
    color?: OverridableStringUnion<
        'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    >;
    translationNamespaces?: string[];
    helperText?: string;
    className?: string;
    name?: string;
    value?: string;
    onChange?: (name: string, value: string) => void;
    error?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    required?: boolean;
    disabled?: boolean;
    startAdornment?: ReactElement;
    inputComponent?: ComponentType;
}

const BaseTextField = function <T>({
    inputProps,
    id,
    color,
    translationNamespaces,
    placeholder,
    name,
    value,
    onChange,
    disabled,
    label,
    ...rest
}: TextFieldProps<T>): ReactElement {
    const inputBaseClasses = useInputBaseStyles();

    const { t } = useTranslation(translationNamespaces);

    const handleChange = (e: BaseSyntheticEvent): void => {
        if (typeof onChange === 'function' && name) {
            onChange(name, e.target.value);
        }
    };

    return (
        <OutlinedInput
            id={id}
            color={color}
            classes={inputBaseClasses}
            inputProps={inputProps}
            placeholder={placeholder ? t(placeholder) : ''}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            label={label ? t(label) : ''}
            {...rest}
        />
    );
};

export const TextField = memo(BaseTextField) as typeof BaseTextField;
