import React, { ReactElement } from 'react';
import clsx from 'clsx';

import { useShopDetailsLayoutStyles } from './ShopDetailsLayouStyles';

interface ShopDetailsLayoutProps {
    children: ReactElement[];
}

export const ShopDetailsLayout = ({ children }: ShopDetailsLayoutProps): ReactElement => {
    const classes = useShopDetailsLayoutStyles();
    return (
        <div className={classes.container}>
            {children[0] && (
                <div className={clsx(classes.fullWidth, classes.breadcrumps)}>
                    {children[0]}
                </div>
            )}
            {children[1] && (
                <div className={clsx(classes.fullWidth, classes.banner)}>
                    {children[1]}
                </div>
            )}
            {children[2] && (
                <div className={clsx(classes.fullWidth, classes.info)}>{children[2]}</div>
            )}
            {children[3] && (
                <div className={classes.browseShopContainer}>{children[3]}</div>
            )}
            <div className={clsx(classes.fullWidth, classes.infoAndLinksContainer)}>
                {children[4] && <div className={classes.mainInfo}>{children[4]}</div>}
                {children[5] && <div className={classes.socials}>{children[5]}</div>}
            </div>
            {/* Map is not for version 1 */}
            {/* {children[6] && <div className={classes.map}>{children[5]}</div>} */}
            {children[7] && <div className={classes.additionalInfo}>{children[7]}</div>}
            {/* May like this is not for version 1 */}
            {/* {children[8] && <div className={classes.mayLikeProducts}>{children[8]}</div>} */}
        </div>
    );
};
