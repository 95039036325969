import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';

export const useToggableGroupFilterStyles = makeStyles(() => {
    return {
        root: {
            width: '100%',
            height: 40,
        },
    };
});

export const useToggableBtnFilterStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            width: '100%',
            justifyContent: 'space-between',
            border: `2px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
            borderRadius: 10,
            color: 'rgba(0, 0, 0, 0.6)',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '1.4rem',
            textTransform: 'none',
            '& .MuiSvgIcon-root': {
                fontSize: '2rem',
                color: theme.palette.secondary.contrastText,
            },
        },
        selected: {
            backgroundColor: `${theme.palette.secondary.main}!important`,
            color: `${theme.palette.secondary.contrastText}!important`,
            border: 'none',
        },
    };
});
