import React, { ReactElement, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues, NumberFormatProps } from 'react-number-format';

import { TextField } from 'src/components/fields';

interface CurrencyFieldProps {
    id: string;
    name: string;
    value: number | null;
    onChange: (name: string, value: number | null) => void;
    tLabelKey: string;
    languageNamespaces: string[];
    disabled?: boolean;
    tPlaceholderKey?: string;
    blurCb?: (name: string) => void;
}

const NumberFormatCustom = forwardRef<NumberFormat<NumberFormatProps>, NumberFormatProps>(
    function NumberFormatCustom(props: NumberFormatProps, ref) {
        return <NumberFormat getInputRef={ref} {...props} />;
    }
);

export const CurrencyField = ({
    id,
    name,
    disabled,
    tPlaceholderKey,
    tLabelKey,
    value,
    onChange,
    blurCb,
    languageNamespaces,
}: CurrencyFieldProps): ReactElement => {
    const { t } = useTranslation(languageNamespaces);
    const [isFocused, setFocused] = useState<boolean>(false);

    const onFocus = (): void => {
        setFocused(true);
    };

    const onBlur = (): void => {
        setFocused(false);
        if (typeof blurCb === 'function') {
            blurCb(name);
        }
    };

    const handleChangeFormattedValue = (values: NumberFormatValues): void => {
        if (typeof onChange === 'function') {
            if (typeof values.floatValue === 'number') {
                onChange(name, values.floatValue);
            } else {
                onChange(name, null);
            }
        }
    };

    return (
        <TextField
            id={id}
            inputComponent={NumberFormatCustom}
            inputProps={{
                name,
                value,
                disabled,
                isNumericString: true,
                fixedDecimalScale: !isFocused,
                allowLeadingZeros: false,
                allowNegative: false,
                autoComplete: 'off',
                thousandSeparator: '.',
                decimalSeparator: ',',
                placeholder: tPlaceholderKey ? t(tPlaceholderKey) : '',
                decimalScale: 2,
                suffix: '€',
                onValueChange: handleChangeFormattedValue,
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            label={tLabelKey}
            translationNamespaces={languageNamespaces}
        />
    );
};

CurrencyField.defaultProps = {
    disabled: false,
    tPlaceholderKey: '',
    tLabelKey: '',
};
