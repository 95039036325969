import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { centerAlignByFlex } from 'src/theming/mixins';

import { THEME_COLORS } from 'src/theming/theme';

export const useGoodsListPaginationStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            height: '100%',
            ...centerAlignByFlex(),
            '& .MuiButtonBase-root': {
                color: THEME_COLORS.SUBTITLE_COLOR,
                fontWeight: theme.typography.fontWeightBold,
                '&.Mui-selected': {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.secondary.contrastText,
                },
            },
        },
        pageSelectorContainer: {
            marginLeft: theme.spacing(5),
            '& .MuiSelect-select': {
                height: 'auto!important',
                padding: 0,
                fontSize: '1.6rem',
                fontWeight: theme.typography.fontWeightBold,
                color: THEME_COLORS.SUBTITLE_COLOR,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 0,
            },
            '& .MuiSvgIcon-root': {
                fontSize: '2.4rem',
            },
        },
    };
});
