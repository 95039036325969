import React, { ReactElement, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import { Button, Grid } from '@mui/material';

import { BaseInputWrapper } from 'src/components/fields';
import { useStore } from 'src/mst/StoreProvider';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import PasswordStrengthBar from 'src/lib/passwordStrengthBar/PasswordStrengthBar';
import { AuthModuleSections } from 'src/authentication/constants';
import { useSelectionValues, SELECTION_VALUE_OPTIONS } from 'src/shared/hooks';
import { SelectableItem } from 'src/shared/types';
import { Loader } from 'src/components/base';

import { useSignUpFormConfig } from './signUpFormConfig';
import { SignUpFormType } from './SignUpFormType';
import { signUpFormInitialValues } from './signUpFormInitialValues';
import { SignUpFormItem } from './SignUpFormItem';
import { signUpValidationSchema } from './signUpValidationSchema';
import { useSignUpFormStyles } from './SignUpFormStyles';

import { SignUpFormFieldNames } from './SignUpFormFieldNames';

const langNs = ['common', 'authentication', 'fieldErrors', 'serverErrors'];

const countryFormatter = (item: { label: string; value: string }): SelectableItem => {
    return { title: item.label, value: item.value };
};

interface LoginError {
    error: {
        response: {
            data: {
                errors: {
                    email?: string[] | undefined;
                };
            };
        };
    };
}

export const SignUpForm = (): ReactElement => {
    const classes = useSignUpFormStyles();
    const { authentication } = useStore();
    const { t } = useTranslation(langNs);

    const [isStrong, setStrong] = useState<boolean>(false);

    const { mutateAsync, isLoading } = useMutation(authentication.signUp, {
        onSuccess: () => {
            toast.success(t('successSigningUpMessage', { ns: 'authentication' }));
            authentication.setAuthModule(AuthModuleSections.signIn);
        },
        onError: (error: LoginError) => {
            if (error.error.response.data.errors.email) {
                toast.error(t('alreadyRegistered', { ns: 'serverErrors' }));
            } else {
                toast.error(t('unknownError', { ns: 'serverErrors' }));
            }
        },
    });

    const {
        values,
        setFieldValue,
        setFieldTouched,
        isValid,
        dirty,
        errors,
        touched,
        handleSubmit,
    } = useFormik<SignUpFormType>({
        initialValues: signUpFormInitialValues,
        onSubmit: (values) => {
            mutateAsync(values);
        },
        validationSchema: signUpValidationSchema,
    });

    const debouncedPassword = useDebounce(values.password);

    const handleSetStrong = useCallback((value: boolean): void => {
        setStrong(value);
    }, []);

    const onChange = useCallback(
        (name: keyof SignUpFormType, value: string | string[] | number): void => {
            setFieldTouched(name, true);
            setFieldValue(name, value);
        },
        []
    );

    const goToLogin = (): void => {
        authentication.setAuthModule(AuthModuleSections.signIn);
    };

    const { data: countries } = useSelectionValues(SELECTION_VALUE_OPTIONS.COUNTRY);
    const formatterCountries = useMemo(() => {
        if (Array.isArray(countries)) {
            return countries.map(countryFormatter);
        }
        return [];
    }, [countries]);

    const signUpFormConfig = useSignUpFormConfig({ countries: formatterCountries });

    return (
        <Grid
            noValidate
            container
            spacing={3}
            component='form'
            onSubmit={handleSubmit}
            className={classes.formContainer}
            autoComplete='off'
        >
            {signUpFormConfig.map(
                ({ id, label, name, required, columnSize, ...rest }) => {
                    return (
                        <Grid
                            item
                            key={id}
                            sm={columnSize}
                            className={clsx({
                                [classes.phoneNumberWrapper]:
                                    name === SignUpFormFieldNames.phoneNumber,
                            })}
                        >
                            <BaseInputWrapper
                                id={id}
                                label={label}
                                required={required}
                                error={touched[name] ? Boolean(errors[name]) : false}
                                helperText={
                                    touched[name] && errors[name]
                                        ? `fieldErrors:${errors[name]}`
                                        : ''
                                }
                                languageNamespaces={langNs}
                            >
                                <>
                                    <SignUpFormItem
                                        id={id}
                                        label={label}
                                        value={values[name]}
                                        name={name}
                                        onChange={onChange}
                                        required={required}
                                        langNs={langNs}
                                        {...rest}
                                    />
                                </>
                            </BaseInputWrapper>

                            {name === SignUpFormFieldNames.password && (
                                <PasswordStrengthBar
                                    password={debouncedPassword}
                                    onStrong={handleSetStrong}
                                    pathToCheckPassword='users/check-password-strength'
                                />
                            )}
                        </Grid>
                    );
                }
            )}
            <Grid
                item
                container
                sm={12}
                className={classes.actionsContainer}
                justifyContent='space-between'
                alignItems='center'
            >
                <Button
                    disableRipple
                    color='secondary'
                    onClick={goToLogin}
                    className={classes.backToLogin}
                >
                    {t('authentication:backToSignIn')}
                </Button>
                <Button
                    disabled={!dirty || !isValid || !isStrong || isLoading}
                    type='submit'
                    color='secondary'
                    variant='contained'
                    className={classes.signUp}
                    startIcon={isLoading && <Loader />}
                >
                    {t('authentication:signUp')}
                </Button>
            </Grid>
        </Grid>
    );
};
