import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
} from 'mobx-state-tree';

import { ImagePreviewMst } from 'src/mst/customTypes';

export const WishListItem = types
    .model('WishListItem', {
        id: types.maybeNull(types.string),
        shopName: types.maybeNull(types.string),
        location: types.maybeNull(types.string),
        workhours: types.maybeNull(types.string),
        amount: types.maybeNull(types.number),
        color: types.maybeNull(types.string),
        size: types.maybeNull(types.string),
        shopId: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        price: types.maybeNull(types.number),
        previewImage: types.maybeNull(ImagePreviewMst),
        shippingAvailable: types.maybeNull(types.boolean),
        shippingCosts: types.maybeNull(types.number),
        freeOfShippingCosts: types.maybeNull(types.number),
        isNewGood: types.maybeNull(types.boolean),
        reserved: types.maybeNull(types.boolean),
        sold: types.maybeNull(types.boolean),
        itemId: types.maybeNull(types.string),
    })
    .actions((self) => {
        let initialState: IWishListItemSnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore(): void {
                applySnapshot(self, initialState);
            },
        };
    });

export type WishListItemModel = Instance<typeof WishListItem>;
export type IWishListItemSnapshotOut = SnapshotOut<typeof WishListItem>;
