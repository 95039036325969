import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
    flow,
    getEnv,
    getRoot,
} from 'mobx-state-tree';
import qs from 'query-string';

import { SelectionValuesItem } from 'src/mst/customTypes';
import { SelectionValuesDto, SelectableItem } from 'src/shared/types';
import { SelectionValues as SelectionValuesEnum } from 'src/shared/constants';
import { StoreModel } from '../root';

export const SelectionValues = types
    .model('SelectionValuesModel', {
        [SelectionValuesEnum.color]: types.maybeNull(types.array(SelectionValuesItem)),
        [SelectionValuesEnum.brand]: types.maybeNull(types.array(SelectionValuesItem)),
        [SelectionValuesEnum.condition]: types.maybeNull(
            types.array(SelectionValuesItem)
        ),
        [SelectionValuesEnum.size]: types.maybeNull(types.array(SelectionValuesItem)),
        [SelectionValuesEnum.sizeShoes]: types.maybeNull(
            types.array(SelectionValuesItem)
        ),
        [SelectionValuesEnum.shop]: types.maybeNull(types.array(SelectionValuesItem)),
        [SelectionValuesEnum.fit]: types.maybeNull(types.array(SelectionValuesItem)),
    })
    .actions((self) => {
        let initialState: ISelectionValuesSnapshotOut;
        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },
            resetStore(): void {
                applySnapshot(self, initialState);
            },
            setSelectionValues(name: string, values: SelectableItem[] | null): void {
                const root: StoreModel = getRoot(self);
                root.productsList.updateFilterTitles(name, values);
                applySnapshot(self, { ...self, [name]: values });
            },
        };
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchSizeSelectionValues: flow(function* (
                name: string,
                targetGroupId: string,
                itemSubcategoryId: string
            ) {
                try {
                    const queryParams: {
                        targetGroupId: string;
                        itemSubcategoryId?: string;
                    } = {
                        targetGroupId,
                    };
                    if (itemSubcategoryId) {
                        queryParams.itemSubcategoryId = itemSubcategoryId;
                    }
                    const path =
                        name === SelectionValuesEnum.sizeShoes
                            ? 'web-shop/shoes/item-sizes'
                            : 'web-shop/item-sizes';
                    const data = yield httpClient.get(
                        `${path}?${qs.stringify(queryParams)}`
                    );
                    if (Array.isArray(data)) {
                        self.setSelectionValues(
                            name,
                            data.map((item) => ({
                                title: item.label,
                                value: item.value,
                            }))
                        );
                    } else {
                        self.setSelectionValues(name, []);
                    }
                } catch {
                    self.setSelectionValues(name, []);
                }
            }),
            fetchSelectionValues: flow(function* (name: string) {
                try {
                    const data: SelectionValuesDto[] = yield httpClient.get(
                        `selection-values/${name}`
                    );

                    if (Array.isArray(data) && data.length > 0) {
                        const mappedSelectionValues = data.map((item) => ({
                            title: item.label,
                            value: item.value,
                        }));
                        self.setSelectionValues(name, mappedSelectionValues);
                    } else {
                        self.setSelectionValues(name, []);
                    }
                } catch {
                    self.setSelectionValues(name, []);
                }
            }),
        };
    })
    .views((self) => {
        return {
            getSelectionItemsByName(name: string): SelectableItem[] | null {
                return [...(self[name as keyof ISelectionValuesSnapshotOut] || [])];
            },
        };
    });

export type SelectionValuesModel = Instance<typeof SelectionValues>;
export type ISelectionValuesSnapshotOut = SnapshotOut<typeof SelectionValues>;
