import { SelectableItem } from '../types';
import { SalutationOptions } from '../constants';

export const salutationDropDownOptions: SelectableItem[] = [
    {
        title: 'common:salutations.notSpecified',
        value: SalutationOptions.notSpecified,
    },
    {
        title: 'common:salutations.mister',
        value: SalutationOptions.mister,
    },
    {
        title: 'common:salutations.missis',
        value: SalutationOptions.missis,
    },
];
