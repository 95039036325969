import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { Spacings } from 'src/shared/constants';
import { getMediaSelectorKey } from 'src/shared/utils';

export const useBookingLayoutStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            height: '100%',
            padding: `${theme.spacing(6)} ${Spacings.xxl}px`,
            columnGap: theme.spacing(5),
            [theme.breakpoints.down('xl')]: {
                padding: `${theme.spacing(4)} ${Spacings.xl}px`,
                columnGap: theme.spacing(4),
            },
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)} ${Spacings.sm}px`,
                columnGap: theme.spacing(2),
            },
        },
        navContainer: {
            width: '100%',
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down('xl')]: {
                marginBottom: theme.spacing(3),
            },
            [theme.breakpoints.down('xl')]: {
                marginBottom: theme.spacing(2),
            },
        },
        listContainer: {
            width: '75%',
            [getMediaSelectorKey('max-width: 830px')]: {
                width: '100%',
            },
        },
        actionBlockContainer: {
            width: '22%',
            [getMediaSelectorKey('max-width: 830px')]: {
                width: '100%',
                marginBottom: 20,
                marginTop: 20,
            },
        },
        contentBlock: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            [getMediaSelectorKey('max-width: 830px')]: {
                flexDirection: 'column-reverse',
            },
        },
    };
});
