import React, { useEffect } from 'react';
import { ShopFilterContext } from './shop-filter-context';
import { useSearchParams } from 'react-router-dom';
import { useStore } from '../../mst/StoreProvider';
import { SelectionValues } from '../../shared/constants';
import { SelectableItem } from '../../shared/types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

interface Props {
    children: JSX.Element;
}

const shopIdParam = 'shopReferrer';

export const ShopFilterProvider = observer(({ children }: Props): JSX.Element => {
    const [searchParams] = useSearchParams();
    const { i18n } = useTranslation();
    const { productsList, selectionValues } = useStore();

    const shopSelectionItems: SelectableItem[] | null =
        selectionValues.getSelectionItemsByName(SelectionValues.shop);

    useEffect(() => {
        selectionValues.fetchSelectionValues(SelectionValues.shop);
    }, [i18n.language]);

    useEffect(() => {
        if (!shopSelectionItems || shopSelectionItems.length < 1) {
            return;
        }

        const shopIdByQueryParam = searchParams.get(shopIdParam);
        if (!shopIdByQueryParam) {
            return;
        }

        const shop = shopSelectionItems.find((shop) => shop.value == shopIdByQueryParam);
        if (!shop) {
            return;
        }

        productsList.setFilterValues(SelectionValues.shop, [
            {
                filter: SelectionValues.shop,
                value: shop.value,
                titles: [() => shop.title],
            },
        ]);
    }, [searchParams, shopSelectionItems]);

    return (
        <ShopFilterContext.Provider value={{ shops: [], setShops: () => undefined }}>
            {children}
        </ShopFilterContext.Provider>
    );
});
