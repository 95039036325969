import {
    Instance,
    SnapshotOut,
    types,
    applySnapshot,
    flow,
    getEnv,
} from 'mobx-state-tree';
import qs from 'query-string';
import { generatePath } from 'react-router-dom';

import { AppRoutes } from 'src/routing/appRoutes';

import {
    PurchaseHistoryItem,
    IPurchaseHistoryItemSnapshotOut,
} from './purchasedItemsHistoryItem';
import { BookingPurchaseListFormattedItem } from './types';

export const PurchasedItemHistory = types
    .model('PurchasedItemHistory', {
        list: types.maybeNull(types.array(PurchaseHistoryItem)),
        total: types.maybeNull(types.number),
        page: types.number,
        rowsPerPage: types.number,
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchHistoryOfPurchasedItems: flow(function* () {
                const skip = (self.page + 1) * self.rowsPerPage - self.rowsPerPage;
                const take = self.rowsPerPage;
                const queryParams = { skip, take };
                const data = yield httpClient.get(
                    `web-shop/checkout-history/page?${qs.stringify(queryParams)}`
                );
                applySnapshot(self, { ...self, list: data.result, total: data.total });
            }),
            setPage(value: number): void {
                self.page = value;
            },
            setRowsPerPage(value: number): void {
                applySnapshot(self, { ...self, page: 0, rowsPerPage: value });
            },
        };
    })
    .views((self) => {
        return {
            get amountOfBoughtItems(): number {
                return self.list.length;
            },
            get formattedPurchaseList(): BookingPurchaseListFormattedItem[] {
                const shops: Set<string> = new Set<string>(
                    self.list?.map((item) => item.shopId)
                );
                const formattedPurchaseList: BookingPurchaseListFormattedItem[] =
                    Array.from(shops).map((shop, index) => {
                        const itemsByShop = self.list?.filter(
                            (item) => item.shopId === shop
                        );
                        return {
                            id: `${index}`,
                            shop: itemsByShop[0].shopName,
                            shopId: itemsByShop[0].shopId,
                            location: itemsByShop[0].location,
                            workHours: itemsByShop[0].workhours,
                            items: itemsByShop.map(
                                (item: IPurchaseHistoryItemSnapshotOut) => ({
                                    id: item.itemId,
                                    reserved: item.reserved,
                                    adddedToCart: false,
                                    availableForPurchaseAndBooking: false,
                                    addedToBookingList: false,
                                    localOnly: !item.shippingAvailable,
                                    isNewGood: item.isNewGood,
                                    shippingAvailable: item.shippingAvailable,
                                    name: item.name,
                                    price: item.price,
                                    freeShippingPrice: item.freeOfShippingCosts,
                                    amount: 1, // It is hardcoded because it is supposed that we can reserve only 1 item
                                    color: item.color,
                                    size: item.size,
                                    stock: item.amount,
                                    previewImage: item.previewImage,
                                    imageLink: generatePath(AppRoutes.goodsItem, {
                                        itemId: item.itemId,
                                    }),
                                })
                            ),
                        };
                    });
                return formattedPurchaseList;
            },
        };
    });

export type PurchasedItemHistoryModel = Instance<typeof PurchasedItemHistory>;
export type IPurchasedItemHistorySnapshotOut = SnapshotOut<typeof PurchasedItemHistory>;
