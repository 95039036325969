import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Box, Button } from '@mui/material';

import { TextField, BaseInputWrapper } from 'src/components/fields';
import { AuthModuleSections } from 'src/authentication/constants';
import { useStore } from 'src/mst/StoreProvider';
import { Loader } from 'src/components/base';
import { ResponseError } from 'src/shared/types';

import { useSignInFormStyles } from './SignInFormStyles';
import { signInValidationSchema } from './SignInValidationSchema';

const langNs = ['authentication', 'fieldErrors', 'serverErrors'];

interface SignInFormType {
    email: string;
    password: string;
}

export const SignInForm = (): ReactElement => {
    const classes = useSignInFormStyles();
    const { t } = useTranslation(langNs);

    const { authentication } = useStore();

    const { mutateAsync, isLoading } = useMutation(authentication.signIn, {
        onSuccess: () => {
            toast.success(t('successSigningInMessage', { ns: 'authentication' }));
        },
        onError: (err: ResponseError) => {
            const errors = Object.values(
                err?.error?.response?.data?.errors || {}
            )?.flat();
            const firstError =
                Array.isArray(errors) && errors.length > 0 ? errors[0] : '';
            if (firstError) {
                toast.error(t(firstError, { ns: 'serverErrors' }));
            } else {
                toast.error(t('unknownError', { ns: 'serverErrors' }));
            }
        },
    });

    const {
        values,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        setFieldTouched,
        isValid,
        dirty,
    } = useFormik<SignInFormType>({
        initialValues: { email: '', password: '' },
        validationSchema: signInValidationSchema,
        onSubmit: (values) => {
            mutateAsync(values);
        },
    });

    const { email, password } = values;

    const goToForgotPassword = (): void => {
        authentication.setAuthModule(AuthModuleSections.passwordRestore);
    };

    const goToRegister = (): void => {
        authentication.setAuthModule(AuthModuleSections.signUp);
    };

    const handleChange = (name: string, value: string): void => {
        setFieldTouched(name);
        setFieldValue(name, value);
    };

    return (
        <form className={classes.container} onSubmit={handleSubmit}>
            <Box mb={3}>
                <BaseInputWrapper
                    id='email'
                    label='authentication:email'
                    languageNamespaces={langNs}
                    error={touched.email ? Boolean(errors.email) : false}
                    helperText={
                        touched.email && errors.email ? `fieldErrors:${errors.email}` : ''
                    }
                >
                    <TextField
                        id='email'
                        name='email'
                        translationNamespaces={langNs}
                        label='authentication:email'
                        value={email}
                        onChange={handleChange}
                        inputProps={{ type: 'email', autoComplete: 'email' }}
                    />
                </BaseInputWrapper>
            </Box>
            <Box mb={2}>
                <BaseInputWrapper
                    id='password'
                    label='authentication:password'
                    languageNamespaces={langNs}
                    error={touched.password ? Boolean(errors.password) : false}
                    helperText={
                        touched.password && errors.password
                            ? `fieldErrors:${errors.password}`
                            : ''
                    }
                >
                    <TextField
                        id='password'
                        name='password'
                        translationNamespaces={langNs}
                        label='authentication:password'
                        value={password}
                        onChange={handleChange}
                        inputProps={{ type: 'password', autoComplete: 'password' }}
                    />
                </BaseInputWrapper>
            </Box>
            <Box mb={3}>
                <Button
                    disableRipple
                    onClick={goToForgotPassword}
                    className={classes.forgotPassword}
                >
                    {t('authentication:forgotPassword')}
                </Button>
            </Box>
            <Box mb={3}>
                <Button
                    fullWidth
                    disabled={!isValid || !dirty || isLoading}
                    color='secondary'
                    variant='contained'
                    type='submit'
                    startIcon={isLoading && <Loader />}
                >
                    {t('authentication:login')}
                </Button>
            </Box>
            <Button
                fullWidth
                color='secondary'
                className={classes.registerBtn}
                onClick={goToRegister}
            >
                {t('authentication:register')}
            </Button>
        </form>
    );
};
