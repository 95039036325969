import {
    Instance,
    SnapshotOut,
    types,
    flow,
    getEnv,
    applySnapshot,
} from 'mobx-state-tree';
import qs from 'query-string';
import { generatePath } from 'react-router-dom';

import { AppRoutes } from 'src/routing/appRoutes';

import {
    BookingReservationListItem,
    IBookingReservationListItemSnapshotOut,
} from '../bookingReservationList/bookingReservationListItem';
import { BookingReservationListFormattedItem } from '../bookingReservationList/types';

export const ReservationHistory = types
    .model('ReservationHistory', {
        list: types.maybeNull(types.array(BookingReservationListItem)),
        total: types.maybeNull(types.number),
        page: types.number,
        rowsPerPage: types.number,
    })
    .actions((self) => {
        const {
            env: { httpClient },
        } = getEnv(self);
        return {
            fetchHistoryOfReservedItems: flow(function* () {
                const skip = (self.page + 1) * self.rowsPerPage - self.rowsPerPage;
                const take = self.rowsPerPage;
                const queryParams = { skip, take };
                const data = yield httpClient.get(
                    `web-shop/reservation-history/page?${qs.stringify(queryParams)}`
                );
                applySnapshot(self, { ...self, list: data.result, total: data.total });
            }),
            setPage(value: number): void {
                self.page = value;
            },
            setRowsPerPage(value: number): void {
                applySnapshot(self, { ...self, page: 0, rowsPerPage: value });
            },
        };
    })
    .views((self) => {
        const {
            env: { dateTimeService },
        } = getEnv(self);
        return {
            get amountOfReservedItems(): number {
                return self.total || 0;
            },
            get groupedListByReservationToAndShopId(): BookingReservationListFormattedItem[] {
                const groups: Set<string> = new Set<string>(
                    self.list?.map(
                        (item) =>
                            `${
                                item.shopId
                            } | ${dateTimeService.getDateISOStringWithoutTimeOffset(
                                item.reservedTo
                            )}`
                    )
                );
                const formattedReservationList: BookingReservationListFormattedItem[] =
                    Array.from(groups).map((group) => {
                        const itemsByGroup = self.list?.filter(
                            (item) =>
                                `${
                                    item.shopId
                                } | ${dateTimeService.getDateISOStringWithoutTimeOffset(
                                    item.reservedTo
                                )}` === group
                        );
                        return {
                            id: group,
                            shop: itemsByGroup[0].shopName,
                            shopId: itemsByGroup[0].shopId,
                            location: itemsByGroup[0].location,
                            workHours: itemsByGroup[0].workhours,
                            reservedFrom: itemsByGroup[0].reservedFrom,
                            reservedTo: itemsByGroup[0].reservedTo,
                            items: itemsByGroup.map(
                                (item: IBookingReservationListItemSnapshotOut) => ({
                                    id: item.itemId,
                                    reserved: item.reserved,
                                    adddedToCart: false,
                                    availableForPurchaseAndBooking: false,
                                    addedToBookingList: false,
                                    localOnly: !item.shippingAvailable,
                                    isNewGood: item.isNewGood,
                                    shippingAvailable: item.shippingAvailable,
                                    name: item.name,
                                    price: item.price,
                                    freeShippingPrice: item.freeOfShippingCosts,
                                    amount: 1, // It is hardcoded because it is supposed that we can reserve only 1 item
                                    color: item.color,
                                    size: item.size,
                                    stock: item.amount,
                                    previewImage: item.previewImage,
                                    imageLink: generatePath(AppRoutes.goodsItem, {
                                        itemId: item.itemId,
                                    }),
                                })
                            ),
                        };
                    });
                return formattedReservationList;
            },
        };
    });

export type ReservationHistoryModel = Instance<typeof ReservationHistory>;
export type IReservationHistorySnapshotOut = SnapshotOut<typeof ReservationHistory>;
