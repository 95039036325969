import { TargetGroupDto } from 'src/shared/types';
import { NavMenuItem } from 'src/components/layouts/BaseLayout/components/MainProductNavigationMenu/NavMenuItem';

export const mapTargetGroupLevel = (
    items: TargetGroupDto[],
    depthLevel = 0
): NavMenuItem[] => {
    return items.map((item) => {
        const mappedItem: NavMenuItem = {
            title: item.label,
            id: item.value,
            parentId: item.parentId,
            submenu: undefined,
        };
        if (
            depthLevel === 0 &&
            Array.isArray(item.categories) &&
            item.categories.length > 0
        ) {
            mappedItem.submenu = [
                {
                    title: 'common:all',
                    id: 'All',
                    submenu: undefined,
                },
                ...mapTargetGroupLevel(item.categories, depthLevel + 1),
            ];
        }
        if (
            depthLevel === 1 &&
            Array.isArray(item.subCategories) &&
            item.subCategories.length > 0
        ) {
            mappedItem.submenu = [
                {
                    title: 'common:all',
                    id: 'All',
                    submenu: undefined,
                },
                ...mapTargetGroupLevel(item.subCategories, depthLevel + 1),
            ];
        }
        return mappedItem;
    });
};
