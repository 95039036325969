import React, { ReactElement, useState, MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Button, Popover } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { CurrencyField, BaseInputWrapper } from 'src/components/fields';
import { useStore } from 'src/mst/StoreProvider';

import { usePriceFilterStyles } from './PriceFilterStyles';

interface PriceFilterProps {
    label: string;
    id: string;
    name: string;
    placeholder?: string;
}

const langNs = ['formatted-values', 'product-filters'];

export const PriceFilter = observer(({ id, label }: PriceFilterProps): ReactElement => {
    const { t } = useTranslation(langNs);
    const classes = usePriceFilterStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [values, setValues] = useState<{
        priceFrom: number | null;
        priceTo: number | null;
    }>({
        priceFrom: null,
        priceTo: null,
    });

    const { productsList } = useStore();

    const onChange = (name: string, value: number | null): void => {
        setValues({ ...values, [name]: value });
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const onBlur = (name: string): void => {
        const currentValue = values[name as 'priceTo' | 'priceFrom'];
        productsList.setFilterValues(name, [
            {
                filter: name,
                titles: [
                    () => t(`product-filters:${name}`),
                    () =>
                        t('formatted-values:formattedCurrency', {
                            currency: values[name as 'priceTo' | 'priceFrom'],
                        }),
                ],
                value:
                    typeof currentValue === 'number' && !Number.isNaN(currentValue)
                        ? currentValue?.toString()
                        : '',
            },
        ]);
    };

    const priceFromStored = productsList.getFilterValues('priceFrom')?.[0]?.value;
    const priceToStored = productsList.getFilterValues('priceTo')?.[0]?.value;

    useEffect(() => {
        setValues({
            priceTo: typeof priceToStored === 'string' ? parseFloat(priceToStored) : null,
            priceFrom:
                typeof priceFromStored === 'string' ? parseFloat(priceFromStored) : null,
        });
    }, [priceFromStored, priceToStored]);

    const open = Boolean(anchorEl);
    const popoverId = open ? 'price-filter' : undefined;

    const isActive = Boolean(priceFromStored || priceToStored);

    return (
        <>
            <Button
                aria-describedby='price-filter'
                onClick={handleClick}
                className={clsx(classes.filterBtn, {
                    [classes.opened]: open,
                    [classes.active]: isActive,
                })}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {t(label)}
            </Button>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.priceFieldsContainer}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <BaseInputWrapper
                    id={id}
                    label='product-filters:priceFrom'
                    languageNamespaces={langNs}
                >
                    <CurrencyField
                        id='priceFrom'
                        name='priceFrom'
                        value={values.priceFrom}
                        onChange={onChange}
                        tLabelKey='product-filters:priceFrom'
                        blurCb={onBlur}
                        languageNamespaces={langNs}
                    />
                </BaseInputWrapper>
                <BaseInputWrapper
                    id={id}
                    label='product-filters:priceTo'
                    languageNamespaces={langNs}
                >
                    <CurrencyField
                        id='priceTo'
                        name='priceTo'
                        value={values.priceTo}
                        onChange={onChange}
                        tLabelKey='product-filters:priceTo'
                        blurCb={onBlur}
                        languageNamespaces={langNs}
                    />
                </BaseInputWrapper>
            </Popover>
        </>
    );
});
