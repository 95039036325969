import React, { ReactElement, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Pagination } from '@mui/material';

import { useQuery } from 'src/shared/hooks';
import { SelectField } from 'src/components/fields';
import { SelectableItem } from 'src/shared/types';

import { useGoodsListPaginationStyles } from './GoodsListPaginationStyles';

export const defaultProductsListPageSize = 32;

interface GoodsListPaginationProps {
    pageOptions: SelectableItem[];
}

export const GoodsListPagination = ({
    pageOptions,
}: GoodsListPaginationProps): ReactElement => {
    const navigate = useNavigate();
    const classes = useGoodsListPaginationStyles();

    const page = useQuery().get('page');

    const handleChange = (_: ChangeEvent<unknown>, value: number): void => {
        navigate({ search: `?page=${value}` });
    };

    const handeSelectSpecifiedPage = (_: string, value: string[]): void => {
        navigate({ search: `?page=${value[0]}` });
    };

    return (
        <div className={classes.container}>
            <Pagination
                hidePrevButton
                hideNextButton
                size='small'
                color='secondary'
                count={pageOptions?.length}
                shape='rounded'
                page={typeof page === 'string' ? parseInt(page) : 1}
                onChange={handleChange}
            />
            {Array.isArray(pageOptions) && pageOptions?.length > 1 && (
                <div className={classes.pageSelectorContainer}>
                    <SelectField
                        languageNamespaces={[]}
                        id='products-list-page-selector'
                        tLabelKey=''
                        items={pageOptions}
                        name='pageSelector'
                        value={[`${typeof page === 'string' ? page : 1}`]}
                        handleChange={handeSelectSpecifiedPage}
                    />
                </div>
            )}
        </div>
    );
};
