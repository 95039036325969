import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';

interface useProductCardStylesProps {
    withBorder: boolean;
    borderColor?: string;
}

export const useProductCardStyles = makeStyles((theme: Theme) => {
    return {
        cardContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 300,
            height: '100%',
            padding: theme.spacing(2),
            overflow: 'visible',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            '&:hover::after': {
                opacity: 1,
            },
            '&::after': {
                opacity: ({ withBorder }: useProductCardStylesProps) =>
                    withBorder ? 0 : 1,
                content: '" "',
                width: '100%',
                position: 'absolute',
                height: 15,
                bottom: -5,
                left: 0,
                zIndex: 100,
                backgroundColor: theme.palette.primary.main,
                transition: '0.5s',
            },
            '&:hover': {
                boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.13)',
                '& .MuiCardContent-root>div': {
                    opacity: 1,
                    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.13)',
                    zIndex: 1000,
                },
            },
        },
        cardMediaContainer: {
            position: 'relative',
            aspectRatio: `${1 / 1.3}`,
            overflow: 'hidden',
            borderRadius: 10,
        },
        cardMedia: {
            aspectRatio: `${1 / 1.3}}`,
            objectFit: 'cover',
            flexGrow: 1,
            borderRadius: 10,
            overflow: 'hidden',
        },
        cardContent: {
            paddingLeft: 0,
            paddingBottom: '5px!important',
            '& p': {
                fontWeight: 'bold',
                color: theme.palette.primary.contrastText,
            },
            [theme.breakpoints.down('lg')]: {
                paddingTop: 12,
            },
        },
        cardAdditionalInfo: {
            width: ({ withBorder }: useProductCardStylesProps) =>
                withBorder ? 'calc(100% + 10px)' : '100%',
            position: 'absolute',
            top: '100%',
            left: ({ withBorder }: useProductCardStylesProps) => (withBorder ? -5 : 0),
            padding: theme.spacing(2),
            paddingTop: 2,
            opacity: 0,
            transition: '0.5s',
            backgroundColor: theme.palette.primary.main,
            boxShadow: 'none',
            border: ({ withBorder, borderColor }: useProductCardStylesProps) =>
                withBorder ? `5px solid ${borderColor}` : 'none',
            zIndex: -1000,
        },
        productHeading: {
            fontSize: '1.6rem',
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.4rem',
            },
        },
        productSizeHeading: {
            fontSize: '1.4rem',
        },
        price: {
            height: 24,
            fontSize: '1.8rem',
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.6rem',
            },
        },
        productSizeContent: {
            fontSize: '1.4rem',
        },
        addToCartBtn: {
            width: '100%',
            marginTop: theme.spacing(4),
            [theme.breakpoints.down('xl')]: {
                fontSize: '1.4rem',
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.2rem',
            },
        },
        cardActions: {
            position: 'absolute',
            top: 5,
            right: 5,
            '& .MuiIconButton-root': {
                color: theme.palette.primary.main,
                '& .MuiSvgIcon-root': {
                    fontSize: '2.4rem',
                },
            },
        },
        shopLink: {
            height: '30px',
            width: '30px',
            fontSize: '30px',
            marginLeft: '-5px',
        },
        topLeftlabelsContainer: {
            display: 'flex',
            position: 'absolute',
            top: 0,
            left: 0,
            borderBottomRightRadius: 10,
            borderTopLeftRadius: 10,
            overflow: 'hidden',
            fontSize: '1.2rem',
            '&>div': {
                padding: `4px 12px`,
            },
        },
        bottomLeftlabelsContainer: {
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            left: 0,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 10,
            overflow: 'hidden',
            fontSize: '1.2rem',
            '&>div': {
                padding: `4px 12px`,
            },
        },
        localOnlyLabel: {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
        },
        reservedLabel: {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.info.contrastText,
        },
        newLabel: {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
        },
        noPreviewContainer: {
            minHeight: 200,
            backgroundColor: theme.palette.grey.A200,
            borderRadius: 10,

            '& img': {
                objectFit: 'none !important',
            },
        },
        noPreviewColor: {
            '& .MuiIconButton-root': {
                color: THEME_COLORS.SUBTITLE_COLOR,
            },
        },
    };
});

export const useProductCardActionAreaStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                maxWidth: 300,
                height: '100%',
                border: ({ withBorder, borderColor }: useProductCardStylesProps) =>
                    withBorder ? `5px solid ${borderColor}` : 'none',

                '&.Mui-focusVisible': {
                    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.13)!important',
                    '& .MuiCardContent-root>div': {
                        opacity: 1,
                        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.13)!important',
                        zIndex: 1000,
                    },
                },
            },
            focusHighlight: {
                backgroundColor: theme.palette.primary.main,
            },
        };
    },
    { index: 1 }
);
