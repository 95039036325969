import React, {
    useCallback,
    useRef,
    useState,
    memo,
    useMemo,
    ReactElement,
    useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

import {
    Autocomplete,
    TextField,
    Checkbox,
    Typography,
    useMediaQuery,
    ListSubheader,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { SelectableItem } from 'src/shared/types';

import { useAutoCompleteStyles, useCheckBoxStyles } from './AutocompleteStyles';

export type CustomAutoCompleteProps = {
    nameSpaces: string[];
    tLabelKey: string;
    tPlaceholderKey?: string;
    required?: boolean;
    name: string;
    value?: string[];
    items?: SelectableItem[];
    error?: boolean;
    disabled?: boolean;
    handleChange?: (name: string, values: string[]) => void;
    multiple?: boolean;
    popperMinWidth?: number;
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps): ReactElement {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };

    if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
        return (
            <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number): React.MutableRefObject<VariableSizeList> {
    const ref = React.useRef<VariableSizeList>(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach(
        (item: React.ReactChild & { children?: React.ReactChild[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactChild): number => {
        if (Object.prototype.hasOwnProperty.call(child, 'group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = (): number => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width='100%'
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType='ul'
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export const CustomAutocomplete = memo(
    ({
        nameSpaces,
        name,
        value,
        handleChange,
        disabled = false,
        tLabelKey,
        tPlaceholderKey,
        error,
        items,
        multiple,
        popperMinWidth = 200,
    }: CustomAutoCompleteProps): JSX.Element => {
        const autocompleteClasses = useAutoCompleteStyles({ popperMinWidth });
        const checkboxClasses = useCheckBoxStyles();
        const { t } = useTranslation(nameSpaces);

        const [open, setOpen] = useState<boolean>(false);
        const autoCompleteInputRef: React.Ref<HTMLInputElement> = useRef(null);

        const handleChangeValue = useCallback(
            (
                _: React.SyntheticEvent,
                newValue: string | SelectableItem | (string | SelectableItem)[]
            ): void => {
                if (Array.isArray(newValue)) {
                    handleChange(
                        name,
                        newValue.map((item) => {
                            if (typeof item !== 'string') {
                                return item?.value;
                            }
                            return item;
                        })
                    );
                }
            },
            [handleChange, name]
        );

        const handleOpen = (): void => {
            setOpen(true);
        };

        const handleClose = (): void => {
            setOpen(false);
        };

        const currentValue = useMemo(() => {
            if (Array.isArray(value)) {
                return value
                    .map(
                        (valueItemId) =>
                            items?.find((itemObj) => itemObj.value === valueItemId) ||
                            null
                    )
                    .filter((item) => Boolean(item));
            }
            return [];
        }, [value, items]);

        return (
            <Autocomplete
                fullWidth
                disableCloseOnSelect
                noOptionsText={t('common:noOptions')}
                multiple={multiple}
                color='secondary'
                disabled={disabled}
                open={open}
                options={items ?? []}
                classes={
                    autocompleteClasses && {
                        popupIndicator: autocompleteClasses.popupIndicator,
                        clearIndicator: autocompleteClasses.clearIndicator,
                        popper: autocompleteClasses.popper,
                    }
                }
                getOptionLabel={(option: SelectableItem) => option.title}
                placeholder={t(tPlaceholderKey)}
                onOpen={handleOpen}
                onClose={handleClose}
                onChange={handleChangeValue}
                value={currentValue}
                popupIcon={<KeyboardArrowDownIcon />}
                renderGroup={(params) => params}
                ListboxComponent={ListboxComponent}
                renderTags={() => null}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            classes={checkboxClasses}
                            checked={selected}
                        />
                        <span
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}
                        >
                            {option.title}
                        </span>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        inputRef={autoCompleteInputRef}
                        name={name}
                        error={error}
                        placeholder={t(tPlaceholderKey ?? tLabelKey)}
                        className={autocompleteClasses.textField}
                        variant='outlined'
                        label={t(tLabelKey)}
                        color='secondary'
                        {...params}
                    />
                )}
            />
        );
    }
);
