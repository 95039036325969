import i18n from 'i18next';
import Backend, { BackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { fallbackLanguage } from './environment';

export enum LocalFormatValues {
    INTL_DATE = 'intlDate',
    INTL_CURRENCY = 'intlCurrency',
    INTL_TIME = 'intlTime',
}

i18n.use(initReactI18next)
    .use(Backend)
    .init({
        fallbackLng: fallbackLanguage,
        backend: {
            requestOptions: {
                cache: 'no-store',
            },
        } as BackendOptions,
        interpolation: {
            format: (value, format, lng) => {
                if (format === LocalFormatValues.INTL_DATE) {
                    if (value) {
                        return new Intl.DateTimeFormat(lng, {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        }).format(new Date(value));
                    }
                }
                if (format === LocalFormatValues.INTL_CURRENCY) {
                    if (value != null) {
                        return Intl.NumberFormat(lng, {
                            style: 'currency',
                            currency: 'EUR',
                        }).format(value);
                    }
                    return value;
                }
                if (format === LocalFormatValues.INTL_TIME) {
                    if (value && value !== 0) {
                        return new Intl.DateTimeFormat(lng, {
                            hour: '2-digit',
                            minute: '2-digit',
                        }).format(new Date(value));
                    }
                    return value;
                }
                return value;
            },
        },
    });

export default i18n;
