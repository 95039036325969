import {
    types,
    Instance,
    SnapshotOut,
    SnapshotIn,
    flow,
    applySnapshot,
} from 'mobx-state-tree';
import { ImagePreviewMst } from 'src/mst/customTypes';
import { CsFile } from 'src/mst/customTypes/CsFile';

import { sleep } from 'src/shared/utils';

export const BookingPurchaseItem = types
    .model('BookingPurchaseItem', {
        id: types.maybeNull(types.string),
        stock: types.maybeNull(types.number),
        shopName: types.maybeNull(types.string),
        location: types.maybeNull(types.string),
        workhours: types.maybeNull(types.string),
        amount: types.maybeNull(types.number),
        color: types.maybeNull(types.string),
        colorId: types.maybeNull(types.string),
        size: types.maybeNull(types.string),
        sizeId: types.maybeNull(types.string),
        shopId: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        price: types.maybeNull(types.number),
        previewImage: types.maybeNull(ImagePreviewMst),
        shippingAvailable: types.maybeNull(types.boolean),
        shippingCosts: types.maybeNull(types.number),
        freeOfShippingCosts: types.maybeNull(types.number),
        isNewGood: types.maybeNull(types.boolean),
        reserved: types.maybeNull(types.boolean),
        sold: types.maybeNull(types.boolean),
        itemId: types.maybeNull(types.identifier),
        gtCs: types.maybeNull(types.string),
        gtCsFile: types.maybeNull(CsFile),
    })
    .actions((self) => {
        return {
            fetchData: flow(function* () {
                yield sleep(500);
                // Here should be a request to get a cart item data
                applySnapshot(self, { ...self, stock: 2 });
            }),
            changeAmount(value: number) {
                if (value <= self.stock) {
                    self.amount = value;
                }
            },
        };
    });

export type BookingPurchaseItemModel = Instance<typeof BookingPurchaseItem>;
export type IBookingPurchaseItemSnapshotOut = SnapshotOut<typeof BookingPurchaseItem>;
export type IBookingPurchaseItemSnapshotIn = SnapshotIn<typeof BookingPurchaseItem>;
