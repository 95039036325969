import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THEME_COLORS } from 'src/theming/theme';

export const useModalWindowStyles = makeStyles((theme: Theme) => {
    return {
        closeButton: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            '&:hover': { backgroundColor: 'inherit' },
            '& .MuiSvgIcon-root': {
                width: 25,
                height: 25,
                color: theme.palette.primary.contrastText,
            },
        },
    };
});

export const useModalClasses = makeStyles((theme: Theme) => {
    return {
        root: {
            zIndex: 10000,
        },
        paper: {
            position: 'relative',
            minWidth: (props: { width?: number }) => {
                return props?.width ? `${props.width}px` : '550px';
            },
            maxWidth: 'initial',
            backgroundColor: theme.palette.common.white,

            '& label': {
                display: 'block',
                padding: '0 0.5rem',
                background: '#ffffff',
            },
            [theme.breakpoints.down('sm')]: {
                minWidth: (props: { width?: number }) => {
                    return props?.width ? `${props.width}px` : '400px';
                },
            },
            [theme.breakpoints.down('ml')]: {
                minWidth: (props: { width?: number }) => {
                    return props?.width ? `${props.width}px` : '350px';
                },
            },
            [theme.breakpoints.down('mm')]: {
                minWidth: (props: { width?: number }) => {
                    return props?.width ? `${props.width}px` : '300px';
                },
            },
            [theme.breakpoints.down('ms')]: {
                minWidth: (props: { width?: number }) => {
                    return props?.width ? `${props.width}px` : '260px';
                },
            },
        },
    };
});

export const useStyleDialogTitle = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(5),
            fontSize: '1.8rem',
            height: 70,
            padding: '0',
            color: THEME_COLORS.SUBTITLE_COLOR,
            borderBottom: `2px solid ${THEME_COLORS.MAIN_BORDER_COLOR}`,
        },
    };
});

export const useStyleDialogContent = makeStyles(() => {
    return {
        root: {
            maxHeight: '80vh',
            padding: '0',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
    };
});

export const useStyleDialogActions = makeStyles((theme: Theme) => {
    return {
        root: {
            marginTop: theme.spacing(3),
            padding: 0,
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(1),
            },
        },
    };
});
