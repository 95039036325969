import React, { ReactElement, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { MenuList, Typography } from '@mui/material';

import { useStore } from 'src/mst/StoreProvider';

import { useMainProductNavigationMenuStyles } from './MainProductNavigationMenuStyles';
import { MainNavigationMenuItems } from './MainNavigationMenuItems';
import {
    AutocompleteFilter,
    FilterChip,
} from '../../../../../pages/GoodsListPage/components/GoodsListFilterPanel/components';
import { SelectionValues } from '../../../../../shared/constants';
import { useTranslation } from 'react-i18next';

export const MainProductnavigationMenu = observer((): ReactElement => {
    const classes = useMainProductNavigationMenuStyles({});
    const [selectedCategory, selectCategory] = useState<string>('');
    const { t } = useTranslation(['mainPage']);

    const {
        navigation: { items },
        productsList,
    } = useStore();

    useEffect(() => {
        const listenerCb = (): void => {
            selectCategory('');
        };
        document.addEventListener('click', listenerCb);
        return () => {
            removeEventListener('click', listenerCb);
        };
    }, []);

    return (
        <MenuList className={classes.navigationContainer}>
            {items.map((menu, index) => {
                return (
                    <div key={menu.id} className={classes.targetGroupContainer}>
                        <MainNavigationMenuItems
                            targetMenuIndex={index}
                            selectedCategory={selectedCategory}
                            selectCategory={selectCategory}
                            {...menu}
                        />
                    </div>
                );
            })}
            <div className={classes.shopFilterContainer}>
                <Typography variant='h5'>{t('chooseStoresQuestion')}</Typography>
                <AutocompleteFilter
                    label={'product-filters:shop'}
                    id={'Shop filter'}
                    name={SelectionValues.shop}
                    languageNamespaces={['product-filters']}
                    width={340}
                />
                <div className={classes.shopFilterChipsContainer}>
                    {productsList.filters
                        .filter(
                            (item) => item.value && item.filter === SelectionValues.shop
                        )
                        .map((item) => {
                            return (
                                <FilterChip
                                    key={`${item.filter}${item.value}`}
                                    {...item}
                                />
                            );
                        })}
                </div>
            </div>
        </MenuList>
    );
});
