import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useQueries, UseQueryResult } from 'react-query';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { Image } from '../types';

// returns shopIds, attachmentUrls
export const useShopsAttachments = (
    shops: { id: string; attachmentId: string }[]
): { shopId: string; src?: string }[] => {
    const httpClient = useHttpClient();
    const attachmentDummies = shops.map((s) => ({ shopId: s.id, src: null }));
    const [attachments, setAttachments] =
        useState<{ shopId: string; src?: string }[]>(attachmentDummies);

    const setAttachment = (shopId: string, src: string): void => {
        const attachmentsUpdate = _.cloneDeep(attachments);
        attachmentsUpdate.find((a) => a.shopId === shopId).src = src;
        setAttachments(attachmentsUpdate);
    };

    const responses: UseQueryResult<ShopImage, unknown>[] = useQueries(
        shops.map((shop) => {
            const path = `web-shops/${shop.id}/attachments/${shop.attachmentId}`;
            return {
                queryKey: [path],
                queryFn: async () => {
                    const file = await httpClient.get<Blob>(path, {
                        axiosConfig: {
                            headers: {
                                'Content-Type': 'image/*',
                            },
                            responseType: 'blob',
                        },
                    });
                    return { shopId: shop.id, file };
                },
                refetchOnWindowFocus: false,
            };
        })
    );

    useEffect(() => {
        responses.forEach((response) => {
            const data = response.data;
            let attachment = null;
            if (data?.file) {
                attachment = attachments.find((a) => a.shopId === data.shopId);
            }
            if (attachment && !attachment.src) {
                const src = URL.createObjectURL(data.file);
                setAttachment(data.shopId, src);
            }
        });
    }, [responses]);

    return attachments;
};

interface ShopImage extends Image {
    shopId: string;
}
