import React, { ReactElement } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { TextField } from 'src/components/fields';
import { usePhoneFieldStyles } from './PhoneFieldStyles';

interface PhoneFieldProps {
    id: string;
    value: string;
    name: string;
    onChange: (name: string, value: string) => void;
    placeholder?: string;
}

export const PhoneField = ({
    id,
    value,
    name,
    onChange,
    placeholder,
}: PhoneFieldProps): ReactElement => {
    const classes = usePhoneFieldStyles();

    const handleChange = (phone: string): void => {
        onChange(name, phone);
    };

    return (
        <TextField<PhoneInputProps>
            id={id}
            value={value}
            name={name}
            inputComponent={PhoneInput}
            placeholder={placeholder}
            inputProps={{
                value,
                className: classes.phoneNumber,
                country: 'de',
                onlyCountries: ['de', 'at', 'ch'],
                onChange: handleChange,
            }}
        />
    );
};
