import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { THEME_COLORS } from 'src/theming/theme';

export const useSelectStyles = makeStyles((theme: Theme) => {
    return {
        select: {
            height: `${40}px!important`,
            padding: `9px ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
            boxSizing: 'border-box',
        },
        icon: {
            fontSize: '2.5rem',
        },
    };
});

export const useMenuStyles = makeStyles((theme: Theme) => {
    return {
        selected: {
            backgroundColor: `${theme.palette.primary.dark}!important`,
        },
        root: {
            '& .MuiTypography-root': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
    };
});

export const useCheckBoxStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            color: THEME_COLORS.MAIN_BORDER_COLOR,
            width: 20,
            height: 20,
            marginRight: theme.spacing(2),
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
            },
        },
        checked: {
            color: `${theme.palette.secondary.main}!important`,
        },
    };
});
