import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { THEME_COLORS } from 'src/theming/theme';
import { getMediaSelectorKey } from 'src/shared/utils';

interface StyleProps {
    placement: 'top' | 'bottom' | 'left' | 'right';
    required?: boolean;
}

export const useSeparateLabelInputWrapperFormControlStyles = makeStyles(
    (theme: Theme) => {
        return {
            root: {
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: theme.spacing(3),
                lineHeight: 1,
                flexDirection: ({ placement }: StyleProps) => {
                    switch (placement) {
                        case 'right':
                        case 'left': {
                            return 'row';
                        }
                        default: {
                            return 'column';
                        }
                    }
                },
                '& label': {
                    width: 'fit-content',
                    fontSize: '1.6rem',
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.primary.contrastText,
                    cursor: 'pointer',
                    order: ({ placement }: StyleProps) => {
                        if (placement === 'top' || placement === 'left') {
                            return 1;
                        }
                        return 2;
                    },
                    '&:after': {
                        content: ({ required }: StyleProps) => (required ? "'*'" : ''),
                        color: theme.palette.error.main,
                    },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    top: 0,
                    borderWidth: 2,
                    borderRadius: 10,
                    borderColor: THEME_COLORS.MAIN_BORDER_COLOR,
                    '& legend': {
                        display: 'none',
                        transition: 'none',
                    },
                },
                '&>div': {
                    maxWidth: 290,
                    flexGrow: 1,
                    order: ({ placement }: StyleProps) => {
                        if (placement === 'top' || placement === 'left') {
                            return 2;
                        }
                        return 1;
                    },
                },
                '& .MuiInputBase-root': {
                    width: '100%',
                },
                [getMediaSelectorKey('max-width: 480px')]: {
                    display: 'block',
                    marginBottom: 10,
                },
            },
            contentWrapper: {
                [getMediaSelectorKey('max-width: 480px')]: {
                    marginTop: 10,
                },
            },
        };
    }
);

export const useFormHelperText = makeStyles((theme: Theme) => {
    return {
        root: {
            fontSize: '1.2rem',
        },
        error: {
            color: theme.palette.error.main,
        },
    };
});
