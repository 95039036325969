import { types, SnapshotOut } from 'mobx-state-tree';
import { ImagePreviewMst } from 'src/mst/customTypes';

export const ShopsListItem = types.model('ShopsListItem', {
    id: types.maybeNull(types.string),
    clientId: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    logo: types.maybeNull(ImagePreviewMst),
    address: types.maybeNull(types.string),
    openingHours: types.maybeNull(types.string),
    shipping: types.maybeNull(types.boolean),
    shippingCosts: types.maybeNull(types.number),
    freeOfShippingCosts: types.maybeNull(types.number),
    defaultTargetGroup: types.maybeNull(types.string),
});

export type IShopsLisIitemSnapshotOut = SnapshotOut<typeof ShopsListItem>;
