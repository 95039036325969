import { ProductsFilterItem } from 'src/mst/customTypes';
import { defaultProductsListPageSize } from 'src/pages/GoodsListPage/components';
import { SelectionValues, SortOptions } from 'src/shared/constants';

interface prepareQueryStringForProductsListParams {
    page: number;
    targetGroup: string;
    filters: ProductsFilterItem[];
    globalSearchTerm?: string;
    allowedCategory?: string;
    allowedSubCategory?: string;
    sorting?: string;
}

interface prepareQueryStringForProductsListReturn {
    skip: number;
    take: number;
    targetGroup: string;
    globalSearchTerm?: string;
    allowedCategory?: string;
    allowedSubCategory?: string;
    allowedShops?: string[];
    allowedBrands?: string[];
    allowedColors?: string[];
    allowedSizes?: string[];
    allowedShoeSizes?: string[];
    allowedConditions?: string[];
    allowedFits?: string[];
    priceFrom?: number;
    priceTo?: number;
    sortField?: string;
    isAscending?: boolean;
}

export const prepareQueryStringForProductsList = ({
    page = 1,
    globalSearchTerm,
    targetGroup,
    allowedCategory,
    allowedSubCategory,
    filters,
    sorting,
}: prepareQueryStringForProductsListParams): prepareQueryStringForProductsListReturn => {
    const skip = page * defaultProductsListPageSize - defaultProductsListPageSize;
    const take = defaultProductsListPageSize;

    const queryParams: prepareQueryStringForProductsListReturn = {
        skip,
        take,
        targetGroup,
    };

    if (globalSearchTerm) {
        queryParams.globalSearchTerm = globalSearchTerm;
    }

    if (allowedCategory) {
        queryParams.allowedCategory = allowedCategory;
    }

    if (allowedSubCategory) {
        queryParams.allowedSubCategory = allowedSubCategory;
    }

    const allowedShops: string[] = [];
    const allowedBrands: string[] = [];
    const allowedColors: string[] = [];
    const allowedSizes: string[] = [];
    const allowedConditions: string[] = [];
    const allowedFits: string[] = [];
    const allowedShoeSizes: string[] = [];

    filters.forEach((item) => {
        if (item.filter === SelectionValues.shop) {
            allowedShops.push(item.value);
        } else if (item.filter === SelectionValues.brand) {
            allowedBrands.push(item.value);
        } else if (item.filter === SelectionValues.color) {
            allowedColors.push(item.value);
        } else if (item.filter === SelectionValues.size) {
            allowedSizes.push(item.value);
        } else if (item.filter === SelectionValues.condition) {
            allowedConditions.push(item.value);
        } else if (item.filter === SelectionValues.fit) {
            allowedFits.push(item.value);
        } else if (item.filter === 'priceFrom' && item.value !== '') {
            queryParams.priceFrom = parseFloat(item.value);
        } else if (item.filter === 'priceTo' && item.value !== '') {
            queryParams.priceTo = parseFloat(item.value);
        } else if (item.filter === SelectionValues.sizeShoes) {
            allowedShoeSizes.push(item.value);
        }
    });

    if (allowedShops.length > 0) {
        queryParams.allowedShops = allowedShops;
    }

    if (allowedBrands.length > 0) {
        queryParams.allowedBrands = allowedBrands;
    }

    if (allowedColors.length > 0) {
        queryParams.allowedColors = allowedColors;
    }
    if (allowedSizes.length > 0) {
        queryParams.allowedSizes = allowedSizes;
    }
    if (allowedConditions.length > 0) {
        queryParams.allowedConditions = allowedConditions;
    }
    if (allowedFits.length > 0) {
        queryParams.allowedFits = allowedFits;
    }

    if (allowedShoeSizes.length > 0) {
        queryParams.allowedShoeSizes = allowedShoeSizes;
    }

    if (sorting) {
        queryParams.sortField = 'price';
        queryParams.isAscending = sorting === SortOptions.asc;
    }

    return queryParams;
};
