import React, {
    ReactElement,
    Dispatch,
    SetStateAction,
    MouseEvent,
    KeyboardEvent,
} from 'react';
import clsx from 'clsx';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { MenuItem } from '@mui/material';

import { useMainProductNavigationMenuStyles } from './MainProductNavigationMenuStyles';
import { MainNavigationMenuItemsDropdown } from './MainNavigationMenuDropdown';
import { AppRoutes } from 'src/routing/appRoutes';

interface MenuItemsProps {
    id: string;
    title: string;
    selectedCategory: string;
    selectCategory: Dispatch<SetStateAction<string>>;
    targetMenuIndex: number;
    submenu?: { id: string; title: string }[];
}

export const MainNavigationMenuItems = ({
    id,
    submenu,
    title,
    selectCategory,
    selectedCategory,
    targetMenuIndex,
}: MenuItemsProps): ReactElement => {
    const navigate = useNavigate();
    const classes = useMainProductNavigationMenuStyles({ targetMenuIndex });

    const { menuId } = useParams();

    const open = selectedCategory === id;

    const handleOpen = (event: MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        selectCategory(id);
    };

    const handleClose = (event: KeyboardEvent<HTMLElement>): void => {
        if (event.key === 'Escape') {
            selectCategory('');
        }
    };

    const handleGoToGoodsList = (): void => {
        selectCategory('');
        navigate(generatePath(AppRoutes.goodsListMenu, { menuId: id }));
    };

    return (
        <>
            {Array.isArray(submenu) && submenu.length > 0 && (
                <div style={{ position: 'relative', height: '100%' }}>
                    <MenuItem
                        disableRipple
                        tabIndex={0}
                        className={clsx([
                            classes.menuItems,
                            ...(selectedCategory === id || menuId === id
                                ? [classes.selectedCategory, classes.activeTextColor]
                                : [classes.underlinedItems]),
                        ])}
                        aria-controls={open ? id : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpen}
                        onKeyDown={handleClose}
                    >
                        {title}
                    </MenuItem>
                    <MainNavigationMenuItemsDropdown
                        submenus={submenu}
                        open={open}
                        selectedMenuId={selectedCategory}
                        selectCategory={selectCategory}
                    />
                </div>
            )}
            {(!Array.isArray(submenu) || submenu.length == 0) && (
                <MenuItem
                    disableRipple
                    tabIndex={0}
                    className={clsx([
                        classes.menuItems,
                        ...(menuId === id
                            ? [classes.selectedCategory, classes.activeTextColor]
                            : [classes.underlinedItems]),
                    ])}
                    onClick={handleGoToGoodsList}
                >
                    {title}
                </MenuItem>
            )}
        </>
    );
};
