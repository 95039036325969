import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Theme, Link as MaterialLink } from '@mui/material';

import { AppRoutes } from 'src/routing/appRoutes';

const useStoreLinksStyles = makeStyles((theme: Theme) => {
    return {
        linksContainer: {
            display: 'flex',
            fontSize: '1.6rem',

            [theme.breakpoints.down('lg')]: {
                fontSize: '1.4rem',
            },

            '& li': {
                padding: '0 0.5em',
                textAlign: 'center',
                '&:not(:last-child)': {
                    borderRight: `1px solid ${theme.palette.warning.light}`,
                },
            },
            '& a': {
                fontFamily: 'Saveur',
                color: theme.palette.primary.contrastText,
            },
        },
    };
});

const ekodiseLink = 'https://www.ekodise.de';

interface StoreLinksProps {
    injectedClassName?: string;
}

export const StoreLinks = ({ injectedClassName }: StoreLinksProps): ReactElement => {
    const { t } = useTranslation(['common']);
    const classes = useStoreLinksStyles();

    return (
        <ul className={clsx(classes.linksContainer, injectedClassName)}>
            <li>
                <MaterialLink target='_blank' href={ekodiseLink}>
                    {t('common:storeLinks.aboutUsAndOutViion')}
                </MaterialLink>
            </li>
            <li>
                <Link to={AppRoutes.shops}>{t('common:storeLinks.allShops')}</Link>
            </li>
            <li>
                <MaterialLink target='_blank' href={ekodiseLink}>
                    {t('common:storeLinks.becomeMerchant')}
                </MaterialLink>
            </li>
        </ul>
    );
};
